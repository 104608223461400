import React,{Component, useState} from "react";
import PacienteList from "../PacienteList";
import PacienteForm from "../PacienteForm";
import CircularProgress from '@mui/material/CircularProgress';
import PacienteButtonBar from "../PacienteFloatButton";
import PacienteFormEdit from "../PacienteFormEdit";
import Fab from '@mui/material/Fab';
import { Collapse } from "@mui/material";
import { useSelector } from "react-redux";
import Busqueda from "../../BusquedaComponent";


function Page(props){

        
    const isBusqueda =useSelector(state =>state.estadosReducer.mostrarBusqueda);    
    const [busqueda,setBusqueda]=useState("");

        var resultDatos;
        var animacionCargando;
       
        
        if(props.pacientees.length>0){
            resultDatos =  <div className="col-12" >         
                                <Collapse in={isBusqueda}>
                                    <Busqueda label="Buscar Paciente" setBusqueda={setBusqueda}/>                                    
                                </Collapse>              
                                <PacienteList  pacientees={props.pacientees} 
                                               setPaciente={props.setPaciente}
                                               busqueda={busqueda}
                                /> 
                            </div>
                                
        }


        return <div style={{marginBottom:"5rem" , marginTop:"5rem" }}>
                                 
                    <PacienteForm  modal={props.isModalNuevaPaciente} 
                                   cerrarModal={props.setIsModalNuevaPaciente} 
                                 />                
                               
                    <PacienteFormEdit  
                                       payload={props.paciente} 
                                       setPaciente={props.setPaciente}
                                       />                

                    <div className="row">
                        {resultDatos} 
                        {animacionCargando}
                    </div>               
                    <div className="row">
                        <PacienteButtonBar abrirModal={props.setIsModalNuevaPaciente} />
                    </div>                
                    

                </div>
         
    
}

export default Page;
