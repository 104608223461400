import React,{Component} from "react";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {Link} from "react-router-dom";
import { Person } from "@mui/icons-material";
import { ListItemButton } from "@mui/material";

export default class Page extends Component{

    render(){
        
        
        function obtenerColor(nombre) {
            if(nombre!=null){
                var size=nombre.length;
                do {
                   if(size>colores.length){
                      size=size-colores.length;
                   } 
                }while (size>colores.length);
  
              return colores[size];
  
            }else{
                return colores[0];
            }
          }

        let colores=["#babdbe","#00bfa5","#8eacbb","#0288D1","#34515e"];
        let p=this.props.usuario;

        const handleListItemClick = (value) => {
          this.props.setUsuario(value);
        };

        return  <div>
                <ListItem>
                <ListItemButton 
                   dense 
                   button 
                   style={{  paddingBottom:6, paddingTop:6 }} 
                   onClick={()=>handleListItemClick(p)} 

                   >
                    <ListItemAvatar>
					          	<Person style={{ marginRight:".8rem", width: "3.5rem",height: "3.5rem", color:obtenerColor(p.usuario)}} alt={p.usuario} />
                    </ListItemAvatar>

					          <ListItemText id={p.id} 
                        primary={<Typography variant="body1" color="primary"  style={{ textTransform:"none"}}>{p.usuario}</Typography>}
								        secondary={<Typography variant="body1" color="secondary"  style={{ textTransform:"none"}}>{p.correo}</Typography>} />

                </ListItemButton>
                </ListItem>
                <Divider variant="inset" component="li" />

                </div>
    }
}
