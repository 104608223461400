import React,{Component} from "react";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {Link} from "react-router-dom";
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import { getAvatarMedBySex } from "../../../utils/utils";
import { ListItemButton } from "@mui/material";

String.prototype.toHex = function() {
  var hash = 0;
  if (this.length === 0) return hash;
  for (var i = 0; i < this.length; i++) {
      hash = this.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash;
  }
  var color = '#';
  for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 255;
      color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
}

String.prototype.toColor = function() {
	var colors = ["#bf360c","#3e2723","#212121","#616161","#5d4037","#455a64",
                 "#00838f","#f44336","#e91e63","#9c27b0","#673ab7","#3f51b5",
                 "#2196f3","#03a9f4","#00bcd4","#009688","#4caf50","#8bc34a",
                 "#999393","#827717","#ffc107","#ff9800","#ff5722","#795548",
                 "#607d8b","#01579b","#006064","#004d40","#6200ea","#1a237e",
                 "#c51162","#880e4f"]
	
    var hash = 0;
	if (this.length === 0) return hash;
    for (var i = 0; i < this.length; i++) {
        hash = this.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }
    hash = ((hash % colors.length) + colors.length) % colors.length;
    return colors[hash];
}

export default class Page extends Component{

  
    render(){               
        function obtenerColor(nombre) {
            if(nombre!=null){
                var size=nombre.length;
                do {
                   if(size>colores.length){
                      size=size-colores.length;
                   } 
                }while (size>colores.length);
  
              return colores[size];
  
            }else{
                return colores[0];
            }
          }

        let colores=["#babdbe","#00bfa5","#9daa1e","#0288D1","#34515e","#babdbe","#00bfa5","#8eacbb",
                     "#0288D1","#34515e","#babdbe","#00bfa5","#8eacbb","#0288D1","#34515e","#babdbe",
                     "#00bfa5","#8eacbb","#0288D1","#34515e"];
        let medico=this.props.medico;

        
        const handleListItemClick = (value) => {
            this.props.setMedico(value);
        };

        return  <div>
                <ListItem>
                <ListItemButton 
                    dense 
                    style={{  paddingBottom:6, paddingTop:6 }}                   
                    onClick={()=>handleListItemClick(medico)} 
                >
                    <ListItemAvatar>
                        {getAvatarMedBySex(medico?.titulo)}
                    </ListItemAvatar>

				    <ListItemText id={medico.id} 
                             primary={
                                        <Typography variant="body1" color="primary"  style={{ textTransform:"capitalize",color:(medico.titulo+medico.nombres+medico.apellidoPaterno).toColor() }}>{medico.titulo+" "+medico.nombres+" "+medico.apellidoPaterno+" "+medico.apellidoMaterno}</Typography>
                                    }
                    />

                </ListItemButton>
                </ListItem>
                <Divider variant="inset" component="li" />

                </div>
    }
}
