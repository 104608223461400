import React,{Component} from "react";
import Page from "./page";

export default class PacientesList extends Component{

    render(){
        let usuariosFilter = this.props.usuarios.filter((data)=>{
			if(data.usuario!=undefined){
				if(this.props.busqueda == null){
					return data;
				}else if(data.usuario.toLowerCase().includes(this.props.busqueda.toLowerCase())){
					return data;
				}
			}
         });   
  
        return< Page usuariosFilter={usuariosFilter} setUsuario={this.props.setUsuario}/>
    }

}

