import React,{Component} from "react";
import CatTipoCitaListItem from "../CatTipoCitaListItem";
import List from '@mui/material/List';

export default class Page extends Component{

  constructor(props) {
    super(props);
  }

    render(){      
      return <List > {
            this.props.catTipoCitaesFilter.map(catTipoCita=> 
                      <CatTipoCitaListItem catTipoCita={catTipoCita} setTipoCita={this.props.setTipoCita}/> )
            }</List>
    }                          
}
