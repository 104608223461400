import {RestDataSource} from "./RestDataSource";
import { MOSTRAR_MENSAJE, PETICION_WS_SOLICITANDO, PETICION_WS_EXITOSA, PETICION_WS_ERROR,
         TIPO_DE_DATO, PACIENTE,CITA, MEDICO} from "../constants/action-type";
import { CONSULTAR_CITAES, AGREGAR_CITA,ACTUALIZAR_CITA, ELIMINAR_CITA } from "../constants/Cita-action-type";
import { CONSULTAR_MEDICOS } from "../constants/Medico-action-type";
import {PROFIL_ADMINISTRADOR, PROFIL_ANESTESIOLOGO, PROFIL_MEDICO} from "../../utils/constantes";


/* Funcion para loggin llamada logMiddleware */
const createCitaRestMiddleware =(citaURL,medicoURL,pacienteURL)=>{
    /* Obtiene datos de webservice  */
    const dataSources={
        [CITA]: new RestDataSource(citaURL),
        [MEDICO]: new RestDataSource(medicoURL),
        [PACIENTE]: new RestDataSource(pacienteURL)

    }

return ({getState, dispatch}) => next => action => {
    console.log("Dentro de createCitaRestMiddleware:"+action.type);

    /* Antes de enviar al Reducer */
    switch(action.type){

                /** CITA INICIO */                                    
                case CONSULTAR_CITAES:
                    if(getState().CitaReducer.estatus==="idle"||action.loading){                
                    next({type:PETICION_WS_SOLICITANDO});
                    let profil=getState().autenticacionReducer.profil;
                    if(profil===PROFIL_ADMINISTRADOR||profil===PROFIL_MEDICO||profil===PROFIL_ANESTESIOLOGO){ //(27junio21) se agrega el caso para cuando es un administro
                        var datosCitasByInstitucion ={};
                        datosCitasByInstitucion['INSTITUCION_id']=getState().InstitucionReducer.instituciones[0].id;
                        dataSources[action.dataType].GetDataParams((dataCita,mensaje)=>{ 
                           // console.log("CITAS CONSULTA :"+JSON.stringify(action.dataType));
                            if(dataCita===null){
                                if(action.loading){
                                    next({type:PETICION_WS_ERROR});
                                }
                            }else{          
                                let citasFilter=dataCita;                   
                                console.log("CITAS CONSULTA (citasFilter):"+JSON.stringify(citasFilter));
                                next({     //por cada registro devuelto del Webservices se envia la funcion next
                                    type:CONSULTAR_CITAES,
                                    dataType:action.dataType,
                                    payload:citasFilter,
                                    estatus:"succeed"
                                    }); 
                                if(action.loading){
                                    next({type:PETICION_WS_EXITOSA});
                                    next({type:TIPO_DE_DATO,
                                        dataType:action.dataType,
                                        registros:citasFilter.length});
                                }
                              
                            }
                        },datosCitasByInstitucion); 
                    }    
                }
              
                break;   

                case AGREGAR_CITA:
                    next({type:PETICION_WS_SOLICITANDO});

                    console.log("AGREGAR_CITA (persona):"+JSON.stringify(action.payload));
                    
                    dataSources[action.dataType].SetData((dataCita,mensaje)=>{ 
                        if(dataCita===null){
                            console.log("FALLA PETICION A AGREGAR_CITA:"+mensaje);
                            if(action.loading){next({type:PETICION_WS_ERROR});}
                        }else{
                            console.log("AGREGAR_CITA (data):"+dataCita);
        
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:AGREGAR_CITA,
                                dataType:action.dataType,
                                payload:dataCita});
                                
                            let count=parseInt( getState().estadosReducer.registros) + 1;
                            console.log("coun:"+count); 
                            next({type:PETICION_WS_EXITOSA});
                        //    next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se agrego el registro exitosamente!."}); 
                        }
                    },action.payload);      
                 break; 

                 case ACTUALIZAR_CITA:
                    next({type:PETICION_WS_SOLICITANDO});

                    console.log("ACTUALIZAR_CITA (cita):"+JSON.stringify(action.payload));
                    dataSources[action.dataType].UpdateData((dataCita,mensaje)=>{ 
                        if(dataCita===null){
                            console.log("FALLA PETICION A ACTUALIZAR_CITA:"+mensaje);
                            if(action.loading){next({type:PETICION_WS_ERROR});}
                        }else{
                            console.log("ACTUALIZAR_CITA (data):"+dataCita);
        
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:ACTUALIZAR_CITA,
                                dataType:action.dataType,
                                payload:dataCita
                            });
                                
                            next({type:PETICION_WS_EXITOSA});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se actualizo el registro exitosamente!."}); 
                        }
                    },action.payload);                                            
                 break; 

                 case ELIMINAR_CITA:
                    next({type:PETICION_WS_SOLICITANDO});

                    console.log("ELIMINAR_CITA (persona):"+JSON.stringify(action.payload));
                    console.log("ELIMINAR_CITA (paciente):"+JSON.stringify(action.payload));
                    var idCita={};
                    idCita["id"]=action.payload.id;
                    dataSources[action.dataType].DeleteDataParams((dataCita,mensaje)=>{ 
                        if(dataCita===null){
                            console.log("FALLA PETICION A AGREGAR_CITA:"+mensaje);
                            if(action.loading){next({type:PETICION_WS_ERROR});}
                        }else{
                            console.log("ELIMINAR_CITA (data):"+dataCita);
                            if(dataCita===true){
                                next({     //por cada registro devuelto del Webservices se envia la funcion next
                                    type:ELIMINAR_CITA,
                                    dataType:action.dataType,
                                    payload:action.payload.id});
                                    
                                let count=parseInt( getState().estadosReducer.registros) - 1;
                                console.log("coun:"+count); 
                                next({type:PETICION_WS_EXITOSA});
                                next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                                next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se elimino el registro exitosamente!."}); 
    
                            }
        
                        }
                    },idCita);                                               
                 break; 


        default:
                next(action);

    }
}

}

export default createCitaRestMiddleware;