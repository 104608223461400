import React,{Component, useEffect, useState} from "react";
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { getFechaDateToStrin } from "../../../../utils/moment";
import { getHorarioStringDayjs, getHorarioStringViewDayjs } from "../../../../utils/dayJS";
import { Box } from "@mui/material";

function Page (props){


 const [backgroundColor,setBackgroundColor] = useState("#ffffff");
 const [color,setColor] = useState("#ffffff");
 const [icon,setIcon] = useState(<QueryBuilderIcon  color="primary" />);
 
 
 
 useEffect(() => {
    console.log("useEffect (Page) [HoaraioListItem]");
    console.log("props.horaInicio (Page) [HoaraioListItem]:"+props.horaInicio);
    console.log("props.desde (Page) [HoaraioListItem]:"+props.desde);
    console.log("props.action (Page) [HoaraioListItem]:"+props.isDaySelected);

    
    if((props.horaInicio===props.desde?.split(' ')[1])&&props.isDaySelected){    
      setBackgroundColor("#61dafb");
      setIcon(<CheckCircleOutlineIcon style={{color:"#20232a"}} />);
      setColor("#20232a");
    }else{
      setBackgroundColor(props.codColorItem);
      setIcon(<QueryBuilderIcon  color="primary" />);
      setColor("#000000");

    }
  },[props.horaInicio,props.codColorItem,props.isDaySelected]);
  

 const handleClick=()=>{
 
   
    let fechaString=getFechaDateToStrin(props.dayofCard);

    console.log("fecha de horarios (HorariosListItem):"+ fechaString  );
    props.setFecha(fechaString);
    props.setHoraInicio(props.desde?.split(' ')[1]);
    props.setHoraFin(props.hasta?.split(' ')[1]);
 
  }
  
      return <Box  sx={{backgroundColor:""}} >
              <Chip 
                //   avatar={<Avatar>M</Avatar>}
                    size="medium"
                    icon={icon}
                    label={getHorarioStringViewDayjs(props.desde)+" - "+getHorarioStringViewDayjs(props.hasta)} //2024-01-08 07:00:00 - 2024-01-08 07:30:00
                    clickable                    
                    disabled={props.contenido}
                    onClick={handleClick} 
                  // onDelete={handleDelete}
                  //  deleteIcon={<DoneIcon />}
                    variant="outlined"                          
                    style={{
                            backgroundColor:backgroundColor,
                            color:color,
                            borderColor:props.ajustado?"gold":"silver",
                            borderStyle:props.ajustado?"groove":"solid",
                            borderWidth:props.ajustado?"0.2rem":"0.09rem"
                          }}
                    className="chip_horario_seleccionado"
                  />
            </Box>
                                                            
}

export default Page