import {connect, useSelector} from "react-redux";
import React,{Component, useEffect, useState} from "react";
import Page from "./page";
import { ESQUEMA_TIPO_CITA, ESQUEMA_TRABAJO } from "../../../../redux/constants/action-type";

function BuscarHorario (props){

     const [idEsquemaTipoCita,setIdEsquemaTipoCita]=useState(null);

     const esquemaTrabajo=useSelector(state=>state.EsquemaTrabajoReducer[ESQUEMA_TRABAJO]);
     const esquemaTipoCita=useSelector(state=>state.EsquemaTipoCitaReducer[ESQUEMA_TIPO_CITA]);

     console.log("idEsquemaTipoCita [BuscarHorario]:"+idEsquemaTipoCita);
	 console.log("props.CAT_TIPO_CITA_id [BuscarHorario]:"+props.CAT_TIPO_CITA_id);
	 console.log("props.MEDICO_id [BuscarHorario]:"+props.MEDICO_id);

    //render(){

  

    useEffect(()=>{
        let esquemaMedico=null;
        let esquemaTipoCitaFilter=null;

        if(props.MEDICO_id!==""&&props.CAT_TIPO_CITA_id!==""){
            esquemaMedico=esquemaTrabajo.filter(item=>item.MEDICO_id===props.MEDICO_id)[0];
            console.log("esquemaMedico [BuscarHorario]:"+JSON.stringify(esquemaMedico));
        }
        if (esquemaMedico !== undefined) { 

            console.log("esquemaTipoCita [BuscarHorario]stringify:"+JSON.stringify(esquemaTipoCita));

            esquemaTipoCitaFilter=esquemaTipoCita.filter(item=>item.CAT_TIPO_CITA_id===props.CAT_TIPO_CITA_id 
                && item.ESQUEMA_TRABAJO_id===esquemaMedico.id)[0];
            console.log("esquemaTipoCitaFilter [BuscarHorario]:"+esquemaTipoCitaFilter);
            console.log("esquemaTipoCitaFilter [BuscarHorario]:"+JSON.stringify(esquemaTipoCitaFilter));

            if (esquemaTipoCitaFilter !== undefined) { 
                setIdEsquemaTipoCita(esquemaTipoCitaFilter.id);
            }else{
                setIdEsquemaTipoCita(null);
            }

        }else{
            setIdEsquemaTipoCita(null);

        }
    },[props.MEDICO_id,props.CAT_TIPO_CITA_id]);

    if(idEsquemaTipoCita===null || idEsquemaTipoCita===undefined ){
        return <></>;

    }

    return<Page 
            duracionTipoCita={props.duracionTipoCita}

            fecha={props.fecha} 
            fechaBusqueda={props.fechaBusqueda} 
            setFechaBusqueda={props.setFechaBusqueda} 
            setFieldValue={props.setFieldValue} 
            horaInicio={props.horaInicio} 
            horaFin={props.horaFin}
            idEsquemaTipoCita={idEsquemaTipoCita}  

            MEDICO_id={props.MEDICO_id}
            CAT_TIPO_CITA_id={props.CAT_TIPO_CITA_id}

            />
  //  }

}
export default BuscarHorario;