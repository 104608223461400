import React,{Component} from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from '@mui/material/Slide';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DoneIcon from '@mui/icons-material/Done';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import PersonIcon from '@mui/icons-material/PersonRounded';
import ListItemText from '@mui/material/ListItemText';


import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PhoneIcon from '@mui/icons-material/Phone';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import "./estilo.css";
import {getEdad} from "../../../utils/moment"

import InputAdornment from '@mui/material/InputAdornment';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import MuiDialogActions from '@mui/material/DialogActions';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {  Popper } from "@mui/material";

import Popover from '@mui/material/Popover';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import PersonAdd from '@mui/icons-material/PersonAdd';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function Page(props) {
		
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
	  setAnchorEl(anchorEl ? null : event.currentTarget);
	};
  
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popper' : undefined;

	function goBack(){
		props.history.goBack();
	}


	function handleFechaDeNacimiento(e){
		props.handleChange(e);
		let edad=getEdad(new Date(e.target.value));
		if(edad<150){
			props.setFieldValue("edad",edad);	
		}
	}

	function handleChange(e){
		props.setFieldValue("SEXO_id",e.target.value);			  
	}


	let appBar="";
	let actions="";

	if(fullScreen){
		appBar=<AppBar color="default" style={{boxShadow: "none",backgroundColor:"#FFFFFF"}}>
					<Toolbar>
						<IconButton
                            edge="start"
                            color="inherit"
                            onClick={goBack}
                            aria-label="close"
                            size="large">
						<ArrowBackIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}}  />
						</IconButton>
						<Typography variant="h6" style={{ flexGrow: 1}} >
							{<Typography type="Roboto" style={{flexGrow:"1",color: '#000000', fontSize:'1.2rem',fontWeight:'normal',textTransform: "capitalize" }}>Nuevo Paciente</Typography>}
						</Typography>

						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>

					</Toolbar>
					<Divider/>
				</AppBar>

	}else{
		actions=<MuiDialogActions>
						<Button variant="contained" edge="start" onClick= {props.cerrarModal}>Cancelar</Button>
						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>
				</MuiDialogActions>
	}
   
        return (
            <form onSubmit={props.handleSubmit}> 




                                                                            <div>
                                                                            
                                                                            
                                                                            
                                                                                <Card >
                                                                                <CardContent>
                                                                                    <Typography  color="textSecondary" gutterBottom>
                                                                                    Word of the Day
                                                                                    </Typography>
                                                                                    <Typography variant="h5" component="h2">
                                                                                    benevoent
                                                                                    </Typography>
                                                                                    <Typography  color="textSecondary">
                                                                                    adjective
                                                                                    </Typography>
                                                                                    <Typography variant="body2" component="p">
                                                                                    well meaning and kindly.
                                                                                    <br />
                                                                                    {'"a benevolent smile"'}
                                                                                    </Typography>
                                                                                </CardContent>
                                                                                <CardActions>
                                                                                    <Button size="small">Learn More</Button>
                                                                                </CardActions>
                                                                                </Card>
                                                                            </div>
                                                                       
                    </form>
        );
    
}
export default  Page;
