import { createContext,useContext, useState } from "react";
import { useSelector } from "react-redux";

const AuthContext = createContext()

export const AuthProvider=({children})=>{
    const autenticado = useSelector(state =>state.autenticacionReducer.autenticado)

    console.log("autenticado (AuthProvider):"+autenticado);
    
    const [isLoggedIn, setIsLoggedIn] = useState(autenticado);

    const login = () => setIsLoggedIn(true);
    const logout = () => setIsLoggedIn(false);
  
    return (
        <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth=()=>{
    return useContext(AuthContext)
}