import { Button } from '@mui/material';
import './App.css';
import dataStore from "./redux/store";
import { BrowserRouter} from "react-router-dom";
import { blue, pink,teal,orange,red ,lightBlue,grey,blueGrey} from '@mui/material/colors';
import { ThemeProvider, StyledEngineProvider, createTheme,  } from '@mui/material/styles'; // v1.x
import {Provider} from "react-redux";
import { Component, useEffect } from 'react';
import setAuthorizationToken from './utils/setAuthorizationToken';
import { agregarUsuarioActual } from './redux/actions/autenticacionActions';
import { obtenerMedicoUsuarioListLocalStorageAction } from './redux/actions/MedicoUsuarioActions';
import { obtenerInstitucionListLocalStoraAction } from './redux/actions/InstitucionActions';
import {obtenerPacienteUsuarioListLocalStorageAction} from "./redux/actions/PacienteUsuarioActions";
import { PROFIL_MEDICO, PROFIL_PACIENTE } from './utils/constantes';
import { INSTITUCION, MEDICO_USUARIO, PACIENTE_USUARIO } from './redux/constants/action-type';
import { decodeToken } from "react-jwt";
import Contenido from './component/Contenido';

const theme = createTheme({
  typography: {
    "fontFamily": `"Roboto","Helvetica", "Arial", "sans-serif"`,
    "fontSize": 16,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500,
    "fontWeightBold":600,
    "fontWeight": 400,
    h6 : {
      "fontSize": '1.4rem',     
    },
    subtitle1:{
      "fontWeight":500,
      "fontSize": '1.1rem', 
      "lineHeight":"1.2"    

    },
    subtitle2:{
      "fontWeight": 400,
      "fontSize": '1.1rem', 
      "lineHeight":"1.1"    
    },
    body1:{
      "lineHeight":"1.3"   
    }
   },
  palette:{    
    primary:{
      main:blue["600"]
    },
    secondary:{
      main:grey['700']
    },background:{
      main:blueGrey['400']
    }
  },
  
 });

 class App extends Component{

  init(){
    if(localStorage.jwtToken){
      const myDecodedToken = decodeToken(localStorage.jwtToken);

      setAuthorizationToken(localStorage.jwtToken);
      console.log("useEffect App (useJwt.jwtToken) "+myDecodedToken.data);

      dataStore.dispatch(agregarUsuarioActual(myDecodedToken.data,localStorage.profil));

      if(localStorage.profil===PROFIL_MEDICO){
        dataStore.dispatch(obtenerMedicoUsuarioListLocalStorageAction(MEDICO_USUARIO,JSON.parse(localStorage.medicoUsuario)));
      }else if(localStorage.profil===PROFIL_PACIENTE){
        dataStore.dispatch(obtenerPacienteUsuarioListLocalStorageAction(PACIENTE_USUARIO,JSON.parse(localStorage.pacienteUsuario)));
      }
      dataStore.dispatch(obtenerInstitucionListLocalStoraAction(INSTITUCION,JSON.parse(localStorage.institucion)));
     
    }
  }

  render(){  
  this.init();
    return (
      <div className="App">
          <BrowserRouter >         
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                  <Provider store={dataStore}>              
                      <Contenido/>
                  </Provider>
              </ThemeProvider>
            </StyledEngineProvider>
            </BrowserRouter>
      </div>
	)
  }
}


export default App;
