import React,{Component} from "react";
import LoginForm from "../LoginForm";


export default class Page extends Component{

    render(){      
      return <div style={{marginBottom:"5rem" , marginTop:"5rem" }}>
                <LoginForm autenticacionLogin={this.props.autenticacionLogin} 
                           errorsForm={this.props.errorsForm} 
                           usuario={this.props.usuario}
                           loading={this.props.loading}/>     
             </div>
    }                          
}
