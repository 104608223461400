import React,{Component} from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from '@mui/material/Slide';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DoneIcon from '@mui/icons-material/Done';
import Divider from '@mui/material/Divider';
import {ID_ROL_MEDICO,ID_ROL_PACIENTE, PROFIL_ROOT } from "../../../utils/constantes";
import MedicoFormSelect from "../MedicoFormSelect";
import PacienteFormSelect from "../PacienteFormSelect";

import InputAdornment from '@mui/material/InputAdornment';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import MuiDialogActions from '@mui/material/DialogActions';
import InstitucionFormSelect from "../InstitucionFormSelect";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  function Page(props) {
		
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	function goBack(){
		props.resetForm({values:""});
		props.cerrarModal(false);
	}

	let appBar="";
	let actions="";

	if(fullScreen){
		appBar=<AppBar color="default" style={{boxShadow: "none",backgroundColor:"#FFFFFF"}}>
					<Toolbar>
						<IconButton
                            edge="start"
                            color="inherit"
                            onClick={goBack}
                            aria-label="close"
                            size="large">
						<ArrowBackIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}}  />
						</IconButton>
						<Typography variant="h6" style={{ flexGrow: 1}} >
							Nueva Usuario
						</Typography>

						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>

					</Toolbar>
					<Divider/>
				</AppBar>

	}else{
		actions=<MuiDialogActions>
						<Button variant="contained" edge="start" onClick= {goBack}>Cancelar</Button>
						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>
				</MuiDialogActions>
	}

	let Institucion="";
	if(props.idInstitucion===""){
		Institucion=<FormControl variant="standard" fullWidth margin="normal">
			<InputLabel htmlFor="age-native-simple">INSTITUCION</InputLabel>
			<Select
				error={props.touched.usuario&&props.touched.usuario.INSTITUCION_id&&props.errors.usuario&&props.errors.usuario.INSTITUCION_id? true:false}
				size="medium"
				id="usuario.INSTITUCION_id"
				label="INSTITUCION_id:"
				type="text"
				fullWidth
				variant="outlined"
				onChange={props.handleChange} value={props.values.usuario.INSTITUCION_id}
				onBlur={props.handleBlur}
				native
			>
					<option aria-label="None" value="" disabled/>
		
				{
				props.instituciones.map(institucion=> (
					<option key={institucion.id}  value={institucion.id}>{institucion.nombre}</option>)
				)}
			</Select>
		</FormControl>

	}


	//	let //
	console.log("ERRORS :"+JSON.stringify(props.errors));
	let institucionesList="";
	if(props.profil==PROFIL_ROOT){
		institucionesList=<InstitucionFormSelect
								INSTITUCION_id="usuario.INSTITUCION_id"
								institucionValue={props.values.usuario.INSTITUCION_id}
								errors={props.errors}
								touched={props.touched} 
								handleChange={props.handleChange}
							/>		
	}

	let medicosList="";
	medicosList=(props.values.rol.CAT_ROL_id===ID_ROL_MEDICO)?
							<MedicoFormSelect MEDICO_id="rol.MEDICO_id"  
								medicoValue={props.values.rol.MEDICO_id}
								errors={props.errors}
								touched={props.touched} 
								handleChange={props.handleChange} 
								/>:null;
	let pacientesList="";
	pacientesList=(props.values.rol.CAT_ROL_id===ID_ROL_PACIENTE)?
							<PacienteFormSelect PACIENTE_id="rol.PACIENTE_id"  
								pacienteValue={props.values.rol.PACIENTE_id}
								INSTITUCION_id={props.values.usuario.INSTITUCION_id}
								errors={props.errors}
								touched={props.touched} 
								handleChange={props.handleChange} 
								/>:null;


														
        return (<form onSubmit={props.handleSubmit}> 
                    <Dialog
                    open={props.modal}
                    onClose={props.cerrarModal}
                    aria-labelledby="form-dialog-title"
                    TransitionComponent={Transition}
					fullScreen={fullScreen}


                    >
                    
                        <DialogTitle id="form-dialog-title">Nuevo Usuario</DialogTitle>
                        
						{appBar}

							<DialogContent>
							<TextField
								error={props.touched.usuario&&props.touched.usuario.usuario&&props.errors.usuario&&props.errors.usuario.usuario? true:false}
								helperText={props.touched.usuario&&props.touched.usuario.usuario&&props.errors.usuario&&props.errors.usuario.usuario?props.errors.usuario.usuario:""}
								size="medium"
								margin="normal"
								id="usuario.usuario"
								label="Usuario:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} 
								value={props.values.usuario.usuario}
								onBlur={props.handleBlur}
							/>
							<TextField
								error={props.touched.usuario&&props.touched.usuario.password&&props.errors.usuario&&props.errors.usuario.password? true:false}
								helperText={props.touched.usuario&&props.touched.usuario.password&&props.errors.usuario&&props.errors.usuario.password?props.errors.usuario.password:""}
								size="medium"
								margin="normal"
								id="usuario.password"
								label="Password:"
								type="password"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.usuario.password}
								onBlur={props.handleBlur}
							/>
							<TextField
								error={props.touched.usuario&&props.touched.usuario.passwordConfirmacion&&props.errors.usuario&&props.errors.usuario.passwordConfirmacion? true:false}
								helperText={props.touched.usuario&&props.touched.usuario.passwordConfirmacion&&props.errors.usuario&&props.errors.usuario.passwordConfirmacion?props.errors.usuario.passwordConfirmacion:""}
								size="medium"
								margin="normal"
								id="usuario.passwordConfirmacion"
								label="Confirmacion:"
								type="password"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.usuario.passwordConfirmacion}
								onBlur={props.handleBlur}
							/>
							<TextField
								error={props.touched.usuario&&props.touched.usuario.correo&&props.errors.usuario&&props.errors.usuario.correo? true:false}
								helperText={props.touched.usuario&&props.touched.usuario.correo&&props.errors.usuario&&props.errors.usuario.correo?props.errors.usuario.correo:""}
								size="medium"
								margin="normal"
								id="usuario.correo"
								label="Correo:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.usuario.correo}
								onBlur={props.handleBlur}
							/>
							{institucionesList}
							<FormControl
							fullWidth
							margin="normal"
							variant="filled" 
							
							>
							    <InputLabel htmlFor="age-native-simple">PERFIL</InputLabel>
								<Select
									error={props.touched.rol&&props.touched.rol.CAT_ROL_id&&props.errors.rol&&props.errors.rol.CAT_ROL_id? true:false}
									size="medium"
									id="rol.CAT_ROL_id"
									label="PERFIL:"
									type="text"
									fullWidth
									variant="outlined"
									onChange={props.handleChange} value={props.values.rol.CAT_ROL_id}
									onBlur={props.handleBlur}
									native
								>
								          <option aria-label="None" value="" disabled/>
							
									{
									props.roles.map(rol=> (
										<option key={rol.id}  value={rol.id}>{rol.nombre}</option>)
									)}
								</Select>
							</FormControl>
							{
								medicosList
							}			
							{
								pacientesList
							}			

                            </DialogContent>
							{actions}                
 
                    </Dialog>
                    </form>
                       
                )
    
}
export default  Page;
