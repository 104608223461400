import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {USUARIO,CAT_ROL,INSTITUCION} from "../../../redux/constants/action-type";
import {connect, useDispatch, useSelector} from "react-redux";
import {obtenerCatRolListWSAction} from "../../../redux/actions/CatRolActions"
import { CONSULTAR_PACIENTEES } from "../../../redux/constants/Paciente-action-type";
import {ID_ROL_MEDICO,ROLES_ERLAUB_ARRAY} from "../../../utils/constantes"
import { agregarUsuarioWSAction } from "../../../redux/actions/UsuarioActions";

const addressRegex = /^[a-zA-Z0-9][a-zA-Z0-9 .,-]*$/;
const passwordRegex = /^[a-zA-Z0-9][a-zA-Z0-9 .,]*$/;
const alfanumericoRegex = /^[a-zA-Z0-9][a-zA-Z0-9]*$/;


const validationSchema = yup.object().shape({
    usuario:yup.object().shape({
        usuario: yup.string().matches(
            alfanumericoRegex,
            "Solo es permitido valores alphanumericos (letras y numeros)."
          ).min(8, 'debe ser mayor a 8 caracteres').max(25, 'debe ser menor a 25 caracteres').required("Campo requerido"),
		password: yup.string().matches(
            passwordRegex,
            "Solo es permitido valores alphanumericos (letras y numeros) puntos y comas."
          ).min(8, 'debe ser mayor a 8 caracteres').max(45, 'debe ser menor a 45 caracteres').required("Campo requerido"),
        passwordConfirmacion:yup.string().oneOf([yup.ref("password"),null],"Las contraseñas no coinciden. Vuelve a intentarlo."), 
		correo: yup.string().min(2, 'debe ser mayor a 2 caracteres').max(45, 'debe ser menor a 45 caracteres').email('Correo no valido (revisa no tenga espacios)').required("Campo requerido"),
        }),
    rol:yup.object().shape({
        CAT_ROL_id: yup.string().required("Campo requerido"),
        MEDICO_id: yup.string().when("CAT_ROL_id",{ is:ID_ROL_MEDICO,then:yup.string().required("Campo requerido")})
        }),
})


function UsuarioForm (props){ 
    const dispatch=useDispatch();
    const usuariosStore=useSelector(state=>state.UsuarioReducer[USUARIO]);
    const roles=useSelector(state=>state.CatRolReducer[CAT_ROL]);
    const instituciones= useSelector(state=>state.InstitucionReducer[INSTITUCION]);
    const profil= useSelector(state=>state.autenticacionReducer.profil);

/*
class UsuarioForm extends Component{
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this); // i think you are missing this
     }
    */
     const goBack=()=>{
        // this.props.history.goBack();
       props.cerrarModal(false);

    }
   // render(){    

        let idInstitucion="";
        if(instituciones!==undefined){
            idInstitucion=instituciones[0].id;
        }

    const filteredRoles = roles.filter(rol => {
            return ROLES_ERLAUB_ARRAY.some((val) => rol.nombre === val);
     });
    
    const existUsuario=(values)=>{
            let result=false;

            result=usuariosStore.some(item=>item?.usuario===values?.usuario);

            return result;
    }

        return <Formik

                    initialValues={{
                        usuario:{'usuario': '',
                                 'password': '',
                                 'passwordConfirmacion': '',
                                 'correo': '',
                                 'ultimoLogin': '',
                                 'token': '',
                                 'INSTITUCION_id': idInstitucion,
                                 'ESTADO_id': '1' },
                            rol:{'CAT_ROL_id': '',
                                'MEDICO_id': '',
                                'PACIENTE_id': ''} // 21Feb21Dom - Guarda el id del paciente para este Usuario 
                    }}

                    validationSchema={validationSchema}

                    onSubmit={(values,{setSubmitting, resetForm,setErrors}) => {


                        console.log("AGREGAR USUARIO (setSubmitting)valores: "+JSON.stringify(values));    
                        
                        if(existUsuario(values)){
                            setErrors({nombres:" ",
                                       apellidoPaterno:" ",
                                       apellidoMaterno:"!El médico ya existe!"}) 
                        } else{
                            setSubmitting(dispatch(agregarUsuarioWSAction(USUARIO,values)));
                            resetForm({values:""});
                            goBack();
                              
                        }                        

                      }}
                >
                    {propers => < Page {...propers} 
                                     modal={props.modal} 
                                     cerrarModal={props.cerrarModal} 

                                     idInstitucion={idInstitucion} 
                                     instituciones={instituciones}  
                                     roles={filteredRoles}   
                                     profil={profil}
                                     />}
                </Formik> 
    }

   /* componentDidMount(){
        console.log("obtenerCatRolListWSAction: (desde) componentDidMount")
        this.props.obtenerCatRolListWSAction(CAT_ROL,false); 
    */
 //   }
 
//}
/*
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        obtenerCatRolListWSAction: (type,loading) => dispatch(obtenerCatRolListWSAction(type,loading)),
    }
}

const mapStateToProps=(state)=>({
  instituciones:state.InstitucionReducer[INSTITUCION],
  roles:state.CatRolReducer[CAT_ROL],
  profil:state.autenticacionReducer.profil,     

})
*/
//export default connect(mapStateToProps,mapDispatchToProps)(UsuarioForm);
export default UsuarioForm;


