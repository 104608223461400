import React,{Component, useEffect, useState} from "react";
import Page from "./page";

export default function PacientesList(props) {

	const [query,setQuery]=useState("");
	const [loading,setLoading]=useState(true);
	const [rows,setRows]=useState(15);
	const [page,setPage]=useState(1);
	const [dataPaciente,setDataPaciente]=useState([]);
	const [hatMehr,setHatMehr]=useState(false);
	const [paciente,setPaciente]=useState([]);
    
       // let pacienteesFilter = props.pacientees.filter((data)=>{
		let pacienteesFilter = paciente.filter((data)=>{
			if(data!=undefined){
				if(data.nombres!=undefined){
					if(props.busqueda == null){
						return data;
					}else if(data.nombres.toLowerCase().includes(props.busqueda.toLowerCase())||
								data.apellidoPaterno.toLowerCase().includes(props.busqueda.toLowerCase())||
									data.apellidoMaterno.toLowerCase().includes(props.busqueda.toLowerCase())){
						return data;
					}
				}
			}
         });   

  useEffect(() => {
	console.log("(useEffect) props.pacientees:"+JSON.stringify(props.pacientees));
	setPage(1);
	setHatMehr(false);
	setDataPaciente([]);
	setPaciente(props.pacientees);

  },[props.pacientees]);

	useEffect(() => {

		console.log("(useEffect) pacienteesFilter:"+JSON.stringify(pacienteesFilter));

		if(props.busqueda !== query){
			setQuery(props.busqueda );
			setPage(1);
			setHatMehr(false);
			setDataPaciente([]);
		}

		setLoading(true);
		let newDataPaciente=[...pacienteesFilter.slice(((rows*page)-rows), (rows*page))];
		console.log();
		console.log(page);

		setDataPaciente( prev=> {
			console.log("(useEffect) dataPaciente1 prev:"+JSON.stringify(prev));

							return [...prev,...pacienteesFilter.slice(((rows*page)-rows), (rows*page))]
						});
						
						console.log("(useEffect) dataPaciente1:"+JSON.stringify(newDataPaciente));
						console.log("(useEffect) dataPaciente2:"+[...pacienteesFilter.slice(((rows*page)-rows), (rows*page))]);
						console.log("(useEffect) dataPaciente4:"+JSON.stringify(dataPaciente));

		setHatMehr(newDataPaciente.length>0);
		setLoading(false);
	},[
		page, 
		props.busqueda,
		paciente
	  ]);

	  console.log("(body) dataPaciente:"+JSON.stringify(dataPaciente));


	return(
			< Page pacienteesFilter={dataPaciente} 
					setPaciente={props.setPaciente} 
					setPage={setPage} 
					loading={loading} 
					hatMehr={hatMehr} 
					busqueda={props.busqueda}
					/>
			)
    

}

