import React,{Component, useState} from "react";
import CatTipoCitaList from "../CatTipoCitaList";
import CatTipoCitaForm from "../CatTipoCitaForm";
import CircularProgress from '@mui/material/CircularProgress';
import CatTipoCitaButtonBar from "../CatTipoCitaFloatButton";
import CatTipoCitaFormEdit from "../CatTipoCitaFormEdit";
import Fab from '@mui/material/Fab';
import { useSelector } from "react-redux";
import Busqueda from "../../BusquedaComponent";
import { Collapse } from "@mui/material";


function Page (props){

    const isBusqueda =useSelector(state =>state.estadosReducer.mostrarBusqueda);    
    const [busqueda,setBusqueda]=useState("");

        let loading=props.loading;
        var resultDatos;
        var animacionCargando;
        if (loading) {
            animacionCargando = <Fab position="fixed" variant="circular" size="small" aria-label="cargando" style={{position: 'fixed', top: "5.5rem", float: "left",
                                    left: "45%", boxShadow:"1", backgroundColor:"#FFFFFF",padding:"0.1rem"}}>
                                    <CircularProgress color="secondary" size="1.5rem"/>           
                                </Fab>
        }else{
            animacionCargando=<div/>
        }
        
        if(props.catTipoCitaes.length>0){
            resultDatos =  <div className="col-12" >      
                                <Collapse in={isBusqueda}>
                                     <Busqueda label="Buscar Tipo de Cita" setBusqueda={setBusqueda}  />
                                </Collapse>                 
                                                                    
                                <CatTipoCitaList  catTipoCitaes={props.catTipoCitaes} 
                                                  setTipoCita={props.setTipoCita}
                                                  busqueda={busqueda}/> 
                            </div>
                                
        }


        return <div style={{marginBottom:"5rem" , marginTop:"5rem" }}>
                                 
                    <CatTipoCitaForm  
                                    modal={props.isModalNueva} 
                                    cerrarModal={props.setIsModalNueva} 
                    />                
                               
                    <CatTipoCitaFormEdit  
                                        payload={props.tipoCita} 
                                        setTipoCita={props.setTipoCita} 
                    />                

                    <div className="row">
                        {resultDatos} 
                        {animacionCargando}
                    </div>               
                    <div className="row">
                        <CatTipoCitaButtonBar abrirModal={props.setIsModalNueva} />
                    </div>                
                    

                </div>
         
}

export default Page;
