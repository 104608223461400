import React,{Component, useEffect, useState} from "react";
import Grid from '@mui/material/Grid';
import HorarioList from "../HorarioList"
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ViewDayOutlinedIcon from '@mui/icons-material/ViewDayOutlined';
import "./estilo.css"
import { getFechaDateToStrin, getFechaFormatoWeekDayMothYear } from "../../../../utils/moment";

function Page (props){

  const [actionIcon,setActionIcon]=useState("");



  useEffect(()=>{
    let fechaString=getFechaDateToStrin(props.dayofCard);
    
    console.log("getFechaComponent useEffect (Page) [DiaSemanaListItem]"+props.fecha);
    console.log("dayofCard useEffect (Page) [DiaSemanaListItem]"+props.dayofCard);
    console.log("fechaString useEffect (Page) [DiaSemanaListItem]"+fechaString);    
    
    if(props.fecha===fechaString){
      setActionIcon(<CheckCircleIcon  fontSize="medium" color="primary"/>);
    }else{
      setActionIcon(<ViewDayOutlinedIcon fontSize="medium" color="secondary"/>);
    }
  },[props.fecha]);

             return <Grid item xs={12}>
                      <Card variant="elevation" elevation={3} className="card_day" color="default" >
                      <CardHeader     
                        className="text_fecha"           
                        title={<Typography>{getFechaFormatoWeekDayMothYear(props.dayofCard)}</Typography>}
                        action={actionIcon}
                      /> 
                      <CardContent >
                        {
                          props.periodoList.map((item,index)=>{
                            return  <HorarioList  
                                      key={index}                                      
                                      horaInicial={item.horaInicial} 
                                      horaFinal={item.horaFinal} 
                                      idDia={item.DIA_SEMANA_id}   
                                      dayofCard={props.dayofCard}
        
                                    
                                      duracionTipoCita={props.duracionTipoCita}

                                      fecha={props.fecha} 
                                      fechaBusqueda={props.fechaBusqueda} 
                                      setFieldValue={props.setFieldValue} 
                                      horaInicio={props.horaInicio} 
                                      horaFin={props.horaFin}
                                      diaBusquedaSelecionado={props.diaBusquedaSelecionado}

                                  />

                          }

                          )
                        }
                      </CardContent>
                      </Card>
                    </Grid> 
            
                      
}

export default Page