import React,{Component} from "react";
import Page from "./page";
import { connect } from "react-redux";
import {getMesNombre,getDiaSemanaNombre,getFechaHoraString,getFormatoDesdeDate} from "../../../../utils/date";
import {isHorarioIntersect,isHorarioDesdeContenido,isHorarioHastaContenido,ajustaHorarioHaciaDelante,ajustaHorarioHaciaAtras,getFormatoHorario, dayOffset, horariosRango, diffHorariosRangoDays} from "../../../../utils/moment"
import { CITA } from "../../../../redux/constants/action-type";
import { combinaDateTimeDays, horariosRangoDays, rangeBetweenDates } from "../../../../utils/dayJS";
//import {CITA} from "../../../../../redux/constants/action-type";

class HorarioList extends Component{
	
	 setHoraInicio=(valor)=>{
		this.props.setFieldValue('horaInicio',valor);
	 }
	 setHoraFin=(valor)=>{
		this.props.setFieldValue('horaFin',valor);
	 }
	 setFecha=(valor)=>{
		this.props.setFieldValue('fecha',valor);
	 }

	addMinutes=(date, minutes)=>{
		return new Date(date.getTime() + minutes*60000);
	}
	


		 //26Ene21 se agrega reducer para remover los objetos duplicados
	quitarHorariosDuplicados=(horariosEncostrados)=>{
		let filteredArr = horariosEncostrados.filter( (ele, ind) => ind === horariosEncostrados.findIndex( elem => elem.desde === ele.desde && elem.hasta === ele.hasta))		
	  return filteredArr;
	}	 
		//27Ene2021 validacion para horarios ajustados que van hacia atras validando que no se encimen en un horario de cita ya agendado
	isHorarioEnCitaIntersect=(horarioDesde, horarioHasta)=>{
		let result=false;

		for (var key in this.props.citaes) {
			if (this.props.citaes.hasOwnProperty(key)) {
				let horarioStringInicial=getFechaHoraString(this.props.citaes[key].fecha,this.props.citaes[key].horaInicio);
				let horarioStringFinal=getFechaHoraString(this.props.citaes[key].fecha,this.props.citaes[key].horaFin);
				if(isHorarioIntersect(horarioStringInicial,horarioStringFinal,horarioDesde,horarioHasta)){
					result=true;
					break;
				}
			}
		  }


		return result;
	}	

	getHorarios=(dateResult)=>{
		let horariosDisponibles=[]; //HORARIOS ENCONTRADOS POR EL PERIODO DEL ESQUEMA DE TRABAJO DEFINIDO POR EL USUARIO Y SELECCIONADO POR EL USUARIO EN EL FORMULARIO.
	//	let horarios=[]; //HORARIOS ENCONTRADOS POR EL PERIODO DEL ESQUEMA DE TRABAJO DEFINIDO POR EL USUARIO Y SELECCIONADO POR EL USUARIO EN EL FORMULARIO.
		/* obtener los horarios disponibles (monstrando fecha y hora)*/ 
		//07Ene21 crea los horarios inicial y final a partir del periodo establecido en el esquema de trabajo y por el tipo de cita seleccionado por el usuario en el formulario.
		let date1=combinaDateTimeDays(dateResult,this.props.horaInicial);
		let date2=combinaDateTimeDays(dateResult,this.props.horaFinal);
		//let date1 =new Date(dateResult.setHours(parseInt(this.props.horaInicial.split(":")[0]),parseInt(this.props.horaInicial.split(":")[1])));
		//let date2 =new Date(dateResult.setHours(parseInt(this.props.horaFinal.split(":")[0]),parseInt(this.props.horaFinal.split(":")[1])));

		console.log("horaInicial:"+this.props.horaInicial);
		console.log("horaFinal:"+this.props.horaFinal);
		
		//horariosRango(this.props.horaInicial);
	//	horariosRangoDays(this.props.horaInicial);

		//diffHorariosRangoDays(this.props.horaInicial,this.props.horaFinal);
		//combinaDateTimeDays(dateResult,this.props.horaInicial);
		//combinaDateTimeDays(dateResult,this.props.horaFinal);

		console.log("date1:"+date1);
		console.log("date2:"+date2);


		//let duracionTipoCitaMinutos=this.countMinutos(this.props.duracionTipoCita);//SE DESCONTINUA CAMBIA BASE DE DATOS DURACIONA INT (18Abril21)
		let duracionTipoCitaMinutos=this.props.duracionTipoCita;
		console.log("this.props.duracionTipoCita:"+this.props.duracionTipoCita);

		console.log("duracionTipoCita(minutos):"+duracionTipoCitaMinutos);

		let range= rangeBetweenDates(date1,date2,duracionTipoCitaMinutos);
		/*range.map((item,index)=>{
			console.log("item:"+JSON.stringify(item));
			console.log("index:"+index);
			//this.horarioContenidoList(item.desde,item.hasta,duracionTipoCitaMinutos,horariosDisponibles);

		});*/
		return range;

		while(date1.getTime()<date2.getTime()){ 
			let inicioDate=new Date(date1);
			date1=this.addMinutes(date1,duracionTipoCitaMinutos);    //ENCUENTRA LOS HORARIOS EN BASE A 30 Min
			let finDate=new Date(date1);
			
			let desde=getFormatoDesdeDate(inicioDate);
			let hasta=getFormatoDesdeDate(finDate);
			console.log("desde:"+desde);
			console.log("hasta:"+hasta);


		//	this.horarioContenidoList(desde,hasta,duracionTipoCitaMinutos,horariosDisponibles);

			//horarios.push([inicioDate,finDate]);
			//horariosDisponibles.push({desde,hasta});

		}
	//	return horarios;		
	//return horariosDisponibles;
		return this.quitarHorariosDuplicados(horariosDisponibles);
	 }

	 horarioContenidoList=(desde,hasta,duracionTipoCitaMinutos,horariosDisponibles)=>{
		let horarioAjustados=[]; 
		let horarioContenidoList=[];
		let isHorarioAjustado=false;
		let isContenido=false;
		let desdeLocal=desde;
		let hastaLocal=hasta;

        this.props.citaes.map(item=>{
            let horarioStringInicial=getFechaHoraString(item.fecha,item.horaInicio);
            let horarioStringFinal=getFechaHoraString(item.fecha,item.horaFin);
			
         //   if(this.isHorarioContenido(horarioStringInicial,horarioStringFinal,horarioStringItem)){}
		 console.log("AQUIII LO ENCUENTRA ITERACION(desdeLocal):"+desdeLocal);
		 console.log("AQUIII LO ENCUENTRA ITERACION (hastaLocal):"+hastaLocal);
		 console.log("AQUIII LO ENCUENTRA ITERACION (item):"+JSON.stringify(item));
			if(isHorarioIntersect(horarioStringInicial,horarioStringFinal,desdeLocal,hastaLocal)){

				//27Ene21 Se agrega cita para mostrarlo en los horaios disponibles
				let horarioItemCita={"desde":horarioStringInicial,"hasta":horarioStringFinal,"ajustado":false,"contenido":true,"idTipoCita":item.CAT_TIPO_CITA_id};
				horariosDisponibles.push(horarioItemCita);

				horarioContenidoList.push(item);       
				let horarioDesdeContenido=isHorarioDesdeContenido(horarioStringInicial,horarioStringFinal,desdeLocal);
				let horarioHastaContenido=isHorarioHastaContenido(horarioStringInicial,horarioStringFinal,hastaLocal);

				console.log("AQUIII LO ENCUENTRA PARENT (desde):"+horarioDesdeContenido);
				console.log("AQUIII LO ENCUENTRA PARENT (hasta):"+horarioHastaContenido);

				if(horarioDesdeContenido&&horarioHastaContenido){
					console.log("AQUIII LO ENCUENTRA PARENT (1):");
					isContenido=true;

				}else if(horarioDesdeContenido&&!horarioHastaContenido){
					console.log("AQUIII LO ENCUENTRA PARENT (2):");
					desdeLocal=horarioStringFinal;
					hastaLocal=ajustaHorarioHaciaDelante(horarioStringFinal,duracionTipoCitaMinutos)
					isHorarioAjustado=true;
				}else if(!horarioDesdeContenido&&horarioHastaContenido){
					console.log("AQUIII LO ENCUENTRA PARENT (3):");
					//27Ene2021 valida si el horario hacia atras es valido o no interceptado en las citas ajendadas
					//console.log("isHorarioEnCitaIntersect:"+this.isHorarioEnCitaIntersect(ajustaHorarioHaciaAtras(horarioStringInicial,duracionTipoCitaMinutos),horarioStringInicial))

					if(this.isHorarioEnCitaIntersect(ajustaHorarioHaciaAtras(horarioStringInicial,duracionTipoCitaMinutos),horarioStringInicial)){// Recorrer todas las citas y validar si no se intercescta para que sea valida el ajuste de horario-
						desdeLocal=horarioStringFinal;
						hastaLocal=ajustaHorarioHaciaDelante(horarioStringFinal,duracionTipoCitaMinutos)
					}else{ 
						desdeLocal=ajustaHorarioHaciaAtras(horarioStringInicial,duracionTipoCitaMinutos)
						hastaLocal=horarioStringInicial
					}
					isHorarioAjustado=true;
				}else if(!horarioDesdeContenido&&!horarioHastaContenido){
					console.log("AQUIII LO ENCUENTRA PARENT (4):");

					desdeLocal=horarioStringFinal;
					hastaLocal=ajustaHorarioHaciaDelante(horarioStringFinal,duracionTipoCitaMinutos)
					isHorarioAjustado=true;

				}

            }        
		})
		
		console.log("AQUIII LO ENCUENTRA PARENT (desdeLocal):"+desdeLocal);
		console.log("AQUIII LO ENCUENTRA PARENT (hastaLocal):"+hastaLocal);


		if(isContenido!==true){
			let horarioItem={"desde":desdeLocal,"hasta":hastaLocal,"ajustado":isHorarioAjustado,"contenido":isContenido,"idTipoCita":""};
			console.log("AQUIII LO ENCUENTRA PARENT (horarioItem):"+JSON.stringify(horarioItem));	
			horariosDisponibles.push(horarioItem);
			isContenido=false;
		}
		
		return horarioContenidoList;
    }
	 
    render(){

	let horarios=this.getHorarios(this.props.dayofCard);
	console.log("horarios<<<:"+JSON.stringify(horarios));
	
	return <div><Page 
				 horarios={horarios} 

				 dayofCard={this.props.dayofCard}


				 setFecha={this.setFecha} 
				 setHoraInicio={this.setHoraInicio} 
				 setHoraFin={this.setHoraFin} 

				 fecha={this.props.fecha} 
				 horaInicio={this.props.horaInicio} 
				 horaFin={this.props.horaFin}
				 duracionTipoCita={this.props.duracionTipoCita}
				 />

			</div>	 

    }
}

const mapStateToProps =(state,ownProps)=>({
	citaes:state.CitaReducer[CITA],    

})

const mapDispatchToProps = (dispatch, ownProps) => ({
	
})

export default connect(mapStateToProps,mapDispatchToProps)(HorarioList);

