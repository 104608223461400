import React,{Component} from "react";
import Page from "./page";

function Busqueda(props){


        return <Page label={props.label} setBusqueda={props.setBusqueda}/>

}
export default Busqueda;

