import React,{Component, useEffect} from "react";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';

import "./estilo.css"
import Typography from '@mui/material/Typography';
import { Accordion, AccordionSummary, Button, Chip } from "@mui/material";
import { getFechatoLocaleDateString, getHorarioLocaleString } from "../../../../utils/date";
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import FechaDeBusquedaTextField from "../FechaDeBusquedaTextField";
import { Edit } from "@mui/icons-material";
import SeleccionHorarioCita from "../../HorariosBusqueda/SeleccionHorarioCita";

function Page (props){

  const MINUTE_MS = 400;

  const bottomRef = React.useRef();

  const [expanded, setExpanded] = React.useState(false);

  const scrollToBottom = () => {  
		if (bottomRef.current) {
			console.log(">>scrollToBottom()<<:");
			bottomRef.current.scrollIntoView({behavior: "smooth"});
		}
	}

  const handleClickExpanded = () => {
    setExpanded((prev) => !prev);

    const interval = setTimeout(() => {
      scrollToBottom();
    }, MINUTE_MS);

    return () => clearInterval(interval);
  };


  let citaInfo="";

  if(props.fecha!==""&&props.horaInicio!==""){
    citaInfo=<Box>
                CITA
                <Box display={{ xs: 'block', sm: 'flex' }} >
                  <Typography  style={{ textTransform:"none", marginRight:"6px",color:"#ff7f16"}}>{getFechatoLocaleDateString(props.fecha)} </Typography>
                  <Chip  
                      variant="outlined"                                           
                      size="medium"
                      color="secondary"
                      icon={<QueryBuilderIcon  />} 
                      label={ <Typography  style={{ textTransform:"lowercase",color:"#1dbd9e"}}>{getHorarioLocaleString(props.horaInicio)+"-"+getHorarioLocaleString(props.horaFin)}</Typography>}
                      style={{maxWidth: 260,backgroundColor:"whitesmoke"}} 

                    />
                    
                </Box>
              </Box>
  }

  useEffect(() => {
    if(props.fecha!==""&&props.horaInicio!==""){
      setExpanded(false);          
    }else{
      setExpanded(true);         
    } 
    console.log(">>useEffect(setExpanded)<<:");

  }, []);
  
  let card="";
  if(expanded){

      card=<Card  variant="outlined"  >
              <CardContent >
                <FechaDeBusquedaTextField
                  fechaBusqueda={props.fechaBusqueda}                  
                  setFechaBusqueda={props.setFechaBusqueda}
                  label="Fecha de búsqueda"
                />  

               <SeleccionHorarioCita 
                  duracionTipoCita={props.duracionTipoCita}
  
                  fecha={props.fecha} 
                  fechaBusqueda={props.fechaBusqueda} 
                  setFechaBusqueda={props.setFechaBusqueda} 
                  setFieldValue={props.setFieldValue} 
                  horaInicio={props.horaInicio} 
                  horaFin={props.horaFin}
                  idEsquemaTipoCita={props.idEsquemaTipoCita}  

                  MEDICO_id={props.MEDICO_id}
                  CAT_TIPO_CITA_id={props.CAT_TIPO_CITA_id}
        
               
               />
              </CardContent>
            </Card>   
  }else{
    card=<div>hpal</div>
  }
  
  
  return <Accordion 
            variant="outlined" 
            expanded={expanded}                        
            style={{borderColor:"dodgerblue",marginTop:"1rem"}}
            onChange={handleClickExpanded}
          >
            <AccordionSummary
              expandIcon={<Edit />}
              aria-controls="panel1bh-content"            
              iconbuttonprops={{
                onclick: handleClickExpanded
              }}
            >
              {citaInfo}
            </AccordionSummary>              
            
            {card}
            <div ref={bottomRef}/>
          </Accordion>          
                    
              
}

export default Page;