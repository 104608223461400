import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {MEDICO} from "../../../redux/constants/action-type";
import {connect, useDispatch, useSelector} from "react-redux";
import { agregarMedicoWSAction } from "../../../redux/actions/MedicoActions";


const validationSchema = yup.object({
		nombres: yup.string().trim().required("Campo requerido").matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
                'Solo se permite letras.').max(45, 'debe ser menor a 45 caracteres'),
		apellidoPaterno: yup.string().required("Campo requerido").matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
                'Solo se permite letras.').max(45, 'debe ser menor a 45 caracteres'),
		apellidoMaterno: yup.string().matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
                'Solo se permite letras.').max(45, 'debe ser menor a 45 caracteres'),
		cedulaProfesional: yup.string().max(45, 'debe ser menor a 45 caracteres'),
        correo: yup.string().email('el correo no es valido').max(45, 'debe ser menor a 45 caracteres'),
		telefono: yup.string().max(45, 'debe ser menor a 45 caracteres'),
		celular: yup.string().max(45, 'debe ser menor a 45 caracteres'),
		especialidad: yup.string().max(45, 'debe ser menor a 45 caracteres')
})

function MedicoForm (props){
    const dispatch=useDispatch();
    const medicosStore=useSelector(state=>state.MedicoReducer[MEDICO]);
//class MedicoForm extends Component{
 /*   constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this); // i think you are missing this
     }
   */ 
    const goBack=()=>{
        //this.props.history.goBack();
        props.cerrarModal(false);
    }

    const existMedico=(values)=>{
		let result=false;

        result=medicosStore.some(item=>
				item?.nombres.toUpperCase()===values?.nombres.toUpperCase()&&
				item?.apellidoMaterno.toUpperCase()===values?.apellidoMaterno.toUpperCase()&&
				item?.apellidoPaterno.toUpperCase()===values?.apellidoPaterno.toUpperCase());

		return result;
	}
    
        return <Formik

                    initialValues={{
                        'titulo': '',
                        'nombres': '',
                        'apellidoPaterno': '',
                        'apellidoMaterno': '',
                        'cedulaProfesional': '',
                        'correo': '',
                        'telefono': '',
                        'celular': '',
                        'especialidad': '',

                    }}

                    validationSchema={validationSchema}
                    onSubmit={(values,{setSubmitting, resetForm,setErrors,stopPropagation}) => {
                        console.log("AGREGAR MEDICO (onSubmit):"+JSON.stringify(values));
                        if(existMedico(values)){
                                setErrors({nombres:" ",
                                           apellidoPaterno:" ",
                                           apellidoMaterno:"!El médico ya existe!"}) 
                        } else{
                            setSubmitting(dispatch(agregarMedicoWSAction(MEDICO,values)));
                            resetForm({values:""});
                            goBack();
                        } 

                      }}
                >
                {propers => <Page {...propers} 
                                                                      
                                 modal={props.modal}                                                     
                                 cerrarModal={props.cerrarModal}                                                    
                    />}
                </Formik> 
}

export default MedicoForm;


