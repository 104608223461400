import React,{Component} from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from '@mui/material/Slide';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {PACIENTE} from "../../../redux/constants/action-type";
import DoneIcon from '@mui/icons-material/Done';
import Divider from '@mui/material/Divider';
import ConfirmacionDialog from "./ConfirmacionDialog";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import PersonIcon from '@mui/icons-material/PersonRounded';
import ListItemText from '@mui/material/ListItemText';
import {getEdad} from "../../../utils/moment"

import InputAdornment from '@mui/material/InputAdornment';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import MuiDialogActions from '@mui/material/DialogActions';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

  function Page(props) {
		
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	function goBack(){
		props.cerrarModalEditar(false);		
	}
		

	let appBar="";
	let actions="";

	if(fullScreen){
		appBar=<AppBar color="default" style={{boxShadow: "none",backgroundColor:"#FFFFFF"}}>
					<Toolbar>
					<IconButton
                        edge="start"
                        color="inherit"
                        onClick={goBack}
                        aria-label="close"
                        size="large">
                            <ArrowBackIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}}  />
                            </IconButton>
                            <Typography variant="h6" style={{ flexGrow: 1}} >
                                Editar Paciente
                            </Typography>
                            <IconButton onClick={props.abrirConfirmacion} size="large">
                                <DeleteOutlineIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}} />
                            </IconButton>
                            <IconButton size="large">
                            </IconButton>

							<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>

					</Toolbar>
					<Divider/>
				</AppBar>

	}else{
		actions=<MuiDialogActions>
						<Button variant="contained" edge="start" onClick= {props.abrirConfirmacion}>Eliminar</Button>
						<Button variant="contained" edge="start" onClick= {props.cerrarModalEditar}>Cancelar</Button>
						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>
				</MuiDialogActions>
	}

	function handleFechaDeNacimiento(e){
		props.handleChange(e);
		let edad=getEdad(new Date(e.target.value));
		if(edad<150){
			props.setFieldValue("edad",edad);	
		}
	}

	function handleChange(e){
		props.setFieldValue("SEXO_id",e.target.value);			  
	}

        let opcion=props.tipoSeleccion;
        const inputProps = {
            step: 300,
            resize:"2rem",
            fontSize:"2rem",
            style: {fontSize: 15} 

          };

        return (
            <form onSubmit={props.handleSubmit} style={{margin: 1, width: '25ch'}}> 
                <Dialog
					open={props.modalEditar}
                    onClose={goBack}
                    aria-labelledby="form-dialog-title"
                    TransitionComponent={Transition}
					fullScreen={fullScreen}
					disableEnforceFocus

                    >

                        <DialogTitle id="form-dialog-title">Actualizar Paciente</DialogTitle>

						{appBar}

							<DialogContent>

								<TextField
									error={props.touched.nombres&&props.errors.nombres? true:false}
									helperText={props.touched.nombres&&props.errors.nombres?props.errors.nombres:""}
									size="medium"
									margin="normal"
									id="nombres"
									label="Nombres:"
									type="text"
									fullWidth
									variant="outlined"
									onChange={props.handleChange} value={props.values.nombres}
									onBlur={(value) =>{ props.handleBlur(value);props.setFieldValue("nombres", props.values.nombres?.trim())}}
									
								/>
								<TextField
									error={props.touched.apellidoPaterno&&props.errors.apellidoPaterno? true:false}
									helperText={props.touched.apellidoPaterno&&props.errors.apellidoPaterno?props.errors.apellidoPaterno:""}
									size="medium"
									margin="normal"
									id="apellidoPaterno"
									label="Apellido Paterno:"
									type="text"
									fullWidth
									variant="outlined"
									onChange={props.handleChange} value={props.values.apellidoPaterno}
									onBlur={(value) =>{ props.handleBlur(value);props.setFieldValue("apellidoPaterno", props.values.apellidoPaterno?.trim())}}
								/>
								<TextField
									error={props.touched.apellidoMaterno&&props.errors.apellidoMaterno? true:false}
									helperText={props.touched.apellidoMaterno&&props.errors.apellidoMaterno?props.errors.apellidoMaterno:""}
									size="medium"
									margin="normal"
									id="apellidoMaterno"
									label="Apellido Materno:"
									type="text"
									fullWidth
									variant="outlined"
									onChange={props.handleChange} value={props.values.apellidoMaterno}
									onBlur={(value) =>{ props.handleBlur(value);props.setFieldValue("apellidoMaterno", props.values.apellidoMaterno?.trim())}}
								/>

									

								<FormControl
								fullWidth
								variant="filled" 								
								>
									<InputLabel id="demo-simple-select-filled-label">Sexo:</InputLabel>

									<Select
										error={props.touched.SEXO_id&&props.errors.SEXO_id? true:false}
										size="medium"
										id="SEXO_id"
										label="Sexo:"
										type="text"
										fullscreen={fullScreen.toString()}
										variant="outlined"
										onChange={handleChange} 
										value={props.values.SEXO_id}
									>					
								
										{props.catSexo.map((sexo)=> (
											<MenuItem key={sexo.id} value={sexo.id} id={sexo.id}>
												<ListItemText primary={sexo.descripcion} />
											</MenuItem>
										))}
									</Select>

								</FormControl>

							<TextField
								error={props.touched.fechaNacimiento&&props.errors.fechaNacimiento? true:false}
								helperText={props.touched.fechaNacimiento&&props.errors.fechaNacimiento?props.errors.fechaNacimiento:""}
								size="medium"
								margin="normal"
								id="fechaNacimiento"
								label="Fecha Nacimiento:"
								type="date"
								fullWidth
								variant="outlined"
								onChange={handleFechaDeNacimiento}
								value={props.values.fechaNacimiento}
								onBlur={(value) =>{ props.handleBlur(value);props.setFieldValue("fechaNacimiento", props.values.fechaNacimiento?.trim())}}
								InputLabelProps={{
									shrink: true,
								  }}
							/>
							<TextField
								error={props.touched.edad&&props.errors.edad? true:false}
								helperText={props.touched.edad&&props.errors.edad?props.errors.edad:""}
								size="medium"
								margin="normal"
								id="edad"
								label="Edad:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.edad}
								onBlur={(value) =>{ props.handleBlur(value);props.setFieldValue("edad", props.values.edad?.trim())}}
							/>	
							<TextField
								error={props.touched.expediente&&props.errors.expediente? true:false}
								helperText={props.touched.expediente&&props.errors.expediente?props.errors.expediente:""}
								size="medium"
								margin="normal"
								id="expediente"
								label="Expediente:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.expediente}
								onBlur={(value) =>{ props.handleBlur(value);props.setFieldValue("expediente", props.values.expediente?.trim())}}
							/>
							
							
							
							<TextField
								error={props.touched.calle&&props.errors.calle? true:false}
								helperText={props.touched.calle&&props.errors.calle?props.errors.calle:""}
								size="medium"
								margin="normal"
								id="calle"
								label="Calle:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.calle}
								onBlur={(value) =>{ 
												props.handleBlur(value);
												props.setFieldValue("calle", props.values.calle?.trim())
								}}
							/>
							<TextField
								error={props.touched.ciudad&&props.errors.ciudad? true:false}
								helperText={props.touched.ciudad&&props.errors.ciudad?props.errors.ciudad:""}
								size="medium"
								margin="normal"
								id="ciudad"
								label="Ciudad:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.ciudad}
								onBlur={(value) =>{ props.handleBlur(value);props.setFieldValue("ciudad", props.values.ciudad?.trim())}}
							/>
							<TextField
								error={props.touched.estado&&props.errors.estado? true:false}
								helperText={props.touched.estado&&props.errors.estado?props.errors.estado:""}
								size="medium"
								margin="normal"
								id="estado"
								label="Estado:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.estado}
								onBlur={(value) =>{ props.handleBlur(value);props.setFieldValue("estado", props.values.estado?.trim())}}
							/>
							<TextField
								error={props.touched.pais&&props.errors.pais? true:false}
								helperText={props.touched.pais&&props.errors.pais?props.errors.pais:""}
								size="medium"
								margin="normal"
								id="pais"
								label="Pais:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.pais}
								onBlur={(value) =>{ props.handleBlur(value);props.setFieldValue("pais", props.values.pais?.trim())}}
							/>
							<TextField
								error={props.touched.telefono&&props.errors.telefono? true:false}
								helperText={props.touched.telefono&&props.errors.telefono?props.errors.telefono:""}
								size="medium"
								margin="normal"
								id="telefono"
								label="Telefono de contacto:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.telefono}
								onBlur={(value) =>{ props.handleBlur(value);props.setFieldValue("telefono", props.values.telefono?.trim())}}
							/>
							<TextField
								error={props.touched.celular&&props.errors.celular? true:false}
								helperText={props.touched.celular&&props.errors.celular?props.errors.celular:""}
								size="medium"
								margin="normal"
								id="celular"
								label="Celular de contacto:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.celular}
								onBlur={(value) =>{ props.handleBlur(value);props.setFieldValue("celular", props.values.celular?.trim())}}
							/>
							<TextField
								error={props.touched.correo&&props.errors.correo? true:false}
								helperText={props.touched.correo&&props.errors.correo?props.errors.correo:""}
								size="medium"
								margin="normal"
								id="correo"
								label="Correo Eléctronico:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.correo}
								onBlur={(value) =>{ props.handleBlur(value);props.setFieldValue("correo", props.values.correo?.trim())}}
							/>
                        </DialogContent>  
						{actions}                  
                        
                    </Dialog>
                    <ConfirmacionDialog modalConfirmacion={props.modalConfirmacion}   
                                            cerrarConfirmacion={props.cerrarConfirmacion}
                                            eliminar={props.eliminar} 
                                            payload={props.payload}/>
                    </form>   
                )
    
}
export default  Page;
