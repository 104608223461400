import {CONSULTAR_PACIENTEES, AGREGAR_PACIENTE, ACTUALIZAR_PACIENTE,ELIMINAR_PACIENTE} from "../constants/Paciente-action-type";
import {estadoInicial} from "../store/initialState";

export default function(state =estadoInicial.datos, action){

    console.log("Dentro de REDUCERS (PACIENTEES 0):"+action.type);
    console.log("Dentro de REDUCERS (PACIENTEES 1):"+JSON.stringify(action.payload));

    
    switch(action.type){
        
        case CONSULTAR_PACIENTEES:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat.apply([],[action.payload]),
                estatus:action.estatus

            }

        case AGREGAR_PACIENTE:
            return{
                ...state,
                [action.dataType]:[action.payload].concat(state[action.dataType])

            }

        case ACTUALIZAR_PACIENTE:
            return{
                ...state,
                [action.dataType]: state[action.dataType].map(p =>
                p.id === action.payload.id ? action.payload : p)        

            }
        case ELIMINAR_PACIENTE:
            return{
                ...state,
                [action.dataType]: state[action.dataType]
                .filter(p => p.id !== action.payload)                    

            }

                default:
            console.log("ENTRA EN DEFAULT SWICTH  EN REDUCER PACIENTEES");
            return state||estadoInicial.datos;
        

    }
}
