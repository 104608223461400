import React,{Component, useState} from "react";
import UsuarioList from "../UsuarioList";
import UsuarioForm from "../UsuarioForm";
import UsuarioButtonBar from "../UsuarioFloatButton";
import UsuarioFormEdit from "../UsuarioFormEdit";
import { useSelector } from "react-redux";
import Busqueda from "../../BusquedaComponent";
import { Collapse } from "@mui/material";


function Page (props){

    const isBusqueda =useSelector(state =>state.estadosReducer.mostrarBusqueda);    
    const [busqueda,setBusqueda]=useState("");

        var resultDatos;
        var animacionCargando;
        

        if(props.usuarios.length>0){
            resultDatos =  <div className="col-12" >                       
                                <Collapse in={isBusqueda}>
                                    <Busqueda label="Buscar Usuario" setBusqueda={setBusqueda}  />
                                </Collapse>                                                                      
                                <UsuarioList  usuarios={props.usuarios} 
                                              setUsuario={props.setUsuario}
                                             busqueda={busqueda}/> 
                            </div>
                                
        }


        return <div style={{marginBottom:"5rem" , marginTop:"5rem" }}>
                                 
                    <UsuarioForm  
                                    modal={props.isModalNuevoUsuario} 
                                    cerrarModal={props.setIsModalNuevoUsuario} 
                    />                
                               
                    <UsuarioFormEdit  
                                    payload={props.usuario} 
                                    setUsuario={props.setUsuario}
                                    />                

                    <div className="row">
                        {resultDatos} 
                        {animacionCargando}
                    </div>               
                    <div className="row">
                        <UsuarioButtonBar abrirModal={props.setIsModalNuevoUsuario} />
                    </div>                
                    

                </div>        
}

export default Page;
