import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {PACIENTE} from "../../../redux/constants/action-type";
import {connect, useDispatch, useSelector} from "react-redux";
import {CAT_SEXO} from "../../../redux/constants/action-type";
import { agregarPacienteWSAction } from "../../../redux/actions/PacienteActions";

/*yup.addMethod(yup.string, "unique", function(message) {
	return this.test("unique", message, function(list) {
		console.log("list [yup.addMethod]:"+list);
		console.log("list [yup.addMethod]:"+JSON.stringify(list));
		

		return true;

		//  const mapper = x => x.name;
	 // const set = [...new Set(list.map(mapper))];
	 // const isUnique = list.length === set.length;
	 // if (isUnique) {
	//	return true;
	 // }
  
	  const idx = list.findIndex((l, i) => mapper(l) !== set[i]);
	  return this.createError({
		path: `[${idx}].name`,
		message: message,
	  });
	});
  });*/
  
const validationSchema = yup.object({
	//expediente: yup.string().required("Campo requerido"),
	nombres: yup.string().trim().required("Campo requerido").matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
		'Solo se permite letras.').max(45, 'debe ser menor a 45 caracteres'),
	apellidoPaterno: yup.string().required("Campo requerido").matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
		'Solo se permite letras.').max(45, 'debe ser menor a 45 caracteres'),
	apellidoMaterno: yup.string().matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
		'Solo se permite letras.').max(45, 'debe ser menor a 45 caracteres'),
		SEXO_id: yup.string().required("Campo requerido"),
	fechaNacimiento: yup.string().required("Campo requerido"),
		edad: yup.number().typeError('Debe introducir un valor numerico').required("Campo requerido"),
		
		calle: yup.string().max(45, 'debe ser menor a 45 caracteres'),
		ciudad: yup.string().max(45, 'debe ser menor a 45 caracteres'),
		estado: yup.string().max(45, 'debe ser menor a 45 caracteres'),
		pais: yup.string().max(45, 'debe ser menor a 45 caracteres'),
		telefono: yup.string().max(45, 'debe ser menor a 45 caracteres'),
		celular: yup.string().max(45, 'debe ser menor a 45 caracteres'),
		correo: yup.string().max(45, 'debe ser menor a 45 caracteres'),
		expediente: yup.string().max(45, 'debe ser menor a 45 caracteres')
})



function PacienteForm (props){ 
	const dispath=useDispatch();
    const catSexo =useSelector(state =>state.CatSexoReducer[CAT_SEXO]);
	const pacientesStore =useSelector(state =>state.PacienteReducer[PACIENTE]);

//class PacienteForm extends Component{
    /*constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this); // i think you are missing this
     }*/
    
    const goBack=()=>{
		props.cerrarModal(false);		
    }

	const existPaciente=(values)=>{
		let result=false;
		result=pacientesStore.some(item=>
				item?.nombres.toUpperCase()===values?.nombres.toUpperCase()&&
				item?.apellidoMaterno.toUpperCase()===values?.apellidoMaterno.toUpperCase()&&
				item?.apellidoPaterno.toUpperCase()===values?.apellidoPaterno.toUpperCase());
		return result;
	}
   // render(){    

        return <Formik

                    initialValues={{
							'expediente': '',
							'nombres': '',
							'apellidoPaterno': '',
							'apellidoMaterno': '',
							'fechaNacimiento': '1980-01-01',
							'edad': '',
							'calle': '',
							'ciudad': '',
							'estado': '',
							'pais': '',
							'telefono': '',
							'celular': '',
							'correo': '',
							'SEXO_id': ''

                    }}

                    validationSchema={validationSchema}

                    onSubmit={(values,{setSubmitting, resetForm,setErrors,stopPropagation}) => {
                          console.log("(setSubmitting)valores AGREGAR PACIENTE "+JSON.stringify(values));                              
						  if(existPaciente(values)){
							setErrors({nombres:" ",
								       apellidoPaterno:" ",
									   apellidoMaterno:"!El paciente ya existe!"}) 
						  }else{
							setSubmitting(dispath(agregarPacienteWSAction(PACIENTE,values)));
							resetForm({values:""});
							goBack();
   
						  }
					}}
                >
					{propers => <Page {...propers}  
									modal={props.modal}
									cerrarModal={props.cerrarModal}
									catSexo={catSexo}
						 		/>}
                </Formik> 
  //  }
}

  /*const mapStateToProps=(state)=>({
	catSexo:state.CatSexoReducer[CAT_SEXO],
	pacientes:state.PacienteReducer[PACIENTE]
  
  })*/
  
  export default PacienteForm;

