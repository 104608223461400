import React,{Component, useContext, useEffect} from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CardActionArea, Container } from "@mui/material";
import runImg from "../../../logo/1_opt.jpg";
import CardMedia from '@mui/material/CardMedia';
import { useAuth } from "../../auth/auth";


const PREFIX = 'Page';

const classes = {
    root: `${PREFIX}-root`,
    card: `${PREFIX}-card`
};

const StyledContainer = styled(Container)((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
        maxWidth: 345,
        flexDirection:"column",
        borderRadius:"19px",
        position:"relative",
        display:"flex"

    },

    [`& .${classes.card}`]: {
        maxWidth: 345,
        padding: theme.spacing.unit,
        textAlign: "center",
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing.unit
    }
}));



function Page(props) {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    let cardAncho="80%";
    if(fullScreen){
        cardAncho="100%"
    }
    let errorsForm=props.errorsForm;
    let loading=props.loading;

    console.log("fullScreen:"+fullScreen);

 
    return (
        <StyledContainer maxWidth="sm" className={classes.container}>
                <Card  style={{width:cardAncho}} className={classes.root} >
                 
                    <CardActionArea>
                
                    <CardMedia
                         style = {{ height: 0, paddingTop: '40%'}}
                            image= {runImg}
                            title="Bienvenido a la agenda medica de Bildsoft"
                        />
                       

                    
                    </CardActionArea>  

                    <CardHeader        
                                title="Accede a tu cuenta"
                                subheader="InnovaAgenda"
                     /> 
                    <form onSubmit={props.handleSubmit}>
                             
       
                        

                        {errorsForm && <Alert severity="error">{errorsForm}</Alert>}

                        <CardContent >
                        
                        {loading && <div className="container-fluid" align="center"><CircularProgress color="secondary" /> </div> }

                            <TextField
                                error={props.touched.usuario&&props.errors.usuario? true:false}
                                helperText={props.touched.usuario&&props.errors.usuario?props.errors.usuario:""}
                                autoFocus
                                margin="normal"
                                id="usuario"
                                label="Usuario:"
                                type="text"
                                fullWidth
                                variant="outlined"
                                onChange={props.handleChange} value={props.values.usuario}
                                onBlur={props.handleBlur}
                            />
                            <TextField
                                error={props.touched.password&&props.errors.password? true:false}
                                helperText={props.touched.password&&props.errors.password?props.errors.password:""}
                                margin="normal"
                                id="password"
                                label="Password:"
                                fullWidth
                                variant="outlined"
                                type="password"
                                onChange={props.handleChange} 
                                value={props.values.password}
                                onBlur={props.handleBlur}
                                autoComplete="off"

                            />
                            
                            </CardContent>
                            <CardActions>
                                <Button size="large" href="/registro" color="primary" variant="outlined">
                                        Registro
                                </Button>
                                <Button size="large" onClick={props.handleSubmit} variant="contained" color="primary" disabled={loading} type="submit">
                                    Ingresar
                                </Button>
                            </CardActions>
                            

                        </form>   
                    </Card>
                  </StyledContainer>
    );
}
export default Page;
