import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmacionDialog(props) {
  
  const handleClose = () => {
    props.cerrarConfirmacion(false);
  };

  console.log("payload-ConfirmacionDialog "+props.payload);
  return (
    <div>
      <Dialog
        open={props.modalConfirmacion}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¿Esta seguro que desea eliminar el registro?"}</DialogTitle>
      
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Cancelar
          </Button>
          <Button onClick={()=>props.eliminar(props.payload)} autoFocus variant="outlined">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
