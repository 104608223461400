import React from "react";
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import runImg from '../../logo/logo.ico'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import SnackbarsMensaje from "../SnackbarsMensajeComponent";
import {getFechaHoyFormato, getFechaHoytoLocaleDateString} from "../../utils/date";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';
import {Link} from "react-router-dom";
import logoBS from '../../logo/logo.ico'
import logoInstitucion from '../../logo/especializada.jpg'
import MenuPrincipalList from "./MenuPrincipalList";
import { getColorSemana } from "../../utils/utils";
import { getFechaHoytoLocaleDateSemanaString } from "../../utils/date";
import Search from "@mui/icons-material/Search";
import SearchOffIcon from '@mui/icons-material/SearchOff';
import AssignmentInd from "@mui/icons-material/AssignmentInd";
import { Badge, Collapse, Slide } from "@mui/material";
import { PROFIL_ADMINISTRADOR } from "../../utils/constantes";
import { useEffect } from "react";
import usuarioPNG from "../../icons/usuario.png";
import BottomNavigationComponent from "../BottomNavigationComponent";


const PREFIX = 'Page';
const drawerWidth = 240;

const classes = {
  badge: `${PREFIX}-badge`,
  root: `${PREFIX}-root`,
  drawer: `${PREFIX}-drawer`,
  appBar: `${PREFIX}-appBar`,
  menuButton: `${PREFIX}-menuButton`,
  toolbar: `${PREFIX}-toolbar`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  content: `${PREFIX}-content`,
  nested: `${PREFIX}-nested`,
  sectionDesktop: `${PREFIX}-sectionDesktop`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
    backgroundColor:"red"
  },

  [`& .${classes.drawer}`]: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,

    },
  },

  [`& .${classes.appBar}`]: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    boxShadow: "none",
    backgroundColor:"#FFFFFF",
    borderWidth:"1",
    marginTop: "0rem",
  },

  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  // necessary for content to be below app bar
  [`&.${classes.toolbar}`]: theme.mixins.toolbar,

  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor:"red",
  },

  [`& .${classes.nested}`]: {
    paddingLeft: theme.spacing(4),
  },

  [`& .${classes.sectionDesktop}`]: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  }
}));


const StyledBadge = Badge;

const Page = (props) => {

  const [isBusqueda, setIsBusqueda] = React.useState(false);

  const toggleIsBusqueda = () => {
    console.log("isBusqueda:"+isBusqueda);
    setIsBusqueda(!isBusqueda);
    console.log("isBusqueda:"+isBusqueda);
  };

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerToggleClose = () => {
    setMobileOpen(false);
  };

  const logoutHandler =(e) => {
    props.history.push('/login');
    props.logout();
    props.history.replace("/login");

  };



  let  encabezado=(<Root className={classes.toolbar} >
                      <Container maxWidth="sm">
                          <Grid container wrap="nowrap" spacing={0} margin={0}>
                          <Grid item mt={1}>
                              <Avatar alt="Bildsoft" src={runImg}  style={{width: "3rem",height: "3rem"}}/> 
                          </Grid>
                          <Grid item >
                          <Typography type="Roboto" variant="subtitle1" style={{ fontWeight:"600" }}>
                              <Box  m={2}>
                                InnovAgenda®
                              </Box>
                          </Typography>
                          </Grid>
                          
                          </Grid>
                      </Container>
                  </Root>);
  let opcion=props.tipoSeleccion;
  let registros=props.registros;
  let autenticado=props.autenticado;
  let notificaciones="";

  if(props.profil===PROFIL_ADMINISTRADOR){ 
    notificaciones= <Box ml={0}>
                      <IconButton color="inherit" m={0} p={0} size="large">
                        <StyledBadge
                          badgeContent={4}
                          color="primary"
                          classes={{
                            badge: classes.badge
                          }}>
                          <AssignmentInd />
                        </StyledBadge>  
                      </IconButton>                                  
                    </Box>

  }





  const usuario=(<div>
                  <Box style={{textAlign:"center",paddingTop:"0rem"}}>
                    <Chip 
                            size="medium"
                            label={localStorage.urs}
                            variant="outlined"
                            icon={<img src={usuarioPNG} style={{ width: "1.1rem",height: "1.1rem", color:"#1e88e5"}}/>}
                            position="rigth"
                            />

                  </Box> 
                  <MenuPrincipalList opcion={opcion} handleDrawerToggleClose={handleDrawerToggleClose} logout={props.logout}/>
                </div>);

  const invitado=(<div>
                    <Divider />
                  </div>);

  const container = window !== undefined ? () => window.document.body : undefined;
  const theme = useTheme();

  useEffect(() => {
    console.log("isBusqueda: (useEffect):"+isBusqueda);
    props.mostrarBusqueda(isBusqueda);

  });

  return (
    <div className={classes.root} >
              <AppBar  position="fixed"  className={classes.appBar} style={{backgroundColor:"#FFFFFF", boxShadow: "none"}}>
                <Toolbar>                                                                                    
                  {autenticado?   
                          <Grid container  alignItems="center" p={0} m={0} style={{display:"flex",alignContent:"center",width:"100%",backgroundColor:"#ffffff"}}>   
                              <Grid item xs={4}>
                                <Box style={{textAlign:"left"}} >
                                  <IconButton
                                    edge="start"
                                    aria-label="menu"
                                    onClick={handleDrawerToggle}
                                    className={classes.menuButton}
                                    size="large">
                                    <MenuIcon color="primary"  style={{  borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}} />
                                  </IconButton>
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box style={{textAlign:"center"}} >
                                    <Chip
                                      color="primary"
                                      avatar={<Avatar  
                                                  variant="circular" 
                                                  color="primary"
                                              >                                                            
                                                {(registros>99)?"99+":registros}
                                              </Avatar>}
                                      label={
                                              <Typography variant="body2"  style={{textTransform: "capitalize"}}>
                                                      {opcion==="instituciones"?"Hospitales":opcion}
                                              </Typography>
                                            }
                                      style={{textAlign:"center",alignItems:"center",alignContent:"center",alignSelf:"center"}}
                                      size="medium" />                                   
                                 
                                </Box>
                              </Grid>
                              <Grid item xs={4} p={0} m={0} style={{color:"#000000"}} >
                                <Box display="flex"  sx={{textAlign:"right",alignItems:"center"}} justifyContent="flex-end" alignContent="flex-end">                                      
                                  <Box>
                                    
                                      {isBusqueda?<Collapse in={isBusqueda}>
                                                    <IconButton onClick={toggleIsBusqueda}>
                                                      <SearchOffIcon  color="secondary"/>
                                                    </IconButton>
                                                  </Collapse>
                                                 :<Collapse in={!isBusqueda}>
                                                    <IconButton onClick={toggleIsBusqueda}>
                                                      <Search   color="primary" />
                                                    </IconButton>
                                                  </Collapse>
                                      }
                                      
                                      
                                                
                                  </Box>                                                                         
                                  <Hidden smDown implementation="css">
                                    <Box p={1} flexGrow={1}>
                                        <Button onClick={props.logout} href="/login">Cerrar Sesion</Button>
                                    </Box>
                                  </Hidden> 

                                </Box>
                            </Grid>
                          </Grid>
                      :<div>
                          <Hidden smDown implementation="css">
                            <Button color="primary" component={Link} to="/login">Iniciar sesión</Button>
                          </Hidden> 
                       </div> 
                 }
                            
                </Toolbar>
                <Divider/>
              </AppBar>
              <nav className={classes.drawer} aria-label="mailbox folders">
                <Hidden smUp implementation="css">
                                            
                    <Drawer
                    container={container}
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                      keepMounted: true, // Better open performance on mobile.
                    }}                    
                    >
                      {encabezado}
                      {autenticado?usuario:invitado}
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {encabezado}
                        {autenticado?usuario:invitado}
                    </Drawer>
                </Hidden>
                <SnackbarsMensaje />
              </nav>  
              <BottomNavigationComponent opcion={opcion}/>
           </div>
  );
};
export default Page;