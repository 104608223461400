import React,{Component} from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from '@mui/material/Slide';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {USUARIO} from "../../../redux/constants/action-type";
import DoneIcon from '@mui/icons-material/Done';
import Divider from '@mui/material/Divider';
import ConfirmacionDialog from "./ConfirmacionDialog";
import { Button, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import MuiDialogActions from '@mui/material/DialogActions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

  function Page(props) {
   
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const goBack=()=>{
         props.cerrarModalEditar();
     }

    let appBar="";
	let actions="";

	if(fullScreen){
		appBar=<AppBar color="default" style={{boxShadow: "none",backgroundColor:"#FFFFFF"}}>
					<Toolbar>
					<IconButton
                        edge="start"
                        color="inherit"
                        onClick={goBack}
                        aria-label="close"
                        size="large">
                            <ArrowBackIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}}  />
                            </IconButton>
                            <Typography variant="h6" style={{ flexGrow: 1}} >
                                Editar Usuario
                            </Typography>
                            <IconButton onClick={props.abrirConfirmacion} size="large">
                                <DeleteOutlineIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}} />
                            </IconButton>
                            <IconButton size="large">
                            </IconButton>

							<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>

					</Toolbar>
					
					<Divider/>
				</AppBar>

	}else{
		actions=<MuiDialogActions>
						<Button variant="contained" edge="start" onClick= {props.abrirConfirmacion}>Eliminar</Button>
						<Button variant="contained" edge="start" onClick= {props.cerrarModalEditar}>Cancelar</Button>
						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>
				</MuiDialogActions>
		
	}

        return (
            <form onSubmit={props.handleSubmit}> 
                <Dialog
                    open={props.modalEditar}
                    onClose={props.cerrarModalEditar}
                    aria-labelledby="form-dialog-title"
                    TransitionComponent={Transition}
                    fullScreen={fullScreen}
                    >
                     
                        <DialogTitle id="form-dialog-title">Editar Usuario</DialogTitle>
						
						{appBar}

							<DialogContent>
							<TextField
								error={props.touched.usuario&&props.errors.usuario? true:false}
								helperText={props.touched.usuario&&props.errors.usuario?props.errors.usuario:""}
								size="medium"
								margin="normal"
								id="usuario"
								label="usuario:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.usuario}
								onBlur={props.handleBlur}
							/>
							<TextField
								error={props.touched.password&&props.errors.password? true:false}
								helperText={props.touched.password&&props.errors.password?props.errors.password:""}
								size="medium"
								margin="normal"
								id="password"
								label="password:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.password}
								onBlur={props.handleBlur}
							/>
							<TextField
								error={props.touched.correo&&props.errors.correo? true:false}
								helperText={props.touched.correo&&props.errors.correo?props.errors.correo:""}
								size="medium"
								margin="normal"
								id="correo"
								label="correo:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.correo}
								onBlur={props.handleBlur}
							/>
							<FormControl variant="standard" fullWidth margin="normal">
							    <InputLabel htmlFor="age-native-simple">INSTITUCION</InputLabel>
								<Select
									error={props.touched.INSTITUCION_id&&props.errors.INSTITUCION_id? true:false}
									size="medium"
									id="INSTITUCION_id"
									label="INSTITUCION_id:"
									type="text"
									fullWidth
									variant="outlined"
									onChange={props.handleChange} value={props.values.INSTITUCION_id}
									onBlur={props.handleBlur}
									native
								>
								          <option aria-label="None" value="" disabled/>
							
									{
									props.instituciones.map(institucion=> (
										<option key={institucion.id}  value={institucion.id}>{institucion.nombre}</option>)
									)}
								</Select>
							</FormControl>
							<FormControl variant="standard" fullWidth margin="normal">
							    <InputLabel htmlFor="age-native-simple">ESTADO</InputLabel>
								<Select
									error={props.touched.ESTADO_id&&props.errors.ESTADO_id? true:false}
									size="medium"
									id="ESTADO_id"
									label="ESTADO_id:"
									type="text"
									fullWidth
									variant="outlined"
									onChange={props.handleChange} 
									value={props.values.ESTADO_id}
									onBlur={props.handleBlur}
									native
								>
								          <option aria-label="None" value="" disabled/>
							
									{
									props.estados.map(estado=> (
										<option key={estado.id}  value={estado.id}>{estado.descripcion}</option>)
									)}
								</Select>
							</FormControl>
                        </DialogContent>       
						{actions}                                                             
                    </Dialog>
                    <ConfirmacionDialog modalConfirmacion={props.modalConfirmacion}   
                                            cerrarConfirmacion={props.cerrarConfirmacion}
                                                eliminar={props.eliminar} 
                                                    payload={props.payload}/>
                    </form>
        );
    
}
export default  Page;
