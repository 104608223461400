import React,{Component} from "react";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import PersonIcon from '@mui/icons-material/PersonRounded';
import {Link} from "react-router-dom";
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Badge from '@mui/material/Badge';
import {MASCULINO,FEMENINO} from "../../../utils/constantes";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {getFormatoFechaNacimiento} from "../../../utils/moment"
import Chip from '@mui/material/Chip';
import { ArrowRightOutlined } from "@mui/icons-material";
import EventIcon from '@mui/icons-material/Event';
import { Box, ListItemButton } from "@mui/material";
import { getAvatarBySex } from "../../../utils/utils";
import cakeFecNacPNG from "../../../icons/cake.png";
import { getFechaNacFormatViewDayjs } from "../../../utils/dayJS";

export default class Page extends Component{

    render(){
        
        function obtenerColor(nombre) {
            if(nombre!=null){
                var size=nombre.length;
                do {
                   if(size>colores.length){
                      size=size-colores.length;
                   } 
                }while (size>colores.length);
  
              return colores[size];
  
            }else{
                return colores[0];
            }
          }

        let colores=["#babdbe","#00bfa5","#8eacbb","#0288D1","#34515e"];
        let nombrePaciente="";

        let edad="";
        if(this.props.paciente!==undefined){
          edad=this.props.paciente.edad+"a";
        }
        let fechaNac="";
        if(this.props.paciente!==undefined){
          fechaNac=<Typography  variant="body1" style={{ textTransform:"capitalize",display: 'flex',justifyContent:"left"}} color="secondary">
                      <EventIcon fontSize="small" padding={0} margin={0}/>  {getFormatoFechaNacimiento(this.props.paciente.fechaNacimiento)}
                  </Typography>
        }
        if(this.props.paciente!=undefined){
          nombrePaciente=<Typography variant="body1" style={{ textTransform:"capitalize"}} color="primary">
                            {this.props.paciente.nombres.toLowerCase()+" "+this.props.paciente.apellidoPaterno.toLowerCase()+" "+this.props.paciente.apellidoMaterno.toLowerCase()}
                        </Typography>

        }
        
        let colorFace="#90a4ae";
        if(this.props.paciente!==undefined){          
          console.log("this.props.paciente.sexo:"+this.props.paciente.sexo);
          if(this.props.paciente.SEXO_id===MASCULINO){
            colorFace="#00aae4";
          }else if(this.props.paciente.SEXO_id===FEMENINO){
            colorFace="#fe4164";
          }  
        }
        
        let avatarPaciente=getAvatarBySex(this.props.paciente?.SEXO_id);

        const handleListItemClick = (value) => {
          this.props.setPaciente(value);
        };
        
        return  <span>
                <ListItem>
                  <ListItemButton 
                    dense 
                    style={{  paddingBottom:6, paddingTop:6 }}                   
                    onClick={()=>handleListItemClick(this.props.paciente)} 

                    >
                      <ListItemAvatar>
                      {avatarPaciente}
                      </ListItemAvatar>
                      <ListItemText 
                        id={this.props.paciente.id} 
                        primary={nombrePaciente}                      
                        secondary={
                          <Typography variant="body2">
                                <Box sx={{display:"flex",flexDirection:"row",columnGap:"3px" }} >
                                  <Box>
                                    <img src={cakeFecNacPNG} style={{ width: "1.3rem",height: "1.3rem",alignContent:"center",alignSelf:"center",alignItems:"center"}}/> 
                                  </Box>
                                  <Box>
                                  {getFechaNacFormatViewDayjs(this.props.paciente.fechaNacimiento)}
                                  </Box>
                                </Box>  
                        </Typography>
                                }

                      />
                      <ListItemSecondaryAction >
                          <Chip
                              color="secondary"
                              variant="outlined"
                              size="small"
                              style={{borderColor:"#1dbd9e",backgroundColor:"whitesmoke",color:"default"}} 
                              label={edad}
                            />                             
                      </ListItemSecondaryAction>
                    
                  </ListItemButton>
                </ListItem>
                <Divider variant="inset" component="li" />

                </span>
    }
}
