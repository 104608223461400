import React,{Component} from "react";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default class Page extends Component{

    render(){
        

        return  <FormControl
                    fullWidth
                    margin="normal"	
                    variant="filled" 

                    >
                        <InputLabel htmlFor="age-native-simple">INSTITUCION</InputLabel>
                        <Select
                            error={this.props.touched.usuario&&this.props.touched.usuario.INSTITUCION_id&&this.props.errors.usuario&&this.props.errors.usuario.INSTITUCION_id? true:false}
                            size="medium"
                            id={this.props.INSTITUCION_id}
                            label="INSTITUCION:"
                            type="text"
                            fullWidth
                            variant="outlined"
                            onChange={this.props.handleChange} 
                            value={this.props.institucionValue}
                            onBlur={this.props.handleBlur}
                            native
                        >
                                <option aria-label="None" value="" disabled/>

                                {

                                
                                    this.props.instituciones.map(institucion=> (
                                        <option key={institucion.id}  value={institucion.id}>{institucion.nombre} </option>)
                                    )
                                    /*
                                        this.props.medico.map(item=> (
                                        <PersonaFormSelect item={item} />
                                        ))
                                        */
                                }
                            </Select>
                    </FormControl>
                
    }
}
