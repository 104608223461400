import React,{Component} from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from '@mui/material/Slide';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
//import Select from '@mui/material/Select';
import Select, { Option, ReactSelectProps } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Divider from '@mui/material/Divider';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ListItemText from '@mui/material/ListItemText';
import InputAdornment from '@mui/material/InputAdornment';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import MuiDialogActions from '@mui/material/DialogActions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  function Page(props) {
		
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	function goBack(){
        props.resetForm({values:""});
		props.cerrarModal(false);
	}

	let appBar="";
	let actions="";

	if(fullScreen){
		appBar=<AppBar color="default" style={{boxShadow: "none",backgroundColor:"#FFFFFF"}}>
					<Toolbar>
						<IconButton
                            edge="start"
                            color="inherit"
                            onClick={goBack}
                            aria-label="close"
                            size="large">
						<ArrowBackIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}}  />
						</IconButton>
						<Typography variant="h6" style={{ flexGrow: 1}} >
							Nuevo Tipo de Cita
						</Typography>

						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>

					</Toolbar>
					<Divider/>
				</AppBar>

	}else{
		actions=<MuiDialogActions>
						<Button variant="contained" edge="start" onClick= {goBack}>Cancelar</Button>
						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>
				</MuiDialogActions>
	}





	function handleChange(e){
		console.log("e.target.selectedOptions:"+e.target.selectedOptions);
		console.log("e.target.selectedOptions:"+e.target.value);
		props.setFieldValue(
			"COLOR_id",e.target.value);	
	  }
	  
        return (
            <form onSubmit={props.handleSubmit}> 
                        <Dialog
                        open={props.modal}
                        onClose={goBack}
                        aria-labelledby="form-dialog-title"
                        TransitionComponent={Transition}
                        fullScreen={fullScreen}


                        >
                       
                            <DialogTitle id="form-dialog-title">Nuevo Tipo de Cita</DialogTitle>
                            
                            {appBar}


                                <DialogContent>
                                <TextField
                                    error={props.touched.nombre&&props.errors.nombre? true:false}
                                    helperText={props.touched.nombre&&props.errors.nombre?props.errors.nombre:""}
                                    size="medium"
                                    margin="normal"
                                    id="nombre"
                                    label="Nombre Tipo de Cita:"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    onChange={props.handleChange} 
                                    value={props.values.nombre}
                                    onBlur={props.handleBlur}
                                />
                                <TextField
                                    error={props.touched.descripcion&&props.errors.descripcion? true:false}
                                    helperText={props.touched.descripcion&&props.errors.descripcion?props.errors.descripcion:""}
                                    size="medium"
                                    margin="normal"
                                    id="descripcion"
                                    label="Descripcion Tipo de Cita:"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    onChange={props.handleChange} 
                                    value={props.values.descripcion}
                                    onBlur={props.handleBlur}
                                />
                                <TextField
                                    error={props.touched.duracion&&props.errors.duracion? true:false}
                                    helperText={props.touched.duracion&&props.errors.duracion?props.errors.duracion:""}
                                    size="medium"
                                    margin="normal"
                                    id="duracion"
                                    label="Duracion en minutos de la Cita:"
                                    type="number"
                                    fullWidth
                                    variant="outlined"
                                    onChange={props.handleChange} 
                                    value={props.values.duracion}
                                    onBlur={props.handleBlur}
                                    autoComplete="off"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">(minutos)</InputAdornment>
                                      }}
                                />
                                <FormControl variant="standard" fullWidth margin="normal">
                                    <InputLabel htmlFor="age-native-simple"> Color asignado a la Cita:</InputLabel>
                                    <Select
                                        error={props.touched.COLOR_id&&props.errors.COLOR_id? true:false}
                                        size="medium"
                                        id="COLOR_id"
                                        label="COLOR_id:"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        onChange={handleChange} 
                                        value={props.values.COLOR_id}
                                        onBlur={props.handleBlur}
                                        
                                    >						
                                        {props.color.map((color)=> (
                                            <MenuItem key={color.id} value={color.id} >
                                             <FiberManualRecordIcon style={{color:color.color,marginRight:"1rem"}}/>
                                             <ListItemText primary={color.color} />
                                            </MenuItem>
                                        ))}
                                    </Select>

                                </FormControl>
                                </DialogContent>
                                {actions}                

                        </Dialog>
                        </form>
        );
    
}
export default  Page;
