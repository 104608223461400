import { createStore, combineReducers, applyMiddleware } from "redux";
import modelReducer from "../reducers/modelReducer";
import stateReducer from "../reducers/stateReducer";
import statePeticionReducer from "../reducers/statePeticionWSReducer";
import stateMensajeReducer from "../reducers/stateMensajeReducer";
import tutorialMensajeReducer from "../reducers/stateMensajeTutorialReducer";

import loginReducer from "../reducers/loginReducer";
import InstitucionReducer from "../reducers/InstitucionReducer";
import UsuarioReducer from "../reducers/UsuarioReducer";
import EstadoReducer from "../reducers/EstadoReducer";
import MedicoReducer from "../reducers/MedicoReducer";
import MedicoUsuarioReducer from "../reducers/MedicoUsuarioReducer";
import PacienteUsuarioReducer from "../reducers/PacienteUsuarioReducer";
import PacienteReducer from "../reducers/PacienteReducer";
import CitaReducer from "../reducers/CitaReducer";
import CatEstadoCitaReducer from "../reducers/CatEstadoCitaReducer";
import CatTipoCitaReducer from "../reducers/CatTipoCitaReducer";
import CatRolReducer from "../reducers/CatRolReducer";
import CatSexoReducer from "../reducers/CatSexoReducer";
import DiaSemanaReducer from "../reducers/DiaSemanaReducer";
import RecordatorioReducer from "../reducers/RecordatorioReducer";
import EsquemaTrabajoReducer from "../reducers/EsquemaTrabajoReducer";
import EsquemaTipoCitaReducer from "../reducers/EsquemaTipoCitaReducer";
import EsquemaTipoCitaHorarioReducer from "../reducers/EsquemaTipoCitaHorarioReducer";
import HorarioReducer from "../reducers/HorarioReducer";
import PeriodoReducer from "../reducers/PeriodoReducer";
import ColorReducer from "../reducers/ColorReducer";
import CatUnidadMedidaReducer from "../reducers/CatUnidadMedidaReducer";
import PresionArterialReducer from "../reducers/PresionArterialReducer";
import GlucosaReducer from "../reducers/GlucosaReducer";
import ComidaReducer from "../reducers/ComidaReducer";
import PaginaWebReducer from "../reducers/PaginaWebReducer";
import ProductoReducer from "../reducers/ProductoReducer";
import ProcedimientoReducer from "../reducers/ProcedimientoReducer";
import MovimientoReducer from "../reducers/MovimientoReducer";
import TecnicoReducer from "../reducers/TecnicoReducer";


import autenticacionReducer from "../reducers/autenticacionReducer";
import logMiddleware from "../middleware/logMiddleware";
import { createLoginMiddleware } from "../webservice/LoginMiddleware";
import { createUsuarioMiddleware } from "../webservice/UsuarioMiddleware";
import { createMedicoUsuarioMiddleware } from "../webservice/MedicoUsuarioMiddleware";
import { createInstitucionMiddleware } from "../webservice/InstitucionMiddleware";
import createMedicoRestMiddleware from "../webservice/MedicoMiddleware ";
import createPacienteRestMiddleware from "../webservice/PacienteMiddleware";
import createCitaRestMiddleware from "../webservice/CitaMiddleware";
import createCatTipoCitaRestMiddleware from "../webservice/CatTipoCitaMiddleware";
import createCatDiaSemanaMiddleware from "../webservice/CatDiaSemanaMiddleware";
import createCatEstadoCitaRestMiddleware from "../webservice/CatEstadoCitaMiddleware";
import createCatRolRestMiddleware from "../webservice/CatRolMiddleware";
import createCatSexoRestMiddleware from "../webservice/CatSexoMiddleware";
import createRecordatorioMiddleware from "../webservice/RecordatorioMiddleware";
import createEsquemaTrabajoMiddleware from "../webservice/EsquemaTrabajoMiddleware";
import createEsquemaTipoCitaMiddleware from "../webservice/EsquemaTipoCitaMiddleware";
import createEsquemaTipoCitaHorarioMiddleware from "../webservice/EsquemaTipoCitaHorarioMiddleware";
import createHorarioMiddleware from "../webservice/HorarioMiddleware";
import createPeriodoMiddleware from "../webservice/PeriodoMiddleware";
import createColorMiddleware from "../webservice/ColorMiddleware";
import createCatUnidadMedicaMiddleware from "../webservice/CatUnidadMedicaMiddleware";
import createPresionArterialMiddleware from "../webservice/PresionArterialMiddleware";
import createGlucosaMiddleware from "../webservice/GlucosaMiddleware ";
import createComidaMiddleware from "../webservice/ComidaMiddleware";
import createPaginaWebMiddleware from "../webservice/PaginaWebMiddleware";
import createProductoMiddleware from "../webservice/ProductoMiddleware";
import createProcedimientoMiddleware from "../webservice/ProcedimientoMiddleware";
import createMovimientoMiddleware from "../webservice/MovimientoMiddleware";
import createTecnicoMiddleware from "../webservice/TecnicoMiddleware";

import {ENTORNO,SUBDOMINIO} from "../../utils/constantes"




const periodoMiddleware=createPeriodoMiddleware(    
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/PeriodoService.php",
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/MedicoService.php"
);

const horarioMiddleware=createHorarioMiddleware(    
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/HorarioService.php",
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/MedicoService.php"
);

const esquemaTipoCitaHorarioMiddleware=createEsquemaTipoCitaHorarioMiddleware(    
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/EsquemaTipocitaHorarioService.php",
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/PeriodoService.php",
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/HorarioService.php",
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/MedicoService.php"
);

const esquemaTipoCitaMiddleware=createEsquemaTipoCitaMiddleware(    
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/EsquemaTipoCitaService.php",
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/MedicoService.php"
);

const colorMiddleware=createColorMiddleware(    
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/ColorService.php",
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/MedicoService.php"
);
const esquemaTrabajoMiddleware=createEsquemaTrabajoMiddleware(    
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/EsquemaTrabajoService.php",
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/EsquemaTipoCitaService.php",
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/PeriodoService.php",
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/HorarioService.php",
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/EsquemaTipocitaHorarioService.php",
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/MedicoService.php"
);
const recordatorioRestMiddleware= createRecordatorioMiddleware(    
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/RecordatorioService.php",
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/PacienteService.php"
);

const catDiaSemanaRestMiddleware= createCatDiaSemanaMiddleware(    
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/DiaSemanaService.php",
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/PacienteService.php"
);
const catTipoCitaRestMiddleware= createCatTipoCitaRestMiddleware(    
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/CatTipoCitaService.php",
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/PacienteService.php"
);
const catEstadoCitaRestMiddleware= createCatEstadoCitaRestMiddleware(    
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/CatEstadoCitaService.php",
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/PacienteService.php"
);
const catRolRestMiddleware= createCatRolRestMiddleware(    
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/CatRolService.php"
);
const catSexoRestMiddleware= createCatSexoRestMiddleware(    
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/CatSexoService.php"
);

const citaRestMiddleware= createCitaRestMiddleware(    
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/CitaService.php",
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/MedicoService.php",
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/PacienteService.php"
);

const pacienteRestMiddleware= createPacienteRestMiddleware(    
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/PacienteService.php",
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/InstitucionPacienteService.php"

);

const medicoRestMiddleware= createMedicoRestMiddleware(    
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/MedicoService.php",
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/InstitucionMedicoService.php"
);

const restMiddleware= createInstitucionMiddleware(
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/InstitucionService.php"
);

const usuarioMiddleware= createUsuarioMiddleware(
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/UsuarioService.php",
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/UsuarioRolService.php",
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/MedicoUsuarioService.php",
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/PacienteUsuarioService.php",
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/EstadoService.php"

);

const medicoUsuarioMiddleware= createMedicoUsuarioMiddleware(
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/MedicoUsuarioService.php"

);

const loginMiddleware= createLoginMiddleware(
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/LoginService.php",
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/MedicoUsuarioService.php",
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/PacienteUsuarioService.php",
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/CatTipoCitaService.php"

);

//(21Feb21 Dom.)
const catUnidadMedica=createCatUnidadMedicaMiddleware(    
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/CatUnidadMedidaService.php"
);

const presionArterialMiddleware=createPresionArterialMiddleware(    
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/PresionArterialService.php"
);

const glucosaRestMiddleware=createGlucosaMiddleware(    
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/GlucosaService.php"
);

const comidaRestMiddleware=createComidaMiddleware(    
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/ComidaService.php"
);

const paginaWebRestMiddleware=createPaginaWebMiddleware(    
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/PaginaWebService.php",    
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/PaginaWebImagesService.php",
);

const productoRestMiddleware=createProductoMiddleware(    
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/ProductoService.php"
);

const procedimientoRestMiddleware=createProcedimientoMiddleware(    
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/ProcedimientoService.php"
);

const movimientoRestMiddleware=createMovimientoMiddleware(    
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/MovimientoService.php"
);

const tecnicoRestMiddleware=createTecnicoMiddleware(    
    "https://"+SUBDOMINIO+".bildsoft.com.mx/"+ENTORNO+"/services/TecnicoService.php"
);

/*
combineReducers es utilizado para crear un rootReducer principal que continen subconjuntos de 
reducers con el fin de simplificar el codigo y sea mas entendible.
*/
const rootReducer = combineReducers({
    datosReducer:modelReducer,
    estadosReducer:stateReducer,
    estadosPeticionReducer:statePeticionReducer,
    estadosMensajeReducer:stateMensajeReducer,
    tutorialMensajeReducer:tutorialMensajeReducer,
    loginReducer:loginReducer,
    autenticacionReducer:autenticacionReducer,
    InstitucionReducer:InstitucionReducer,
    UsuarioReducer:UsuarioReducer,
    EstadoReducer:EstadoReducer,
    MedicoReducer:MedicoReducer,
    MedicoUsuarioReducer:MedicoUsuarioReducer,
    PacienteUsuarioReducer:PacienteUsuarioReducer,
    PacienteReducer:PacienteReducer,
    CitaReducer:CitaReducer,
    CatTipoCitaReducer:CatTipoCitaReducer,
    CatRolReducer:CatRolReducer,
    CatSexoReducer:CatSexoReducer,
    DiaSemanaReducer:DiaSemanaReducer,
    CatEstadoCitaReducer:CatEstadoCitaReducer,
    RecordatorioReducer:RecordatorioReducer,
    EsquemaTrabajoReducer:EsquemaTrabajoReducer,
    EsquemaTipoCitaReducer:EsquemaTipoCitaReducer,
    EsquemaTipoCitaHorarioReducer:EsquemaTipoCitaHorarioReducer,
    HorarioReducer:HorarioReducer,
    PeriodoReducer:PeriodoReducer,
    ColorReducer:ColorReducer,
    CatUnidadMedidaReducer:CatUnidadMedidaReducer,
    PresionArterialReducer:PresionArterialReducer,
    GlucosaReducer:GlucosaReducer,
    ComidaReducer:ComidaReducer,
    PaginaWebReducer:PaginaWebReducer,
    ProductoReducer:ProductoReducer,
    ProcedimientoReducer:ProcedimientoReducer,
    MovimientoReducer:MovimientoReducer,
    TecnicoReducer:TecnicoReducer,
     });



/*
     alternativa createRedux pg.148
createSotre conectar el store con los reducers de la aplicacion
para crear un storeredux es requerido pasarle como parametro los reducer , 
el estado inicial(opcional) y store potenciador (opcinal)  
appliyMiddleware es una capa que es llamada por los accion antes de llamar al reducers
*/
export default createStore(rootReducer,
        applyMiddleware(loginMiddleware,usuarioMiddleware, medicoUsuarioMiddleware,restMiddleware,medicoRestMiddleware,pacienteRestMiddleware,citaRestMiddleware,
            catEstadoCitaRestMiddleware,catTipoCitaRestMiddleware,catDiaSemanaRestMiddleware,catRolRestMiddleware,catSexoRestMiddleware,
            recordatorioRestMiddleware,esquemaTrabajoMiddleware,colorMiddleware,logMiddleware,esquemaTipoCitaMiddleware,
            esquemaTipoCitaHorarioMiddleware,horarioMiddleware,periodoMiddleware,
            catUnidadMedica,presionArterialMiddleware,glucosaRestMiddleware,
            comidaRestMiddleware,paginaWebRestMiddleware,productoRestMiddleware,procedimientoRestMiddleware,movimientoRestMiddleware,tecnicoRestMiddleware));
