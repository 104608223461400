import React from 'react';
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const PREFIX = 'page';

const classes = {
  root: `${PREFIX}-root`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="standard" {...props} />;
}

export default function Page(props) {


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    props.mostrarMensaje(false,props.mensaje);
    };

  return (
    <Root className={classes.root}>
     
      <Snackbar open={props.abrirMensaje} autoHideDuration={2000} onClose={handleClose} style={{marginBottom:75}}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
          }} >
            <div>
              <Alert onClose={handleClose} severity={props.tipoMensaje} variant="standard" >
                {props.mensaje}
              </Alert>

            </div>
      </Snackbar>
    </Root>
  );
}
