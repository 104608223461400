import React,{Component, useEffect, useState} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {PACIENTE} from "../../../redux/constants/action-type";
import {connect, useDispatch, useSelector} from "react-redux";
import {CAT_SEXO} from "../../../redux/constants/action-type";
import { actualizarPacienteWSAction, eliminarPacienteWSAction } from "../../../redux/actions/PacienteActions";

const validationSchema = yup.object({
	//expediente: yup.string().required("Campo requerido"),
    nombres: yup.string().trim().required("Campo requerido").matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        'Solo se permite letras.').max(45, 'debe ser menor a 45 caracteres'),
    apellidoPaterno: yup.string().required("Campo requerido").matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        'Solo se permite letras.').max(45, 'debe ser menor a 45 caracteres'),
    apellidoMaterno: yup.string().matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        'Solo se permite letras.').max(45, 'debe ser menor a 45 caracteres'),
	SEXO_id: yup.string().required("Campo requerido"),
	fechaNacimiento: yup.string().required("Campo requerido"),
    edad: yup.number().typeError('Debe introducir un valor numerico').required("Campo requerido"),

    calle: yup.string().max(45, 'debe ser menor a 45 caracteres'),
    ciudad: yup.string().max(45, 'debe ser menor a 45 caracteres').nullable(true),
    estado: yup.string().max(45, 'debe ser menor a 45 caracteres').nullable(true),
    pais: yup.string().max(45, 'debe ser menor a 45 caracteres').nullable(true),
    telefono: yup.string().max(45, 'debe ser menor a 45 caracteres').nullable(true),
    celular: yup.string().max(45, 'debe ser menor a 45 caracteres').nullable(true),
    correo: yup.string().max(45, 'debe ser menor a 45 caracteres').nullable(true),
    expediente: yup.string().max(45, 'debe ser menor a 45 caracteres').nullable(true)
})


//class PacienteFormEdit extends Component{
function PacienteFormEdit (props){
    const [modalConfirmacion,setModalConfirmacion]=useState(false)
    const [modalEditar,setModalEditar]=useState(false);

    const dispath=useDispatch();
    const catSexo =useSelector(state =>state.CatSexoReducer[CAT_SEXO]);


    /*constructor(props) {
        super(props);
        this.state={
                modalConfirmacion:false,
        };
    }*/
    const abrirConfirmacion=()=> {
       /* this.setState({
            modalConfirmacion:true
        });*/
        setModalConfirmacion(true);
    }
    const cerrarConfirmacion=()=> {
        /*this.setState({
            modalConfirmacion:false
        });*/
        setModalConfirmacion(false);
    }

    const eliminar=(dato)=> {
        console.log("dato [eliminar-PacienteFormEdit]:"+dato);
        console.log("dato string [eliminar-PacienteFormEdit]:"+JSON.stringify(dato));

        cerrarConfirmacion();
        props.setPaciente(null);
        dispath(eliminarPacienteWSAction(PACIENTE,dato));
    }

    const cerrarModalEditar=()=> {
        props.setPaciente(null);

    }

   // render(){    
    useEffect(()=>{
        const showDialog = Boolean(props.payload);
        console.log("showDialog:"+showDialog);
  
        setModalEditar(showDialog);
  
    },[props.payload]);

        let valores=props.payload;
        console.log("valoresvalores:"+JSON.stringify(valores));

        if(valores===null){
            return <></>
        }
    
        return <Formik
                    enableReinitialize
                    initialValues={valores}
                    validationSchema={validationSchema}
                    onSubmit={(values,{setSubmitting, resetForm,stopPropagation}) => {
                          console.log("(setSubmitting)valores "+JSON.stringify(values));                              
                          resetForm({values:""});
                          setSubmitting(dispath(actualizarPacienteWSAction(PACIENTE,values)));
                          setModalEditar(false);		

                      }}
                >
                {propers => < Page 
                                    {...propers}  
                                    modalEditar={modalEditar} 
                                    cerrarModalEditar={cerrarModalEditar} 
                                    eliminar={eliminar} 
                                    payload={valores}
                                    modalConfirmacion={modalConfirmacion}
                                    cerrarConfirmacion={cerrarConfirmacion}
                                    abrirConfirmacion={abrirConfirmacion} 
                                    catSexo={catSexo}
                                    />}
                </Formik> 
//    }



}

  
  export default PacienteFormEdit;

