import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {CITA,RECORDATORIO,CAT_ESTADO_CITA,CAT_TIPO_CITA,PACIENTE,MEDICO,ESQUEMA_TRABAJO,ESQUEMA_TIPO_CITA,MEDICO_USUARIO,INSTITUCION} from "../../../redux/constants/action-type";
import {connect} from "react-redux";

import {obtenerCatEstadoCitaListWSAction,agregarCatEstadoCitaWSAction,actualizarCatEstadoCitaWSAction,eliminarCatEstadoCitaWSAction} from "../../../redux/actions/CatEstadoCitaActions";
import {obtenerCatTipoCitaListWSAction,agregarCatTipoCitaWSAction,actualizarCatTipoCitaWSAction,eliminarCatTipoCitaWSAction} from "../../../redux/actions/CatTipoCitaActions";
import {obtenerRecordatorioListWSAction,agregarRecordatorioWSAction,actualizarRecordatorioWSAction,eliminarRecordatorioWSAction} from "../../../redux/actions/RecordatorioActions";
import {obtenerCitaListWSAction,agregarCitaWSAction,actualizarCitaWSAction,eliminarCitaWSAction} from "../../../redux/actions/CitaActions";
import {obtenerEsquemaTrabajoListWSAction} from "../../../redux/actions/EsquemaTrabajoActions";
import {obtenerEsquemaTipoCitaListWSAction} from "../../../redux/actions/EsquemaTipoCitaActions";
import {obtenerInstitucionListWSAction} from "../../../redux/actions/InstitucionActions";
import { ESTATUS_AGENDADA, ESTATUS_ENVIADA, PROFIL_ADMINISTRADOR, PROFIL_MEDICO } from "../../../utils/constantes";


const validationSchema = yup.object({
		MEDICO_id: yup.string().required("Campo requerido"),
		PACIENTE_id: yup.string().required("Campo requerido"),
		CAT_ESTADO_CITA_id: yup.string().required("Campo requerido"),
		CAT_TIPO_CITA_id: yup.string().required("Campo requerido"),
		fecha: yup.string().required("Campo requerido"),
		horaInicio: yup.string().required("Campo requerido"),
		horaFin: yup.string().required("Campo requerido"),
//		RECORDATORIO_id: yup.string().required("Campo requerido")
})

class CitaForm extends Component{
    constructor(props){
        super(props);
     }

 
   
    render(){    
        let idMedico="";
        let idStatus=ESTATUS_AGENDADA;

        if(this.props.MedicoUsuario!==undefined){
            idMedico=this.props.MedicoUsuario.MEDICO_id;
        }
        console.log("(idMedico)citas "+idMedico);  
        
        let idInstitucion="";
        if(this.props.Institucion!==undefined){
            idInstitucion=this.props.Institucion.id;
        }
        console.log("(idInstitucion)citas:"+idInstitucion);

        let medicos=false;
        if(this.props.profil===PROFIL_ADMINISTRADOR){
            medicos=true;
        }

        if(this.props.profil===PROFIL_MEDICO){
            idStatus=ESTATUS_ENVIADA;
        }

        console.log("props.medicos(profil):"+this.props.profil);
        console.log("props.medicos:"+medicos);

        return <Formik

                    initialValues={{
                        'MEDICO_id': idMedico,
                        'PACIENTE_id': '',
                        'CAT_ESTADO_CITA_id': idStatus,
                        'CAT_TIPO_CITA_id': '',
                        'fecha':'',
                        'horaInicio': '',
                        'horaFin': '',
                        'INSTITUCION_id': idInstitucion
                                    }}

                
                    validationSchema={validationSchema}

                    onSubmit={(values,{setSubmitting, resetForm,stopPropagation}) => {
                          setSubmitting(this.props.agregarCitaWSAction(CITA,values));
                          console.log("(setSubmitting)valores "+JSON.stringify(values));                              
                          resetForm({values:""});
                          this.props.cerrarModal(false);
                         }}
                >
                    {propers => <Page {...propers}
                                
                                 pacientes={this.props.pacientes}  
                                 medico={this.props.medico}
                                 catEstadosCita={this.props.catEstadosCita}  
                                 catTipoCita={this.props.catTipoCita} 
                                 recordatorios={this.props.recordatorios}   
                                 modal={this.props.modal} 
                                 cerrarModal={this.props.cerrarModal}
                                 esquemaTrabajos={this.props.esquemaTrabajos}
                                 esquemaTipoCita={this.props.esquemaTipoCita}
                                 medicos={medicos}
                                                        
                    />}
                </Formik> 
    }


    componentDidMount(){            
            this.props.obtenerEsquemaTrabajoListWSAction(ESQUEMA_TRABAJO,false); /* Invoca por primara vez a productos (default) */
            this.props.obtenerEsquemaTipoCitaListWSAction(ESQUEMA_TIPO_CITA,false); /* Invoca por primara vez a productos (default) */	 
        	this.props.obtenerCatTipoCitaListWSAction(CAT_TIPO_CITA,false);
       //     this.props.obtenerRecordatorioListWSAction(RECORDATORIO,false);
           // this.props.obtenerInstitucionListWSAction(INSTITUCION,false); /* Invoca por primara vez a productos (default) */

    }

}

const mapStateToProps=(state)=>({
    medico:state.MedicoReducer[MEDICO],
    pacientes:state.PacienteReducer[PACIENTE],
    catTipoCita:state.CatTipoCitaReducer[CAT_TIPO_CITA],
    recordatorios:state.RecordatorioReducer[RECORDATORIO],
    esquemaTrabajos:state.EsquemaTrabajoReducer[ESQUEMA_TRABAJO], 
    esquemaTipoCita:state.EsquemaTipoCitaReducer[ESQUEMA_TIPO_CITA],   
    MedicoUsuario:state.MedicoUsuarioReducer[MEDICO_USUARIO][0],  
    Institucion:state.InstitucionReducer[INSTITUCION][0],
    profil:state.autenticacionReducer.profil,     



})


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        obtenerEsquemaTrabajoListWSAction: (type,loading) => dispatch(obtenerEsquemaTrabajoListWSAction(type,loading)),
        obtenerEsquemaTipoCitaListWSAction: (type,loading) => dispatch(obtenerEsquemaTipoCitaListWSAction(type,loading)),
        obtenerCatTipoCitaListWSAction: (type,loading) => dispatch(obtenerCatTipoCitaListWSAction(type,loading)),
        obtenerRecordatorioListWSAction: (type,loading) => dispatch(obtenerRecordatorioListWSAction(type,loading)),
        agregarCitaWSAction: (type,payload) => dispatch(agregarCitaWSAction(type,payload)),
    //    obtenerInstitucionListWSAction: (type,loading) => dispatch(obtenerInstitucionListWSAction(type,loading)),

    }
}


export default connect(mapStateToProps,mapDispatchToProps)(CitaForm);