import React,{Component, useState} from "react";
import MedicoList from "../MedicoList";
import MedicoForm from "../MedicoForm";
import CircularProgress from '@mui/material/CircularProgress';
import MedicoButtonBar from "../MedicoFloatButton";
import MedicoFormEdit from "../MedicoFormEdit";
import Fab from '@mui/material/Fab';
import { useSelector } from "react-redux";
import { Collapse } from "@mui/material";
import Busqueda from "../../BusquedaComponent";


function Page (props){

    const isBusqueda =useSelector(state =>state.estadosReducer.mostrarBusqueda);    
    const [busqueda,setBusqueda]=useState("");

        var resultDatos;
        var animacionCargando;

        if(props.medicos.length>0){
            resultDatos =  <div className="col-12" >  
                                <Collapse in={isBusqueda}>
                                    <Busqueda label="Buscar Médico" setBusqueda={setBusqueda}/>                                    
                                </Collapse>
                                <MedicoList  medicos={props.medicos} 
                                             setMedico={props.setMedico}
                                             busqueda={busqueda}/> 
                            </div>
                                
        }


        return <div style={{marginBottom:"5rem" , marginTop:"5rem" }}>
                                <MedicoForm  
                                            modal={props.isModalNuevaMedico} 
                                             cerrarModal={props.setIsModalNuevaMedico} 
                                    />  
                                 <MedicoFormEdit 
                                                payload={props.medico}  
                                                setMedico={props.setMedico}
                                    /> 
                    <div className="row">
                        {resultDatos} 
                        {animacionCargando}
                    </div>               
                    <div className="row">
                        <MedicoButtonBar abrirModal={props.setIsModalNuevaMedico} />
                    </div>                
                    

                </div>
         
}
export default Page;