import {RestDataSource} from "./RestDataSource";
import { MOSTRAR_MENSAJE, PETICION_WS_SOLICITANDO, PETICION_WS_EXITOSA, PETICION_WS_ERROR,
         TIPO_DE_DATO, PERSONA, PACIENTE,CITA, CAT_TIPO_CITA,COLOR} from "../constants/action-type";
import { CONSULTAR_CITAES } from "../constants/Cita-action-type";
import { CONSULTAR_CAT_TIPO_CITAES } from "../constants/CatTipoCita-action-type";
import { CONSULTAR_COLORES } from "../constants/Color-action-type";


/* Funcion para loggin llamada logMiddleware */
const createColorRestMiddleware =(colorURL,pacienteURL)=>{
    /* Obtiene datos de webservice  */
    const dataSources={
        [COLOR]: new RestDataSource(colorURL),
        [PACIENTE]: new RestDataSource(pacienteURL)
    }

return ({getState, dispatch}) => next => action => {
    console.log("Dentro de createColorRestMiddleware:"+action.type);

    /* Antes de enviar al Reducer */
    switch(action.type){

                /** CITA INICIO */                                    
                case CONSULTAR_COLORES:
                    if(getState().ColorReducer.estatus==="idle"){                

                        if(action.loading){
                            next({type:PETICION_WS_SOLICITANDO});
                        }
                        dataSources[action.dataType].GetData((dataColor,mensaje)=>{ 
                            if(dataColor===null){
                                if(action.loading){
                                    next({type:PETICION_WS_ERROR});
                                }
                            }else{
                                next({     //por cada registro devuelto del Webservices se envia la funcion next
                                    type:CONSULTAR_COLORES,
                                    dataType:action.dataType,
                                    payload:dataColor,
                                    estatus:"succeed"
                                }); 
                                if(action.loading){
                                    next({type:PETICION_WS_EXITOSA});
                                    next({type:TIPO_DE_DATO,dataType:action.dataType,registros:dataColor.length});
                                }
                            }
                        }); 
                    
                    }
                break;   


        default:
                next(action);

    }
}

}

export default createColorRestMiddleware;