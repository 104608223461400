import React,{Component, useEffect, useState } from "react";
import Page from "./page";
import {connect, useDispatch, useSelector} from "react-redux";
import {PACIENTE,CAT_SEXO, TIPO_DE_DATO} from "../../../redux/constants/action-type";
import {obtenerPacienteListWSAction,agregarPacienteWSAction,actualizarPacienteWSAction,eliminarPacienteWSAction} from "../../../redux/actions/PacienteActions";
import {obtenerSexoListWSAction} from "../../../redux/actions/CatSexoActions"


//class Pacientees extends Component{
    function Pacientees(){
        const dispath=useDispatch();
        const pacientesStore =useSelector(state =>state.PacienteReducer[PACIENTE]);

        const [paciente, setPaciente]=useState(null);
        const [isModalNuevaPaciente,setIsModalNuevaPaciente]=useState(false);

/*
    constructor(props) {
        super(props);
        this.state={
                modal:false,
                modalEditar:false,
                payload: {
					'expediente': '',
					'nombres': '',
					'apellidoPaterno': '',
					'apellidoMaterno': '',
					'SEXO_id': '',
                    'fechaNacimiento': '1980-01-01',
					'edad': '',
					'calle': '',
					'ciudad': '',
					'estado': '',
					'pais': '',
					'telefono': '',
					'celular': '',
					'correo': ''
                },
                busqueda:""

        };
    }
     
    abrirModal=()=> {
        this.setState({
            modal:true
        });
    }

    cerrarModal=()=> {
        this.setState({
            modal:false
        });
    }

    abrirModalEditar = (value)=> {
        this.setState({
            modalEditar:true,
            payload: value

        });
    };

    cerrarModalEditar=()=> {
        this.setState({
            modalEditar:false
        });
    }

    buscar=(valor)=> {
        this.setState({
            busqueda:valor
        });
    }

*/
  // render(){    
    
useEffect(()=>{
    dispath(obtenerPacienteListWSAction(PACIENTE,false));
    dispath(obtenerSexoListWSAction(CAT_SEXO,false));     


},[])

useEffect(()=>{
    console.log("cambia pacientesStore (useEffect)"+pacientesStore.length);
            dispath({
                    type:TIPO_DE_DATO,
                    dataType:PACIENTE,
                    registros:pacientesStore.length
                    });

        },[pacientesStore]);


    return  <Page 
                pacientees={pacientesStore} 
                paciente={paciente}
                setPaciente={setPaciente}
                isModalNuevaPaciente={isModalNuevaPaciente}
                setIsModalNuevaPaciente={setIsModalNuevaPaciente}
                /*modal={this.state.modal}
                modalEditar={this.state.modalEditar}
                payload={this.state.payload}
                abrirModal={this.abrirModal}
                cerrarModal={this.cerrarModal}
                abrirModalEditar={this.abrirModalEditar}
                cerrarModalEditar={this.cerrarModalEditar}
                tipoSeleccion={this.props.tipoSeleccion}
                agregarPacienteWSAction={this.props.agregarPacienteWSAction}
                actualizarPacienteWSAction={this.props.actualizarPacienteWSAction}
                eliminarPacienteWSAction={this.props.eliminarPacienteWSAction}   
                buscar={this.buscar} busqueda={this.state.busqueda}*/

                />    
    }
    

/*        componentDidMount(){  
            console.log("SE EJECUTA componentDidMount(Paciente-index) ");  
            if(this.props.location!== undefined){
                let valores=this.props.location.valores;            
                console.log("valores componentDidMount(Paciente-index) "+valores);          
                console.log("valores componentDidMount(Paciente-index) "+JSON.stringify(valores));          
    
                if(valores === undefined){
                    this.props.obtenerPacienteListWSAction(PACIENTE,false); 
                    this.props.obtenerSexoListWSAction(CAT_SEXO,false);
                }else{
                    this.setState(this.props.location.valores);
                }
            } else{
                this.props.obtenerPacienteListWSAction(PACIENTE,false); 
                this.props.obtenerSexoListWSAction(CAT_SEXO,false);
            }       
           

        }

}



const mapStateToProps=(state)=>({
    pacientees:state.PacienteReducer[PACIENTE],    
    loading:state.estadosPeticionReducer.loading,
    tipoSeleccion:state.estadosReducer.selectedType,

})


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        obtenerPacienteListWSAction: (type,loading) => dispatch(obtenerPacienteListWSAction(type,loading)),
        agregarPacienteWSAction: (type,payload) => dispatch(agregarPacienteWSAction(type,payload)),
        actualizarPacienteWSAction: (type,payload) => dispatch(actualizarPacienteWSAction(type,payload)),
        eliminarPacienteWSAction: (type,payload) => dispatch(eliminarPacienteWSAction(type,payload)),
        obtenerSexoListWSAction:(type,loading)=> dispatch(obtenerSexoListWSAction(type,loading))
    }
}
*/
export default Pacientees;


