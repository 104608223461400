import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {CAT_TIPO_CITA,COLOR,INSTITUCION} from "../../../redux/constants/action-type";
import {connect, useDispatch, useSelector} from "react-redux";
import { agregarCatTipoCitaWSAction } from "../../../redux/actions/CatTipoCitaActions";


const validationSchema = yup.object({
		nombre: yup.string().required("Campo requerido").matches(/^'?\p{L}+(?:[' ]\p{L}+)*'?$/u,
                'No se permiten caracteres especiales').min(2, 'Debe ser mayor a 2 caracteres')
                .max(45, 'debe ser menor a 45 caracteres'),
		descripcion: yup.string().required("Campo requerido").min(2, 'Debe ser mayor a 2 caracteres')
        .max(45, 'debe ser menor a 45 caracteres'),
		duracion: yup.string().required("Campo requerido"),
		COLOR_id: yup.string().required("Campo requerido")
})


function CatTipoCitaForm (props){  
    const dispatch=useDispatch();
    const color=useSelector(state=>state.ColorReducer[COLOR]);
    const institucion= useSelector(state=>state.InstitucionReducer[INSTITUCION]);
    const catTipoCitaes =useSelector(state =>state.CatTipoCitaReducer[CAT_TIPO_CITA]);

    let idInstitucion=institucion[0].id;
//class CatTipoCitaForm extends Component{
   /* constructor(props){
        super(props);
     }
    */
     //  render(){    
    
        console.log("(idInstitucion):"+JSON.stringify(idInstitucion));                              

        const goBack=()=>{
            //this.props.history.goBack();
            props.cerrarModal(false);
        }

        const existCatTipoCitaes=(values)=>{
            let result=false;
    
            result=catTipoCitaes.some(item=>
                    item?.nombre.toUpperCase()===values?.nombre.toUpperCase());
    
            return result;
        }

        return <Formik

                    initialValues={{
                    'nombre': '',
                    'descripcion': '',
                    'duracion': '30',
                    'COLOR_id': "",
                    'INSTITUCION_id': idInstitucion,
                    
                    }}

                    validationSchema={validationSchema}

                    onSubmit={(values,{setSubmitting, resetForm,setErrors,stopPropagation}) => {
                        console.log("(setSubmitting)valores "+JSON.stringify(values));      
                        if(existCatTipoCitaes(values)){
                            setErrors({nombre:"!El nombre ya existe!"}) 
                        } else{                        
                            setSubmitting(dispatch(agregarCatTipoCitaWSAction(CAT_TIPO_CITA,values)));
                            resetForm({values:""});
                            goBack();
                        }
                      }}
                >
                    {propers => <Page {...propers} 
                                                                         
                                 modal={props.modal}                                                     
                                 cerrarModal={props.cerrarModal}                                                    
                                 color={color} 
                    />}

                </Formik> 
   //}
}
/*
const mapStateToProps=(state)=>({
  color:state.ColorReducer[COLOR],
  Institucion:state.InstitucionReducer[INSTITUCION][0],

})
*/
export default CatTipoCitaForm;