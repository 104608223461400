import {CONSULTAR_SEXO} from "../constants/CatSexo-action-type";
import {estadoInicial} from "../store/initialState";

export default function(state =estadoInicial.datos, action){

    console.log("Dentro de REDUCERS (CAT_SEXO):"+action.type);

    switch(action.type){
        
        case CONSULTAR_SEXO:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat.apply([],[action.payload]),
                estatus:action.estatus

            }

         default:
            console.log("ENTRA EN DEFAULT SWICTH  EN REDUCER CAT_SEXO");
            return state||estadoInicial.datos;
        

    }
}
