import React from "react";
import { styled } from '@mui/material/styles';
import { Route,BrowserRouter, Routes} from "react-router-dom";
import Login from "../LoginComponent/Login";
import requireAutenticacion from "../../utils/requireAutenticacion"
import Navbar from "../Navbar";
import Pacientes from "../PacienteComponent/Paciente"
import { AuthProvider } from "../auth/auth";
import { RequiereAuth } from "../auth/RequiereAuth";
import Dashboard from "../dashboard";
import { ProtectedRoute } from "../auth/ProtectedRoute ";
import Citas from "../CitaComponent/Cita/indexCitas";
import Medicos from "../MedicoComponent/Medico";
import CatTipoCita from "../CatTipoCitaComponent/CatTipoCita"
import Usuarios from "../UsuarioComponent/Usuario"
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import BottomNavigationComponent from "../BottomNavigationComponent";

const PREFIX = 'Page';
const drawerWidth = 240;

const classes = {
  root: `${PREFIX}-root`,
  drawer: `${PREFIX}-drawer`,
  appBar: `${PREFIX}-appBar`,
  menuButton: `${PREFIX}-menuButton`,
  toolbar: `${PREFIX}-toolbar`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  content: `${PREFIX}-content`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
  },

  [`& .${classes.drawer}`]: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },

  [`& .${classes.appBar}`]: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },

  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  // necessary for content to be below app bar
  [`& .${classes.toolbar}`]: theme.mixins.toolbar,

  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    padding: theme.spacing(0,1,0,0),
    marginTop: "0rem",
    backgroundColor:"white",
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    
  }
}));

const Page =()=>{

  const pathname = window.location.pathname; //returns the current url minus the domain name


  return (
    <Root className={(pathname!=="/citas/tv")?classes.root:""} >
      <AuthProvider>
                    
                    {(pathname!=="/citas/tv")?
                                                <div>
                                                  <Navbar/>
                                                  <nav className={classes.drawer} aria-label="mailbox folders" >
                                                  </nav>
                                                </div>
                                             :
                                                <div></div> 
                    }
                   <main className={(pathname!=="/citas/tv")?classes.content:""}>
                    <Routes>
                      <Route element={<ProtectedRoute />}>
                        <Route exact path="/citas" element={<Citas/>} />                      
                        <Route exact path="/pacientes" element={<Pacientes/>} />                        
                        <Route exact path="/medicos" element={<Medicos/>} />                        
                        <Route exact path="/tipoCita" element={<CatTipoCita/>} />                        
                        <Route exact path="/usuarios" element={<Usuarios/>} />                        
                      </Route>                      
                      <Route  exact path="/login" element={<Login/>} />
                      <Route  exact path="/" element={<Login/>} />
                      <Route  path="*" element={<Login/>} />
                    </Routes>


                   </main>
      </AuthProvider>
    </Root>
  );
};

export default Page;






