import {RestDataSource} from "./RestDataSource";
import { MOSTRAR_MENSAJE, PETICION_WS_SOLICITANDO, PETICION_WS_EXITOSA, PETICION_WS_ERROR,
         TIPO_DE_DATO, PACIENTE,CITA, CAT_TIPO_CITA,COLOR,ESQUEMA_TIPO_CITA, PERIODO} from "../constants/action-type";
import { CONSULTAR_CITAES } from "../constants/Cita-action-type";
import { CONSULTAR_CAT_TIPO_CITAES } from "../constants/CatTipoCita-action-type";
import { CONSULTAR_ESQUEMA_TIPO_CITAES } from "../constants/EsquemaTipoCita-action-type";
import { CONSULTAR_PERIODOS ,ACTUALIZAR_PERIODO,ELIMINAR_PERIODO} from "../constants/Periodo-action-type";


/* Funcion para loggin llamada logMiddleware */
const createPeriodoRestMiddleware =(periodoURL,pacienteURL)=>{
    /* Obtiene datos de webservice  */
    const dataSources={
        [PERIODO]: new RestDataSource(periodoURL),
        [PACIENTE]: new RestDataSource(pacienteURL)
    }

return ({getState, dispatch}) => next => action => {
    console.log("Dentro de MedicoRestMiddleware:"+action.type);

    /* Antes de enviar al Reducer */
    switch(action.type){

                /** CITA INICIO */                                    
                case CONSULTAR_PERIODOS:
                    if(getState().PeriodoReducer.estatus==="idle"){                

                    if(action.loading){next({type:PETICION_WS_SOLICITANDO});}
                            dataSources[action.dataType].GetData((dataPeriodo,mensaje)=>{ 
                                if(dataPeriodo===null){
                                    if(action.loading){next({type:PETICION_WS_ERROR});}
                                }else{
                                next({     //por cada registro devuelto del Webservices se envia la funcion next
                                    type:CONSULTAR_PERIODOS,
                                    dataType:action.dataType,
                                    payload:dataPeriodo,
                                    estatus:"succeed"
                                }); 
                                    if(action.loading){
                                        next({type:PETICION_WS_EXITOSA});
                                        next({type:TIPO_DE_DATO,dataType:action.dataType,registros:dataPeriodo.length});
                                    }
                                }
                            });   
                        }
                break;   
                case ACTUALIZAR_PERIODO:
                    if(action.loading){next({type:PETICION_WS_SOLICITANDO});}
                            dataSources[action.dataType].UpdateData((dataPeriodo,mensaje)=>{ 
                                if(dataPeriodo===null){
                                    if(action.loading){next({type:PETICION_WS_ERROR});}
                                }else{
                                next({     //por cada registro devuelto del Webservices se envia la funcion next
                                    type:ACTUALIZAR_PERIODO,
                                    dataType:action.dataType,
                                    payload:dataPeriodo}); 
                                    if(action.loading){
                                        next({type:PETICION_WS_EXITOSA});
                                        next({type:TIPO_DE_DATO,dataType:action.dataType,registros:dataPeriodo.length});
                                    }
                                    next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se actualizo el registro exitosamente!."}); 
                                }
                            },action.payload.periodo);   
                break;   
                case ELIMINAR_PERIODO:
                    if(action.loading){next({type:PETICION_WS_SOLICITANDO});}
                            dataSources[action.dataType].DeleteData((dataPeriodo,mensaje)=>{ 
                                if(dataPeriodo===null){
                                    if(action.loading){next({type:PETICION_WS_ERROR});}
                                }else{
                                next({     //por cada registro devuelto del Webservices se envia la funcion next
                                    type:ELIMINAR_PERIODO,
                                    dataType:action.dataType,
                                    payload:dataPeriodo}); 
                                    if(action.loading){
                                        next({type:PETICION_WS_EXITOSA});
                                        next({type:TIPO_DE_DATO,dataType:action.dataType,registros:dataPeriodo.length});
                                    }
                                    next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se elimino el registro exitosamente!."}); 

                                }
                            },action.payload.periodo);   
                break;   
                

        default:
                next(action);

    }
}

}

export default createPeriodoRestMiddleware;