import React,{Component, useEffect} from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from '@mui/material/Slide';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DoneIcon from '@mui/icons-material/Done';
import Divider from '@mui/material/Divider';
import ConfirmacionDialog from "./ConfirmacionDialog";


import CitasDisponibles from "../HorariosBusqueda/CitasDisponibles"
import AccountCircle from '@mui/icons-material/AccountCircle';
import PersonAdd from '@mui/icons-material/PersonAdd';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import Grid from "@mui/material/Grid"

import MedicoFormSelect from "../MedicoFormSelect";

import InputAdornment from '@mui/material/InputAdornment';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import MuiDialogActions from '@mui/material/DialogActions';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { Paper } from "@mui/material";
import QRGeneracion from "./QRGeneracion";
import {getFechatoLocaleDateString, getHorarioLocaleString} from "../../../utils/date";
import { ESTATUS_AGENDADA, ESTATUS_ENVIADA } from "../../../utils/constantes";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

  function Page(props) {
	
	useEffect(() => {
        console.log(">>useEffect()<<:");
    });

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


	function goBack(){
		props.cerrarModalEditar();
		//props.history.goBack();
	}

	function handleChange(e,value){
		if(value!==null){
			props.setFieldValue("PACIENTE_id",value.id);	
		}
		
	  }
	  
	let appBar="";
	let actions="";
	let QRCita="";

	console.log("VALORES(id cita):"+props.values.id);
	console.log("VALORES(id medico):"+props.medico);
	

	if(props.values.id!==undefined){
		var pacienteQR=props.pacientes.filter(item=>item.id===props.values.PACIENTE_id)[0];
		var medicoQR=props.medico.filter(item=>item.id===props.values.MEDICO_id)[0];
		var tipoCitaQR=props.catTipoCita.filter(item=>item.id===props.values.CAT_TIPO_CITA_id)[0];
		var estadoCita=props.catEstadosCita.filter(item=>item.id===props.values.CAT_ESTADO_CITA_id)[0];


		var nombrePacienteQR="";
		var nombreMedicoQR="";
		var procedimientoQR="";
		var fechaQR="";
		var horaCita="";
		

		if(pacienteQR!==undefined){
			console.log("pacientesXC:"+JSON.stringify(pacienteQR));
			nombrePacienteQR=pacienteQR.nombres+" "+pacienteQR.apellidoPaterno+" "+pacienteQR.apellidoMaterno;
		}

		if(medicoQR!==undefined){
			console.log("medicoQR:"+JSON.stringify(medicoQR));
			nombreMedicoQR=medicoQR.titulo+" "+medicoQR.nombres+" "+medicoQR.apellidoPaterno+" "+medicoQR.apellidoMaterno;
		}
		console.log("estadoCita(citaformedit):"+JSON.stringify(estadoCita));

		if(tipoCitaQR!==undefined && estadoCita.id===ESTATUS_AGENDADA){
			console.log("tipoCitaQR:"+JSON.stringify(tipoCitaQR));
			procedimientoQR=tipoCitaQR.nombre;

			QRCita=<QRGeneracion id={props.values.id} 
					paciente={nombrePacienteQR} 
					medico={nombreMedicoQR} 
					procedimiento={procedimientoQR} 
					fecha={props.values.fecha} 
					horaCita={props.values.horaInicio}/>

		}
	}


	if(fullScreen){
		appBar=<AppBar color="default" style={{boxShadow: "none",backgroundColor:"#FFFFFF"}}>
					<Toolbar>
					    <IconButton edge="start" onClick={goBack} aria-label="close" size="large">
                            <ArrowBackIcon  color="secondary" style={{ width: "2.0rem",height: "2.0rem"}}  />
                        </IconButton>
						<Typography variant="h6" style={{ flexGrow: 1}} >
							Editar Cita
						</Typography>
						<IconButton onClick={props.abrirConfirmacion} size="large">
							<DeleteOutlineIcon  color="secondary" style={{ width: "2.0rem",height: "2.0rem"}} />
						</IconButton>
						<IconButton size="large">
						</IconButton>
						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>

					</Toolbar>
					<Divider/>
				</AppBar>

	}else{
		actions=<MuiDialogActions>
						<Button variant="contained" edge="start" onClick= {props.abrirConfirmacion}>Eliminar</Button>
						<Button variant="contained" edge="start" onClick= {props.cerrarModalEditar}>Cancelar</Button>
						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>
				</MuiDialogActions>
	}

	console.log("MEDICO SELECCIONADO:"+props.values.MEDICO_id);
	let esquemaMedico=props.esquemaTrabajos.filter(item=>item.MEDICO_id===props.values.MEDICO_id)[0];
	let esquemaTipoCitaDisponible=[];
	let catTipoCitaDisponible=[];
	let esquemaTipoCitaSelecionado=[];


	console.log("[esquemaMedico]:"+JSON.stringify(esquemaMedico));


	if(esquemaMedico!==undefined){			
		esquemaTipoCitaDisponible=props.esquemaTipoCita.filter(eTipoCitaItem=>eTipoCitaItem.ESQUEMA_TRABAJO_id===esquemaMedico.id);
	}


	if(esquemaTipoCitaDisponible.length >0){
	catTipoCitaDisponible=props.catTipoCita.filter(item=>(esquemaTipoCitaDisponible.filter(tCitaDisponible=>
								tCitaDisponible.CAT_TIPO_CITA_id===item.id)[0]!==undefined));


	}

	//07ene21 Filtra el tipo de cita[PANENDO/COLON etc] del medico a partir de su seleecion en el formulario (SIEMPRE VIENE UN ELEMENTO).
	esquemaTipoCitaSelecionado=esquemaTipoCitaDisponible.filter(item=>item.CAT_TIPO_CITA_id===props.values.CAT_TIPO_CITA_id)

	console.log("[esquemaTipoCitaSelecionado]:"+JSON.stringify(esquemaTipoCitaSelecionado));
	console.log("esquemaTipoCitaDisponible:"+JSON.stringify(esquemaTipoCitaDisponible));
	console.log("catTipoCitaDisponible:"+JSON.stringify(catTipoCitaDisponible));

	console.log("catTipoCitaDisponible (props.values.CAT_TIPO_CITA_id):"+props.values.CAT_TIPO_CITA_id);

	

	let medicosSelect="";
		if(props.perfilAdministrador){
			medicosSelect=<MedicoFormSelect MEDICO_id="MEDICO_id"  
							medicoValue={props.values.MEDICO_id}
							errors={props.errors}
							touched={props.touched} 
							handleChange={props.handleChange} 
							esquemaMedico={props.esquemaMedico}
							/>
		}




        return (
            <form onSubmit={props.handleSubmit} style={{margin: 1, width: '25ch'}}> 
                <Dialog
                    open={props.modalEditar}
                   // onClose={props.cerrarModalEditar}
                    aria-labelledby="form-dialog-title"
                    TransitionComponent={Transition}
					fullScreen={fullScreen}
                    >


					<DialogTitle id="form-dialog-title">Editar Cita</DialogTitle>
						{appBar}

							<DialogContent>
						
							{QRCita}
						
 							<FormControl
								fullWidth
								margin="normal"	
								variant="filled" 
								style={{width:"100%"}}
							>														
								 <Autocomplete	
								 									
									options={props.pacientes}	
									value={props.paciente}								
									getOptionLabel={(option) => option.nombres +" " +option.apellidoPaterno+" "+option.apellidoMaterno}
									renderInput={(params) => <Box display="flex">
																<TextField 
																	{...params}
																	id="PACIENTE_id" 
																	error={props.touched.PACIENTE_id&&props.errors.PACIENTE_id? true:false}
																	type="text" {...params}  
																	label="PACIENTE" 
																	variant="outlined" 
																	value={props.values.PACIENTE_id}
																	/>

															</Box>	
												}																
									onChange={handleChange} 
									onBlur={props.handleBlur}

									/>																

						    </FormControl>

							{medicosSelect}

							<FormControl
							fullWidth
							margin="normal"
							variant="filled" 
							>
							    <InputLabel htmlFor="age-native-simple"> TIPO DE CITA</InputLabel>
								<Select
									error={props.touched.CAT_TIPO_CITA_id&&props.errors.CAT_TIPO_CITA_id? true:false}
									size="medium"
									id="CAT_TIPO_CITA_id"
									label=" TIPO DE CITA"
									type="text"
									fullWidth
									variant="outlined"
									onChange={props.handleChange} 
									value={props.values.CAT_TIPO_CITA_id}
									onBlur={props.handleBlur}
									native
									
								>
								          <option aria-label="None" value="" disabled/>
							
									{
									catTipoCitaDisponible.map(cattipocita=> (
										<option key={cattipocita.id}  value={cattipocita.id}>{cattipocita.nombre}</option>)
									)}
								</Select>
							</FormControl>

							<FormControl
							fullWidth
							margin="normal"
							variant="filled" 
							>
							    <InputLabel htmlFor="age-native-simple"> ESTATUS</InputLabel>
								<Select
									error={props.touched.CAT_ESTADO_CITA_id&&props.errors.CAT_ESTADO_CITA_id? true:false}
									size="medium"
									id="CAT_ESTADO_CITA_id"
									label="ESTATUS DE CITA"
									type="text"
									fullWidth
									variant="outlined"
									onChange={props.handleChange} 
									value={props.values.CAT_ESTADO_CITA_id}
									onBlur={props.handleBlur}
									native									
								>
								          <option aria-label="None" value="" disabled/>
							
									{
									props.catEstadosCita.map(estadoCita=> (
										<option key={estadoCita.id}  value={estadoCita.id}>{estadoCita.nombre}</option>)
									)}
								</Select>
							</FormControl>
										
							<CitasDisponibles  
								CAT_TIPO_CITA_id={props.values.CAT_TIPO_CITA_id} //07Ene21 Se requiere para obtener duracion del tipo de cita selecionado por el usuario en el formulario
								catTipoCita={props.catTipoCita}  //07Ene21 Se utiliza para seleccion de color del tipo de cita 												
								fecha={props.values.fecha}      //07Ene21 Se utiliza para el action de seleccion en Card
								setFieldValue={props.setFieldValue} 
							
								horaInicio={props.values.horaInicio} 
								horaFin={props.values.horaFin}
							
								esquemaMedico={esquemaMedico}
								esquemaTipoCitaSelecionado={esquemaTipoCitaSelecionado}
							/>

                        </DialogContent>                          
						{actions}                

                    </Dialog>
                    <ConfirmacionDialog 
						modalConfirmacion={props.modalConfirmacion}   
                        cerrarConfirmacion={props.cerrarConfirmacion}
                        eliminar={props.eliminar} 
                        payload={props.payload}
						/>
                    </form>   
                )
    
}
export default  Page;
