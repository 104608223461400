const dayjs = require('dayjs');
dayjs.locale('es', {
    months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
    monthsShort: 'Ene_Feb_Mrz_Abr_May_Jun_Jul_Ago_Sept_Oct_Nov_Dec'.split('_'),
    weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
    weekdaysShort: 'Dom_.Lun._Mart._Mié._Jue._Vie._Sáb.'.split('_'),
    weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
  }
  );
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)


export const combinaFechaTimeDays=(dateValue,timeValue)=>{

    const dateTime =dayjs(dateValue, 'YYYY-MM-DD');    
    const time =dayjs(timeValue, 'HH:mm:ss');

    const newDatetiem=dateTime.hour(time.hour()).minute(time.get("minute")).second(time.get("second")) ;
    console.log("combinaDateTimeDays (dayjs_1):"+ newDatetiem.toDate() );

    return newDatetiem.toDate();
  }

export const combinaDateTimeDays=(dateValue,timeValue)=>{

    const dateTime =dayjs(dateValue);    
    const time =dayjs(timeValue, 'HH:mm:ss');

    const newDatetiem=dateTime.hour(time.hour()).minute(time.get("minute")).second(time.get("second")) ;
    console.log("combinaDateTimeDays (dayjs_1):"+ newDatetiem.toDate() );

    return newDatetiem.toDate();
  }

  export const rangeBetweenDates=(startOfRangeValue,endOfRangeValue,amount )=>{
    let ranges=[];
    let currentDate = dayjs(startOfRangeValue);
    let endOfRange = dayjs(endOfRangeValue).subtract(1, 'minute');    

    while (currentDate.isBefore(endOfRange) || currentDate.isSame(endOfRange)) {
     let currentDateNext = currentDate.add(amount, "minute");
     let horarioItemCita={"desde":currentDate.format("YYYY-MM-DD HH:mm:ss"),"hasta":currentDateNext.format("YYYY-MM-DD HH:mm:ss")};
     ranges.push(horarioItemCita);
     currentDate=currentDateNext;
    }
    console.log("ranges:"+ranges);
    return ranges;
  }

  export const isBetweenDays=(date0,date1,date2,substrac)=>{
   let d1= dayjs(date0).subtract(substrac);
    return dayjs(d1).isBetween(date1, date2,"minute","[)");
  }

  export const isBetweenDayDayjs=(date0,date1)=>{
    let d1=dayjs(date1).startOf("day");
    let d2=dayjs(date1).endOf("day");
     return dayjs(date0).isBetween(d1, d2,"day","[]");
   }

   export const isBetweenHourDayjs=(date0,date1)=>{
    let d1=dayjs(date1).startOf("hour");
    let d2=dayjs(date1).endOf("hour");
     return dayjs(date0).isBetween(d1, d2,"hour","[]");
   }

   export const isBetweenNowDayjs=(horaValue)=>{
    let d1=dayjs(horaValue).startOf("hour");
    let d2=dayjs(horaValue).endOf("hour");
    console.log("isBetweenNowDayjs:"+d1.toDate());
    console.log("isBetweenNowDayjs2:"+d2.toDate());
    console.log("isBetweenNowDayjs3:"+dayjs().toDate());
     return dayjs().isBetween(d1, d2,"hour","[]");

   }

  export const getFechaDateToStringDays=(fecha)=>{
    const myDate = dayjs(fecha).format('YYYY-MM-DD');
    console.log("myDate[dayjs]: "+myDate);
    return myDate;
}

export const getHorarioStringDayjsSec=(timeValue)=>{

    const time =dayjs(timeValue, 'HH:mm');
    console.log("getHorarioStringDayjsSec[timeValue]: "+time.format("HH:mm:ss"));
    return time.format("HH:mm:ss");
}

export const getHorarioStringDayjs=(timeValue)=>{

    const time =dayjs(timeValue, 'HH:mm:ss');
    console.log("getHorarioString[dayjs]: "+time.format("h:mm a"));
    return time.format("h:mm a");
}
export const getHorarioStringViewDayjs=(timeValue)=>{

    const time =dayjs(timeValue, 'YYYY-MM-DD HH:mm:ss');
    console.log("getHorarioStringViewDayjs[dayjs]: "+time.format("h:mm a"));
    return time.format("h:mm a");
}
export const getDiaStringByDateDayjs=(dateValue)=>{
    const date =dayjs(dateValue, 'YYYY-MM-DD');
    console.log("getDiaStringByDateDayjs: "+date.date());
    return date.date();
}


export const getMesAnioByFechaStringViewDayjs=(dateValue)=>{
    const date =dayjs(dateValue, 'YYYY-MM-DD');
    console.log("getMesAnioByFechaStringViewDayjs: "+date.format("MMM, YYYY"));
    return date.format("MMM");
}

export const getMesSemanaByFechaStringViewDayjs=(dateValue)=>{
    const date =dayjs(dateValue, 'YYYY-MM-DD');
    console.log("getMesSemanaByFechaStringViewDayjs: "+date.format("MMM, ddd"));
    return date.format("MMM, ddd");
}

export const getSemanaByFechaStringViewDayjs=(dateValue)=>{
    const date =dayjs(dateValue, 'YYYY-MM-DD');
    console.log("getSemanaByFechaStringViewDayjs: "+date.format("ddd"));
    return date.format("ddd");
}

export const getFechaNacFormatViewDayjs=(dateValue)=>{
    const date =dayjs(dateValue);
    console.log("getFechaNacFormatViewDayjs: "+date.format("YYYY-MM-DD"));
    return date.format("YYYY-MM-DD");
}
//************** CALENDARIO

export const agregarDia=(dia)=>{
    const newDay=dayjs(dia).add(1, 'day');
    return newDay;
}

export const horasArrayDayjs=(diaValue)=>{
    const totalHoras=24;
    let horasArrayResult=[];

    console.log("horasArraydia>:"+diaValue.toDate());

    for (let i = 0; i < totalHoras; i++) {
        let newHour=(diaValue.add(i, 'hour'));
        console.log("newHour>:"+newHour);

        horasArrayResult.push(newHour);
    }
    console.log("horasArrayResult>:"+horasArrayResult);
    return horasArrayResult;
}

export const diasArrayDayjs=(dia)=>{
    const totalDias=42;
    let diasArray=[];

    for (let i = 1; i <= totalDias; i++) {
        diasArray.push(dayjs(dia).add(i, 'day'));
    }
    console.log("diasArray>:"+diasArray);
    return diasArray;
}


export const horasSemanaArrayDayjs=(diaValue)=>{
    const totalHorasSemana=168; //24hrs * 7 dias = 168
    let horasSemanaArray=[];   

    for (let i = 1; i <= totalHorasSemana; i++) {
        horasSemanaArray.push(dayjs(diaValue).add(i,"hour").subtract(1,"hour"));
    }
    return horasSemanaArray;
}

export const diasSemanaArrayDayjs=(diaValue)=>{
    const totalDiasSemana=7; //24hrs * 7 dias = 168

    let diasSemanaArrayArray=[];   

    for (let i = 1; i <= totalDiasSemana; i++) {
        diasSemanaArrayArray.push(dayjs(diaValue).add(i,"day").subtract(1,"day"));
    }
    console.log("diasSemanaArrayArray>:"+diasSemanaArrayArray);
    return diasSemanaArrayArray;
}


export const isSameDayjs=(datevalue)=>{


    return dayjs().isSame(datevalue, 'day');
}

export const isSameMesDayjs=(datevalue)=>{
    return dayjs().isSame(datevalue, 'month');
}


export const isBeforeMesDayjs=(datevalue)=>{
    return dayjs().isBefore(datevalue, 'month');
}
             
export const isAfterMesDayjs=(datevalue)=>{
    return dayjs().isAfter(datevalue, 'month');
}

export const getDiaDayjs=(dateValue)=>{
    return dayjs(dateValue).get("day");
}

export const isSameDiaDayjs=(date0,date1)=>{
    console.log("isSameDiaDayjs daysjs>:"+date0);
    console.log("isSameDiaDayjs daysjs>:"+date1);

    let isSameDay= dayjs(date0).isSame(dayjs(date1)) // default milliseconds
    console.log("isSameDiaDayjs daysjs>:"+isSameDay);

     return isSameDay;
   }

export const anteriorModoVistaDayjs=(dateValue,counValue,modoVistaValue)=>{

    let previoDate= dayjs(dateValue).subtract(counValue,modoVistaValue) // default milliseconds
    console.log("previoDate daysjs>:"+previoDate.toDate());

    return previoDate;
}

export const siguienteModoVistaDayjs=(dateValue,counValue,modoVistaValue)=>{

    let previoDate= dayjs(dateValue).add(counValue,modoVistaValue) // default milliseconds
    console.log("previoDate daysjs>:"+previoDate.toDate());

    return previoDate;
}

export const diaInicialSemana=(dateValue,modoVistaValue)=>{
    let diaInicialSemanaNew=dayjs(dateValue).startOf(modoVistaValue);
    return diaInicialSemanaNew;
}

export const diaInicialDia=(dateValue,modoVistaValue,hourValue)=>{
    let diaInicialDia=dayjs(dateValue).startOf("day").startOf("hour");
    console.log("diaInicialDia day:"+diaInicialDia.toDate());
    return diaInicialDia;
}

export const diaInicialMes=(dateValue,modoVistaValue,hourValue,substracValue)=>{
    let diaInicialMes=dayjs(dateValue).startOf(modoVistaValue).startOf(hourValue).subtract(1,substracValue);
    return diaInicialMes;
}

export const obtenerHorarioNumeroDayjs=(indexValue)=>{
    let obtenerHorarioNumero=dayjs().hour(indexValue).minute(0).format("LT");
    console.log("obtenerHorarioNumero"+obtenerHorarioNumero);
    return obtenerHorarioNumero;
}