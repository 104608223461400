import React,{Component} from "react";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import {Link} from "react-router-dom";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Paper from '@mui/material/Paper';
import { Badge, Box, Chip } from "@mui/material";
import LensIcon from '@mui/icons-material/Lens';
import { ListItemButton } from "@mui/material";

export default class Page extends Component{

    render(){
        
        
        function obtenerColor(nombre) {
            if(nombre!=null){
                var size=nombre.length;
                do {
                   if(size>colores.length){
                      size=size-colores.length;
                   } 
                }while (size>colores.length);
  
              return colores[size];
  
            }else{
                return colores[0];
            }
          }

        let colores=["#babdbe"];
        let codColor=this.props.codColor;
        let p=this.props.catTipoCita;
        let duracionTipoCita=p.duracion+"min";
        
        const handleListItemClick = (value) => {
          this.props.setTipoCita(value);
        };

        return (
            <span>
                    <ListItem>
                      <ListItemButton 
                      dense 
                      style={{  paddingBottom:6, paddingTop:6 }}                   
                      onClick={()=>handleListItemClick(p)} 
                      >
                        <ListItemAvatar>
                           <LensIcon elevation={3}  style={{ marginRight:".8rem", width: "2rem",height: "2rem", color:codColor}}/>
                        </ListItemAvatar>

                        <ListItemText 
                          primary={
                            <Typography  variant="body1" color="primary" style={{ textTransform:"capitalize"}}>{p.nombre}</Typography>
                          } 
                          secondary={
                            <Typography  variant="body1" color="secondary" style={{ textTransform:"capitalize"}}>
                                    <Box sx={{display:"flex",flexDirection:"row",columnGap:"1rem" }} >
                                      <Box>
                                        {p.descripcion} 
                                      </Box>
                                      <Box>
                                        <Chip
                                          color="secondary"
                                          variant="outlined"              
                                          label={duracionTipoCita}
                                          size="small" />    
                                      </Box>
                                    </Box>  
                            </Typography>
                          } 
                        />

                      </ListItemButton>                                                             
                    </ListItem>
                    <Divider variant="inset" component="li" />

                    </span>
        );
    }
}
