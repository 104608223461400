import {connect} from "react-redux";
import React,{Component} from "react";
import Page from "./page";
import {getFechaHoraString} from "../../../../utils/date";
import { CITA } from "../../../../redux/constants/action-type";
import { isHorarioIntersect } from "../../../../utils/moment";
import {mostrarMensaje} from "../../../../redux/actions/stateActions"


class OtroHorario extends Component{
    setFecha=(valor)=>{
		this.props.setFieldValue('fecha',valor);
	 }
     setHoraInicio=(valor)=>{
		this.props.setFieldValue('horaInicio',valor);
	 }
	 setHoraFin=(valor)=>{
		this.props.setFieldValue('horaFin',valor);
	 }


     getHoraiosIntersect=(fechaBusqueda,desde,hasta)=>{
        let result=false;
        let horarioInicialSelected=getFechaHoraString(fechaBusqueda,desde);
        let horarioFinalSelected=getFechaHoraString(fechaBusqueda,hasta);

        this.props.citaes.map(item=>{

            let horarioStringInicial=getFechaHoraString(item.fecha,item.horaInicio);
            let horarioStringFinal=getFechaHoraString(item.fecha,item.horaFin);

            if(isHorarioIntersect(horarioStringInicial,horarioStringFinal,horarioInicialSelected,horarioFinalSelected)){
                result=true;
            }
         });
         return result;
    
    }


    render(){
        return<Page 
				fechaBusqueda={this.props.fechaBusqueda} 
				setFechaBusqueda={this.props.setFechaBusqueda}
                
                setFecha={this.setFecha}
                setHoraInicio={this.setHoraInicio}
                setHoraFin={this.setHoraFin}

                getHoraiosIntersect={this.getHoraiosIntersect}

                mostrarMensaje={this.props.mostrarMensaje}
				/>
    }

}


const mapStateToProps=(state)=>({
    citaes:state.CitaReducer[CITA],    
})

const mapDispatchToProps=(dispatch)=>({
	mostrarMensaje: (valor,mensaje) => dispatch(mostrarMensaje(valor,mensaje))

})

export default connect(mapStateToProps,mapDispatchToProps)(OtroHorario);