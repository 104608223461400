import React,{Component, useEffect, useState } from "react";
import Page from "./page";
import {connect, useDispatch, useSelector} from "react-redux";
import {USUARIO,ESTADO,INSTITUCION, TIPO_DE_DATO, CAT_ROL} from "../../../redux/constants/action-type";
import {obtenerInstitucionListWSAction,agregarInstitucionWSAction,actualizarInstitucionWSAction,eliminarInstitucionWSAction} from "../../../redux/actions/InstitucionActions";
import {obtenerEstadoListWSAction,agregarEstadoWSAction,actualizarEstadoWSAction,eliminarEstadoWSAction} from "../../../redux/actions/EstadoActions";
import {obtenerUsuarioListWSAction,agregarUsuarioWSAction,actualizarUsuarioWSAction,eliminarUsuarioWSAction} from "../../../redux/actions/UsuarioActions";
import { obtenerCatRolListWSAction } from "../../../redux/actions/CatRolActions";


function Usuarios (){

    const dispath=useDispatch();
    const usuariosStore =useSelector(state =>state.UsuarioReducer[USUARIO]);
    
    const [usuario, setUsuario]=useState(null);
    const [isModalNuevoUsuario,setIsModalNuevoUsuario]=useState(false);

    
/*
    constructor(props) {
        super(props);
        this.state={
                modal:false,
                modalEditar:false,
                payload: {
					'usuario': '',
					'password': '',
					'correo': '',
					'ultimoLogin': '',
					'token': '',
					'INSTITUCION_id': '',
					'ESTADO_id': ''
                },
                busqueda:""

        };
    }
     
    abrirModal=()=> {
        this.setState({
            modal:true
        });
    }

     cerrarModal=()=> {
        this.setState({
            modal:false
        });
    }

    abrirModalEditar = (value)=> {
        this.setState({
            modalEditar:true,
            payload: value

        });
    };

    cerrarModalEditar=()=> {
        this.setState({
            modalEditar:false
        });
    }

    buscar=(valor)=> {
        this.setState({
            busqueda:valor
        });
    }
*/

useEffect(()=>{
    dispath(obtenerUsuarioListWSAction(USUARIO,false));
    dispath(obtenerCatRolListWSAction(CAT_ROL,false)); 


},[])

useEffect(()=>{
    dispath({
            type:TIPO_DE_DATO,
            dataType:USUARIO,
            registros:usuariosStore.length
            });

},[usuariosStore]);

        return  <Page 
                    usuarios={usuariosStore} 
                    usuario={usuario}
                    setUsuario={setUsuario}
                    isModalNuevoUsuario={isModalNuevoUsuario}
                    setIsModalNuevoUsuario={setIsModalNuevoUsuario}
                    /*
                    loading={this.props.loading} 
                    modal={this.state.modal}
                    modalEditar={this.state.modalEditar}
                    payload={this.state.payload}
                    abrirModal={this.abrirModal}
                    cerrarModal={this.cerrarModal}
                    abrirModalEditar={this.abrirModalEditar}
                    cerrarModalEditar={this.cerrarModalEditar}
                    tipoSeleccion={this.props.tipoSeleccion}
                    agregarUsuarioWSAction={this.props.agregarUsuarioWSAction}
                    actualizarUsuarioWSAction={this.props.actualizarUsuarioWSAction}
                    eliminarUsuarioWSAction={this.props.eliminarUsuarioWSAction}   
                    buscar={this.buscar} 
                    busqueda={this.state.busqueda}
                    */
                    />    
    

 /* esta es la accion de middleware*/
  /*      componentDidMount(){            
            let valores=this.props.location.valores;            
            if(valores === undefined){
				this.props.obtenerInstitucionListWSAction(INSTITUCION,false);
				this.props.obtenerEstadoListWSAction(ESTADO,false);
                this.props.obtenerUsuarioListWSAction(USUARIO,true); 
            }else{
                this.setState(this.props.location.valores);
            }

        }
*/

}

/*
funcion que se utilza para regresar el estado del sotre y hacerlo dispobible
*//*
const mapStateToProps=(state)=>({
    usuarios:state.UsuarioReducer[USUARIO],    
    loading:state.estadosPeticionReducer.loading,
    tipoSeleccion:state.estadosReducer.selectedType,

})
*/
/*
permite que una funcion o llamada a funcion esten disponibles como un props
regresa una funcion creadas en actions
*/
/*const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        obtenerUsuarioListWSAction: (type,loading) => dispatch(obtenerUsuarioListWSAction(type,loading)),
        obtenerInstitucionListWSAction: (type,loading) => dispatch(obtenerInstitucionListWSAction(type,loading)),
        obtenerEstadoListWSAction: (type,loading) => dispatch(obtenerEstadoListWSAction(type,loading)),
        agregarUsuarioWSAction: (type,payload) => dispatch(agregarUsuarioWSAction(type,payload)),
        actualizarUsuarioWSAction: (type,payload) => dispatch(actualizarUsuarioWSAction(type,payload)),
        eliminarUsuarioWSAction: (type,payload) => dispatch(eliminarUsuarioWSAction(type,payload))
    }
}
*/
export default Usuarios;


