import React,{Component} from "react";
import requireProfil from "../../../utils/requireProfil";
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Link} from "react-router-dom";
import { ListItemButton } from "@mui/material";


class ListItemComponent extends Component{
    render(){      
        console.log("this.props.opcion:"+this.props.opcion);
        console.log("this.props.menu:"+this.props.menu);

        const selected=(this.props.menu==this.props.opcion);

        return <ListItem>
                <ListItemButton 
                    selected={selected} 
                    component={Link} 
                    to={this.props.ruta}  
                    onClick={this.props.handleDrawerToggleClose}
                
                >                         
                    <ListItemIcon>{this.props.icon}</ListItemIcon>
                    <ListItemText  primary={
                                            <Typography  style={{ textTransform:"capitalize"}}>
                                                {this.props.nombreMenu}                                       
                                            </Typography>
                                            }
                    />
                </ListItemButton>  
                </ListItem>
    }
}


export default requireProfil(ListItemComponent);
