import React,{Component, useState} from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField } from "@mui/material";
import FechaDeBusquedaTextField from "../FechaDeBusquedaTextField";
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import { getHorarioStringDayjsSec } from "../../../../utils/dayJS";

const Page=(props)=>{
 
  const[open,setOpen]=useState(false);
  const[desde,setDesde]=useState("07:00:00");
  const[hasta,setHasta]=useState("07:30:00");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeDesde=(event)=>{
    let valor=event.target.value;
    setDesde(getHorarioStringDayjsSec(valor));

  }

  const handleChangeHasta=(event)=>{
    let valor=event.target.value;
    setHasta(getHorarioStringDayjsSec(valor));
  }

  const handleClick=()=>{   

    if(!props.getHoraiosIntersect(props.fechaBusqueda,desde,hasta)){
        props.setFecha(props.fechaBusqueda);
        props.setHoraInicio(desde);
        props.setHoraFin(hasta);    
        setOpen(false);
    }else{
      props.mostrarMensaje(true,"El horario seleccionado no es valido, revise que el horario no se cruce con el horario de otra cita. ");
    }

  }
      return (
        <div>
                
                 <Button 
                  onClick={handleClickOpen} 
                  variant="contained" fullWidth
                  startIcon={<ScheduleOutlinedIcon/>}>
                     AGREGAR HORARIO  
                  </Button >
                <Dialog 
                      open={open}
                      onClose={handleClose}              
                >
                  <DialogTitle>Agregar otro horario de Cita</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Agrega hora de inicio y hora final
                    </DialogContentText>
                    <FechaDeBusquedaTextField 
                                  fechaBusqueda={props.fechaBusqueda} 
                                  setFechaBusqueda={props.setFechaBusqueda}
                                  label="FECHA DE CITA"                    
                    />
                    <Box>
                      <Box mt="1rem" >
                        <TextField
                          id="time"
                          label="Desde"
                          type="time"
                          defaultValue={desde}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 300, // 5 min
                          }}
                          onChange={handleChangeDesde}
                          variant="outlined"
                          fullWidth
                        />
                      </Box>
                      <Box mt="1rem" >
                        <TextField
                          id="time"
                          label="Hasta"
                          type="time"
                          defaultValue={hasta}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 300, // 5 min
                          }}
                          onChange={handleChangeHasta}
                          variant="outlined"
                          fullWidth
                        />
                      </Box>
                    </Box>   
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      Cancelar
                    </Button>
                    <Button onClick={handleClick} color="primary" variant="contained">
                      Agregar
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
      );
                             
}

export default Page;