import React,{Component} from "react";
import { styled } from '@mui/material/styles';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import {Link} from "react-router-dom";


const PREFIX = 'page';

const classes = {
  text: `${PREFIX}-text`,
  paper: `${PREFIX}-paper`,
  list: `${PREFIX}-list`,
  subheader: `${PREFIX}-subheader`,
  appBar: `${PREFIX}-appBar`,
  grow: `${PREFIX}-grow`,
  fabButton: `${PREFIX}-fabButton`
};

const StyledFab = styled(Fab)((
  {
    theme
  }
) => ({
  [`& .${classes.text}`]: {
    padding: theme.spacing(2, 2, 0),
  },

  [`& .${classes.paper}`]: {
    paddingBottom: 50,
  },

  [`& .${classes.list}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.subheader}`]: {
    backgroundColor: theme.palette.background.paper,
  },

  [`& .${classes.appBar}`]: {
    top: 'auto',
    bottom: 0,
  },

  [`& .${classes.grow}`]: {
    flexGrow: 1,
  },

  [`& .${classes.fabButton}`]: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  }
}));

export default class Page extends Component{

  constructor(props) {
    super(props);
  }

  abrirModal=()=> {
    this.props.abrirModal(true);
  }

    render(){     
 
      return (
        <StyledFab
        position="fixed"
        variant="extended"
        size="large"
        color="default"
        aria-label="agregar"
        style={{position: 'fixed', bottom: "4.5rem", right: "1rem",boxShadow:"1", backgroundColor:"#FFFFFF"}}
        onClick={this.abrirModal}

      >
         <AddIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}} />
         <Typography type="Roboto" style={{flexGrow:"1",color: '#0288D1', fontSize:'1.2rem',fontWeight:'normal',textTransform: "capitalize" }}>Agregar</Typography>
      </StyledFab>
      );
     
                   
    }                          
}
