import React,{Component} from "react";
import CitasDisponiblesCard from "../../HorariosBusqueda/CitasDisponiblesCard";

export default class Page extends Component{

   constructor(props) {
    super(props);
   }
  
    render(){      
      /*let horarios=<div>Sin horarios disponibles</div>;
      if (this.props.esquemaTipoCita !== undefined && this.props.esquema!== undefined) {
           this.props.esquemaTipoCita.filter(e => e.ESQUEMA_TRABAJO_id === this.props.esquema.id).map(item=>            
             horarios =   <CitasDisponiblesCard idEsquemaTipoCita={item.id}  codColor={this.props.codColor} 
                                                  fecha={this.props.fecha}
                                                    setFieldValue={this.props.setFieldValue} horaInicio={this.props.horaInicio}
                                                       horaFin={this.props.horaFin}/>					      
          )
      }
    */
      return <div>
               {
               //horarios
               //07Ene21 esquemaTipoCitaSelecionado siempre contiene un elemento que corresponde al tipo de cita seleccionado del formulario por el usuario.
               //  this.props.esquemaTipoCitaSelecionado.map((item,index)=>
                  <CitasDisponiblesCard 
                                     //   key={index}
                                     //   idEsquemaTipoCita={item.id}  
                                        duracionTipoCita={this.props.duracionTipoCita}  
                                        fecha={this.props.fecha}
                                        setFieldValue={this.props.setFieldValue} 
                                        horaInicio={this.props.horaInicio}
                                        horaFin={this.props.horaFin}
                             //           esquemaMedico={this.props.esquemaMedico}
                                        MEDICO_id={this.props.MEDICO_id}
                                        CAT_TIPO_CITA_id={this.props.CAT_TIPO_CITA_id}
                              
                                        
                                      />
             //  )                
               }							
             </div>
    }                          
}
