import React,{Component} from "react";
import Page from "./page";
import { connect } from "react-redux";


class SeleccionHorarioCita extends Component{


    render(){

        return <Page 
                    duracionTipoCita={this.props.duracionTipoCita}

                    fecha={this.props.fecha} 
                    fechaBusqueda={this.props.fechaBusqueda} 
                    setFechaBusqueda={this.props.setFechaBusqueda} 
                    setFieldValue={this.props.setFieldValue} 
                    horaInicio={this.props.horaInicio} 
                    horaFin={this.props.horaFin}
                    idEsquemaTipoCita={this.props.idEsquemaTipoCita}     
                    profil={this.props.profil}     
                    
                    MEDICO_id={this.props.MEDICO_id}
					CAT_TIPO_CITA_id={this.props.CAT_TIPO_CITA_id}

        />
    }
	componentDidMount(){            
    }
}

const mapStateToProps =(state,ownProps)=>({
    profil:state.autenticacionReducer.profil,     
})

const mapDispatchToProps = (dispatch, ownProps) => ({
})

export default connect(mapStateToProps,mapDispatchToProps)(SeleccionHorarioCita);


