import {CONSULTAR_ESQUEMA_TIPO_CITAES, AGREGAR_ESQUEMA_TIPO_CITA, ACTUALIZAR_ESQUEMA_TIPO_CITA,ELIMINAR_ESQUEMA_TIPO_CITA} from "../constants/EsquemaTipoCita-action-type";
import {estadoInicial} from "../store/initialState";

export default function(state =estadoInicial.datos, action){

    console.log("Dentro de REDUCERS (ESQUEMA_TIPO_CITAES):"+action.type);
    console.log("Dentro de REDUCERS (ESQUEMA_TIPO_CITAES->payload):"+JSON.stringify(action.payload));

    switch(action.type){
        
        case CONSULTAR_ESQUEMA_TIPO_CITAES:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat.apply([],[action.payload]),
                estatus:action.estatus

            }

        case AGREGAR_ESQUEMA_TIPO_CITA:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat(action.payload)

            }

        case ACTUALIZAR_ESQUEMA_TIPO_CITA:
            return{
                ...state,
                [action.dataType]: state[action.dataType].map(p =>
                p.id === action.payload.id ? action.payload : p)        

            }
        case ELIMINAR_ESQUEMA_TIPO_CITA:
            return{
                ...state,
                [action.dataType]: state[action.dataType]
                .filter(p => p.id !== action.payload.id)                    

            }

                default:
            console.log("ENTRA EN DEFAULT SWICTH  EN REDUCER ESQUEMA_TIPO_CITAES");
            return state||estadoInicial.datos;
        

    }
}
