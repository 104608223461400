import React,{Component} from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from '@mui/material/Slide';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {MEDICO} from "../../../redux/constants/action-type";
import DoneIcon from '@mui/icons-material/Done';
import Divider from '@mui/material/Divider';
import ConfirmacionDialog from "./ConfirmacionDialog";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import MuiDialogActions from '@mui/material/DialogActions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

  function Page(props) {
		
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	function goBack(){
		//props.history.goBack();
		props.cerrarModalEditar();
	}
	
	function handleChange(e){
	
		props.setFieldValue(
			"titulo",e.target.value);	
	}

	let appBar="";
	let actions="";

	if(fullScreen){
		appBar=<AppBar color="default" style={{boxShadow: "none",backgroundColor:"#FFFFFF"}}>
					<Toolbar>
					<IconButton
                        edge="start"
                        color="inherit"
                        onClick={goBack}
                        aria-label="close"
                        size="large">
                            <ArrowBackIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}}  />
                            </IconButton>
                            <Typography variant="h6" style={{ flexGrow: 1}} >
                                Editar Médico
                            </Typography>
                            <IconButton onClick={props.abrirConfirmacion} size="large">
                                <DeleteOutlineIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}} />
                            </IconButton>
                            <IconButton size="large">
                            </IconButton>

							<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>

					</Toolbar>
					
					<Divider/>
				</AppBar>

	}else{
		actions=<MuiDialogActions>
						<Button variant="contained" edge="start" onClick= {props.abrirConfirmacion}>Eliminar</Button>
						<Button variant="contained" edge="start" onClick= {props.cerrarModalEditar}>Cancelar</Button>
						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>
				</MuiDialogActions>
		
	}

        return (<form onSubmit={props.handleSubmit}> 
                    <Dialog
                    open={props.modalEditar}
                    onClose={props.cerrarModalEditar}
                    aria-labelledby="form-dialog-title"
                    TransitionComponent={Transition}
					fullScreen={fullScreen}


                    >
                   
                        <DialogTitle id="form-dialog-title">Editar Médico</DialogTitle>
                        
						{appBar}

							<DialogContent>
								
							<FormControl
								fullWidth
								margin="normal"
								variant="filled" 

							>
								<InputLabel htmlFor="age-native-simple"> Titulo:</InputLabel>

								<Select
									error={props.touched.titulo&&props.errors.titulo? true:false}
									size="medium"
									id="titulo"
									label="Titulo:"
									fullWidth
									variant="outlined"
									onChange={handleChange} 
									value={props.values.titulo}

									>							
									<MenuItem value={"Dr."}>Dr.</MenuItem>
									<MenuItem value={"Dra."}>Dra.</MenuItem>
								</Select>
							</FormControl>
							<TextField
								error={props.touched.nombres&&props.errors.nombres? true:false}
								helperText={props.touched.nombres&&props.errors.nombres?props.errors.nombres:""}
								size="medium"
								margin="normal"
								id="nombres"
								label="Nombres:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} 
								value={props.values.nombres}
								onBlur={(value) =>{ props.handleBlur(value);props.setFieldValue("nombres", props.values.nombres.trim())}}
							/>
							<TextField
								error={props.touched.apellidoPaterno&&props.errors.apellidoPaterno? true:false}
								helperText={props.touched.apellidoPaterno&&props.errors.apellidoPaterno?props.errors.apellidoPaterno:""}
								size="medium"
								margin="normal"
								id="apellidoPaterno"
								label="Apellido Paterno:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.apellidoPaterno}
								onBlur={(value) =>{ props.handleBlur(value);props.setFieldValue("apellidoPaterno", props.values.apellidoPaterno.trim())}}
							/>
							<TextField
								error={props.touched.apellidoMaterno&&props.errors.apellidoMaterno? true:false}
								helperText={props.touched.apellidoMaterno&&props.errors.apellidoMaterno?props.errors.apellidoMaterno:""}
								size="medium"
								margin="normal"
								id="apellidoMaterno"
								label="Apellido Materno:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.apellidoMaterno}
								onBlur={(value) =>{ props.handleBlur(value);props.setFieldValue("apellidoMaterno", props.values.apellidoMaterno.trim())}}
							/>
							<TextField
								error={props.touched.cedulaProfesional&&props.errors.cedulaProfesional? true:false}
								helperText={props.touched.cedulaProfesional&&props.errors.cedulaProfesional?props.errors.cedulaProfesional:""}
								size="medium"
								margin="normal"
								id="cedulaProfesional"
								label="Cedula Profesional:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.cedulaProfesional}
								onBlur={(value) =>{ props.handleBlur(value);props.setFieldValue("cedulaProfesional", props.values.cedulaProfesional.trim())}}
							/>
							<TextField
								error={props.touched.correo&&props.errors.correo? true:false}
								helperText={props.touched.correo&&props.errors.correo?props.errors.correo:""}
								size="medium"
								margin="normal"
								id="correo"
								label="Correo Eléctronico:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.correo}
								onBlur={(value) =>{ props.handleBlur(value);props.setFieldValue("correo", props.values.correo.trim())}}
							/>
							<TextField
								error={props.touched.telefono&&props.errors.telefono? true:false}
								helperText={props.touched.telefono&&props.errors.telefono?props.errors.telefono:""}
								size="medium"
								margin="normal"
								id="telefono"
								label="Telefono Contacto:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.telefono}
								onBlur={(value) =>{ props.handleBlur(value);props.setFieldValue("telefono", props.values.telefono.trim())}}
							/>
							<TextField
								error={props.touched.celular&&props.errors.celular? true:false}
								helperText={props.touched.celular&&props.errors.celular?props.errors.celular:""}
								size="medium"
								margin="normal"
								id="celular"
								label="Celular Contacto:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.celular}
								onBlur={(value) =>{ props.handleBlur(value);props.setFieldValue("celular", props.values.celular.trim())}}
							/>
							<TextField
								error={props.touched.especialidad&&props.errors.especialidad? true:false}
								helperText={props.touched.especialidad&&props.errors.especialidad?props.errors.especialidad:""}
								size="medium"
								margin="normal"
								id="especialidad"
								label="Especialidad:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.especialidad}
								onBlur={(value) =>{ props.handleBlur(value);props.setFieldValue("especialidad", props.values.especialidad.trim())}}
							/>
                            
                           
                        </DialogContent>     
						{actions}                                          
                    </Dialog>
                    <ConfirmacionDialog modalConfirmacion={props.modalConfirmacion}   
                                        cerrarConfirmacion={props.cerrarConfirmacion}
                                        eliminar={props.eliminar} 
                                        payload={props.payload}
										/>
                    </form>   
            )
    
}
export default  Page;
