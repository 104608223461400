import { TextField } from "@mui/material";
import { styled } from '@mui/material/styles';
import React,{Component} from "react";
import { blueGrey } from "@mui/material/colors";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import "dayjs/locale/es-mx"

const PREFIX = 'Page';

const classes = {
  root: `${PREFIX}-root`,
  bullet: `${PREFIX}-bullet`,
  title: `${PREFIX}-title`,
  pos: `${PREFIX}-pos`,
  notchedOutline: `${PREFIX}-notchedOutline`,
  label: `${PREFIX}-label`
};

const Root = styled('div')({
  [`& .${classes.root}`]: {
    minWidth: 275,
    margin: 12,
    spacing: 8,
    borderWidth:2,
    BorderColor:blueGrey

  },
  [`& .${classes.bullet}`]: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  [`& .${classes.title}`]: {
    fontSize: 12,
  },
  [`& .${classes.pos}`]: {
    marginBottom: 12,
  },
  [`& .${classes.notchedOutline}`]: {
    borderWidth: "1px",
    borderColor: "#1976d2 !important",
    color:"#1976d2 !important"
  },
  [`& .${classes.label}`]: {
    textTransform: 'capitalize',
    borderRadius: 3,
    border: 0,
    color: '#01579b',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .12)',
    },
    
});

const Page=(props)=>{

  const [value, setValue] = React.useState(dayjs(props.fechaBusqueda));

  const handleChange=(value)=>{
		console.log("value(fechaBusqueda):"+dayjs(value).format('YYYY-MM-DD') );
    setValue(value);
    props.setFechaBusqueda(dayjs(value).format('YYYY-MM-DD'));
	}

      return (
        <Root>
                     <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker 
                            label={props.label}  
                            value={value}
                            onChange={(newValue) => handleChange(newValue)}
                            fullWidth
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                         
              </Root>
      );
                             
}

export default Page;