import React,{Component} from "react";
import Page from "./page";
import {Formik,ErrorMessage } from "formik";
import * as yup from 'yup'; // for everything


const validationSchema = yup.object({
    usuario: yup.string().required("Campo requerido"),
    password: yup.string().required("Campo requerido")
})

class LoginForm extends Component{
        
    render(){    
    
        return <Formik
                    initialValues={{
                        'usuario': '',
                        'password': ''
                    }}

                    validationSchema={validationSchema}

                    onSubmit={(values,{setSubmitting, resetForm}) => {
                          console.log("valores "+JSON.stringify(values));
                          setSubmitting(this.props.autenticacionLogin(values));
                          resetForm({values:""});                                     
                      }}


                >
                {props => < Page {...props}  openModalPaciente={this.props.openModalPaciente}  
                                finalizaCreacionPaciente={this.props.finalizaCreacionPaciente}
                                    errorsForm={this.props.errorsForm}  usuario={this.props.usuario} 
                                        loading={this.props.loading}/>}
                </Formik> 
    }
}

export default LoginForm;

