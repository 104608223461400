import React,{Component, useEffect, useState} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {MEDICO} from "../../../redux/constants/action-type";
import {connect, useDispatch} from "react-redux";
import { actualizarMedicoWSAction, eliminarMedicoWSAction } from "../../../redux/actions/MedicoActions";


const validationSchema = yup.object({
	//	especialidad: yup.string().required("Campo requerido"),
    nombres: yup.string().trim().required("Campo requerido").matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        'Solo se permite letras.').max(45, 'debe ser menor a 45 caracteres'),
    apellidoPaterno: yup.string().required("Campo requerido").matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        'Solo se permite letras.').max(45, 'debe ser menor a 45 caracteres'),
    apellidoMaterno: yup.string().matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        'Solo se permite letras.').max(45, 'debe ser menor a 45 caracteres'),
    cedulaProfesional: yup.string().max(45, 'debe ser menor a 45 caracteres'),
    correo: yup.string().email('el correo no es valido').max(45, 'debe ser menor a 45 caracteres'),
    telefono: yup.string().max(45, 'debe ser menor a 45 caracteres'),
    celular: yup.string().max(45, 'debe ser menor a 45 caracteres'),
    especialidad: yup.string().max(45, 'debe ser menor a 45 caracteres')
})

function  MedicoFormEdit (props){
    const [modalConfirmacion,setModalConfirmacion]=useState(false)
    const [modalEditar,setModalEditar]=useState(false);

    const dispath=useDispatch();

/*
    constructor(props) {
        super(props);
        this.state={
                modalConfirmacion:false,
        };
    }*/
    const abrirConfirmacion=()=> {
     /*   this.setState({
            modalConfirmacion:true
        });*/
        setModalConfirmacion(true);
    }
    const cerrarConfirmacion=()=> {
       /* this.setState({
            modalConfirmacion:false
        });*/
        setModalConfirmacion(false);
    }

   /* eliminar=(dato)=> {
        this.cerrarConfirmacion();
        this.props.cerrarModalEditar();
        this.props.eliminarMedicoWSAction(MEDICO,dato); 
    }*/

    const eliminar=(dato)=> {
        cerrarConfirmacion();
        //setModalEditar(false);//this.props.cerrarModalEditar();
        props.setMedico(null);
        dispath(eliminarMedicoWSAction(MEDICO,dato))
    }
    
    const cerrarModalEditar=()=> {
         props.setMedico(null);
 
     }

    useEffect(()=>{
        const showDialog = Boolean(props.payload);
        console.log("showDialog:"+showDialog);
  
        setModalEditar(showDialog);
  
    },[props.payload]);
    
    let valores=props.payload;

    if(valores===null){
        return <></>
    }


        console.log("valoresvalores:"+JSON.stringify(valores));

        return <Formik
                    enableReinitialize
                    initialValues={valores}
                    validationSchema={validationSchema}
                    onSubmit={(values,{setSubmitting, resetForm,stopPropagation}) => {
                        console.log("EDITAR MEDICO (onSubmit)"+JSON.stringify(values));
                          setSubmitting(dispath(actualizarMedicoWSAction(MEDICO,values))); /* Se implementa actualizar de web services y redux */
                          resetForm({values:""});
                          setModalEditar(false);
                      }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        errors,
                        touched,
                        setFieldValue
                    }) => < Page 
                                setFieldValue={setFieldValue} 
                                handleSubmit={handleSubmit} 
                                handleChange={handleChange} 
                                handleBlur={handleBlur} 
                                values={values} 
                                errors={errors} 
                                touched={touched}  
                                modalEditar={modalEditar} 
                                cerrarModalEditar={cerrarModalEditar} 
                                eliminar={eliminar} 
                                payload={valores}
                                modalConfirmacion={modalConfirmacion}
                                cerrarConfirmacion={cerrarConfirmacion}
                                abrirConfirmacion={abrirConfirmacion} />}
                </Formik> 
    

}


export default MedicoFormEdit;

