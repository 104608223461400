import React,{Component, useEffect, useState} from "react";
import Page from "./page";
import { connect } from "react-redux";

function DiaSemanaListItem (props){


        return <Page 
                     action={props.action}  
                                          
                     setFecha={props.setFecha} 
                     setHoraInicio={props.setHoraInicio} 
                     setHoraFin={props.setHoraFin} 
                     
                     horaInicio={props.horaInicio} 
                     horaFin={props.horaFin}
                     
                     dayofCard={props.dayofCard}

                     horaInicial={props.horaInicial} 
                     horaFinal={props.horaFinal} 
                     fecha={props.fecha}
                     idDia={props.idDia}
                     diaBusquedaSelecionado={props.diaBusquedaSelecionado}
                     duracionTipoCita={props.duracionTipoCita}
                     setFieldValue={props.setFieldValue} 
                     periodoList={props.periodoList}

                     />
    
}

const mapStateToProps =(state,ownProps)=>({
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	
})

export default connect(mapStateToProps,mapDispatchToProps)(DiaSemanaListItem);

