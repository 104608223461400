import React,{Component} from "react";
import Page from "./page";
import { connect } from "react-redux";
import { COLOR } from "../../../../redux/constants/action-type";

class CitasDisponibles extends Component{

    render(){
		
		console.log("fechaDefault:"+this.props.fecha);
		console.log("fechaDefault:"+this.props.fechaDefault);
		console.log("idTipoCita:"+this.props.idTipoCita);
		console.log("esquemaTipoCita(CitasDisponibles):"+JSON.stringify(this.props.esquemaTipoCita));
		console.log("color:"+JSON.stringify(this.props.color));
		console.log("catTipoCita(CitasDisponibles):"+JSON.stringify(this.props.catTipoCita));

		
		let catTipoCita_=this.props.catTipoCita.filter(c=>c.id===this.props.idTipoCita)[0];
		console.log("colores:"+JSON.stringify(catTipoCita_));
		
		let duracionTipoCita="00:00:00";
		if(this.props.CAT_TIPO_CITA_id!==""){
			duracionTipoCita=this.props.catTipoCita.filter(item=>item.id===this.props.CAT_TIPO_CITA_id)[0]?.duracion;
		 }
		console.log("duracionTipoCita(this.props.CAT_TIPO_CITA_id):"+this.props.CAT_TIPO_CITA_id);
		

		console.log("this.props.esquemaTipoCitaSelecionado:"+JSON.stringify(this.props.esquemaTipoCitaSelecionado));


		return <Page  
				   // esquemaMedico={this.props.esquemaMedico} 

					 esquemaTipoCita={this.props.esquemaTipoCita} 
					 duracionTipoCita={duracionTipoCita} 

					 fecha={this.props.fecha} 
					 setFieldValue={this.props.setFieldValue} 
					 horaInicio={this.props.horaInicio} 
					 horaFin={this.props.horaFin}
					// esquemaTipoCitaSelecionado={this.props.esquemaTipoCitaSelecionado}
					MEDICO_id={this.props.MEDICO_id}
					CAT_TIPO_CITA_id={this.props.CAT_TIPO_CITA_id}

						
		/>
    }
	componentDidMount(){            
//			this.props.obtenerEsquemaTipoCitaListWSAction(ESQUEMA_TIPO_CITA,false); /* Invoca por primara vez a productos (default) */	
       // (18Jul21) Se actualiza llamada de action para wsea enviado como parametro el id de esquema correspondiente al esuqema de trabajo seleccionado

/*	   if(this.props.esquemaMedico!==undefined){
		this.props.obtenerEsquemaTipoCitaByEsquemaListWSAction(ESQUEMA_TIPO_CITA,this.props.esquemaMedico.id);
	   }
	
*/
	}
}


const mapStateToProps =(state,ownProps)=>({
//	esquemaTipoCita:state.EsquemaTipoCitaReducer[ESQUEMA_TIPO_CITA].filter(e => e.CAT_TIPO_CITA_id === ownProps.idTipoCita),
	color:state.ColorReducer[COLOR],
	       // (18Jul21) Se actualiza llamada de action para wsea enviado como parametro el id de esquema correspondiente al esuqema de trabajo seleccionado

//	esquemaTipoCita:state.EsquemaTipoCitaReducer[ESQUEMA_TIPO_CITA],   

})

const mapDispatchToProps = (dispatch, ownProps) => ({
	       // (18Jul21) Se actualiza llamada de action para wsea enviado como parametro el id de esquema correspondiente al esuqema de trabajo seleccionado

//	obtenerEsquemaTipoCitaByEsquemaListWSAction: (type,payload) => dispatch(obtenerEsquemaTipoCitaByEsquemaListWSAction(type,payload)),  

//	obtenerEsquemaTipoCitaListWSAction: (type,loading) => dispatch(obtenerEsquemaTipoCitaListWSAction(type,loading)),
	
})

export default connect(mapStateToProps,mapDispatchToProps)(CitasDisponibles);

