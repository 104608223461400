import React,{Component} from "react";
import Page from "./page";

export default class PacientesList extends Component{

    render(){
        let catTipoCitaesFilter = this.props.catTipoCitaes.filter((data)=>{
			if(data.nombre!=undefined){
				if(this.props.busqueda == null){
					return data;
				}else if(data.nombre.toLowerCase().includes(this.props.busqueda.toLowerCase())){
					return data;
				}
			}
         });   
  
        return< Page catTipoCitaesFilter={catTipoCitaesFilter} setTipoCita={this.props.setTipoCita}/>
    }

}

