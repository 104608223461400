import { useDispatch, useSelector } from "react-redux";
import { CAT_ESTADO_CITA, CAT_SEXO, CAT_TIPO_CITA, CITA, COLOR, MEDICO, PACIENTE } from "../../../redux/constants/action-type";
import { Avatar, Box, Chip, Paper, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography, Item, useMediaQuery, Badge, alpha, Divider } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import estetoscopioMedPNG from "../../../icons/ayuda.png";
import { getDiaStringByDateDayjs, getHorarioStringDayjs, getMesSemanaByFechaStringViewDayjs, getSemanaByFechaStringViewDayjs } from "../../../utils/dayJS";
import { getAvatarBySex, getAvatarMedBySex, getColorSemana } from "../../../utils/utils";
import { getFechatoLocaleDateString2 } from "../../../utils/date";
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';




const DEV_MODE=false; //(23jul24 Muestra fondos en color yellow para identificar mejor los elementos)

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -10,
      top: 0,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));

 const avatarMedico =(medico)=>{
    if(medico===undefined){
        return <></>
    }
    return <Box>
            <Box sx={{display:"flex",alignItems:"center",columnGap:"0.6rem" }} 
                style={{backgroundColor:DEV_MODE?"yellow":"",borderColor:DEV_MODE?"blue":"",alignItems:"center"}}
            >
                <Box  style={{backgroundColor:DEV_MODE?"blueviolet":"",borderColor:DEV_MODE?"blueviolet":"",alignItems:"center"}}>
                {getAvatarMedBySex(medico?.titulo)}

                </Box>
                <Box>
                    <Typography style={{fontWeight:"400"}} >
                        {medico?.titulo+" "+medico?.nombres+" "+medico?.apellidoPaterno+" "+medico?.apellidoMaterno} 
                    </Typography>    
                </Box>
            </Box>
    </Box>
    
 } 


function ListCitasItem(props) {
    const matches = useMediaQuery('(min-width:800px)');
    let VISIBLE_MODE=true;

    if(props.index===0){
        VISIBLE_MODE=true;
    }

    const pacientesStore =useSelector(state =>state.PacienteReducer[PACIENTE]);
    const medicosStore =useSelector(state =>state.MedicoReducer[MEDICO]);
    const catEstadoCitaStore =useSelector(state =>state.CatEstadoCitaReducer[CAT_ESTADO_CITA]);
    const catTipoCitaStore =useSelector(state =>state.CatTipoCitaReducer[CAT_TIPO_CITA]);
    const catColorStore =useSelector(state =>state.ColorReducer[COLOR]);
    
    console.log(":pacientesStore:"+JSON.stringify(pacientesStore));

    let paciente=pacientesStore.find(p=>p.id==props.item.PACIENTE_id);
    let medico=medicosStore.find(m=>m.id==props.item.MEDICO_id);
    let estadoCita=catEstadoCitaStore.find(c_ec=>c_ec.id==props.item.CAT_ESTADO_CITA_id);

    let tipoCita=catTipoCitaStore.find(c_tc=>c_tc.id==props.item.CAT_TIPO_CITA_id);
    let colorObj=catColorStore.find(c_c=>c_c.id===tipoCita?.COLOR_id);

    let avatarPaciente=getAvatarBySex(paciente?.SEXO_id);
    let edad=paciente?.edad+"a";
   
    const handleListItemClick = (value) => {
        props.setCita(value);
    };
    
  
    const avatarMedicoItem=avatarMedico(medico);

    return (<Box sx={{display:"flex", alignItems:"center",columnGap:"1rem"}}>
                <Box sx={{flex:"1"}}>                    
                <ListItem> 
                  <Paper sx={{backgroundColor:matches?"#ffffff":estadoCita?.codColor,
                              borderRadius:"1.5rem",
                              borderWidth:matches?"0px":"0.1rem",
                              }} 
                              variant={matches?"elevation":"outlined"}
                   >
                    <ListItemButton onClick={() => handleListItemClick(props.item)} sx={{paddingY:"0.6rem" }}>
                    <Box sx={{display:"flex",
                             flexDirection:matches?"row":"column",
                             rowGap:"0.6rem",
                             columnGap:"0.8rem",
                             alignContent:"center",
                             paddingY:"0.6rem",
                             borderRadius:"1.5rem",
                             alignItems:matches?"center":"flex-start"}}>
                        <Box>          
                            <Tooltip title={estadoCita?.nombre} arrow>                             
                                <FiberManualRecordIcon  sx={{display:matches?"block":"none"}} 
                                                        fontSize="small"  
                                                        style={{color:estadoCita?.codColor}}/>
                            </Tooltip> 
                        </Box>
                        <Box>  
                            <Box 
                                sx={{ display: 'flex',flexWrap:"wrap",flexDirection:"row",alignItems:"center",columnGap:"0.6rem" }}
                            >
                                <Box>
                                    <StyledBadge
                                        badgeContent={edad}
                                        color="info"
                                        anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                        }}
                                    >
                                        {avatarPaciente}
                                    </StyledBadge>
                                </Box>
                        
                                <Box>
                                    <Typography style={{fontWeight:"500"}} >
                                        {paciente?.nombres+" "+paciente?.apellidoPaterno+" "+paciente?.apellidoMaterno} 
                                    </Typography>  
                                </Box>
                                
                            </Box>                                                  
                            <Box  
                            sx={{ display: 'flex',flexWrap:"wrap",flexDirection:"row",alignItems:"center",rowGap:"0.6rem",marginTop:"0.6rem"  }}
                            style={{backgroundColor:DEV_MODE?"yellowgreen":"",width:"100%"}}
                                    >
                                    <Box style={{backgroundColor:DEV_MODE?"yellow":"",borderColor:DEV_MODE?"blue":"",width:matches?"150px":"100%"}}>
                                        <Typography variant="body1" >
                                                {getHorarioStringDayjs(props.item?.horaInicio)} - {getHorarioStringDayjs(props.item?.horaFin)} 
                                        </Typography>
                                    </Box>
                                    <Box style={{backgroundColor:DEV_MODE?"yellow":"",borderColor:DEV_MODE?"blue":"",width:matches?"210px":"100%"}}>
                                        <Chip
                                            variant="outlined"
                                            style={{
                                                    backgroundColor:matches?colorObj?.color:"#fbfbfb",
                                                    borderColor:matches?"red":colorObj?.color,
                                                    color:matches?"#FFFFFF":colorObj?.color,
                                                    borderWidth:matches?"0px":"0.05rem",                                                    
                                                    maxWidth: "210px"  
                                                    }}
                                            avatar={<Avatar  style={{backgroundColor:matches?"#FFFFFF":colorObj?.color }}><Typography variant="button" color={matches?colorObj?.color:"#FFFFFF"} >{tipoCita?.nombre.charAt(0)}</Typography></Avatar>}
                                            label={<Typography variant="button" 
                                                                style={{ 
                                                                        textTransform:"capitalize",
                                                                        whiteSpace: "nowrap",
                                                                        overflow: "hidden",
                                                                        textOverflow: "ellipsis",
                                                                        
                                                                    }}>{tipoCita?.nombre}</Typography>} 
                                            /> 
                                    </Box>
                                    
                            </Box>
                        </Box>
                        {avatarMedicoItem}

                    </Box>                        
                    </ListItemButton>
                    </Paper>
                </ListItem>
               </Box> 
         </Box>     
    );
}

export default ListCitasItem;