import React,{Component, useEffect, useState} from "react";
import Page from "./page";
import { connect } from "react-redux";
import { ESQUEMA_TIPO_CITA_HORARIO, PERIODO, HORARIO } from "../../../../redux/constants/action-type";
import {obtenerPeriodoListWSAction} from "../../../../redux/actions/PeriodoActions";
import {obtenerHorarioListWSAction} from "../../../../redux/actions/HorarioActions";
import {obtenerEsquemaTipocitaHorarioListWSAction} from "../../../../redux/actions/EsquemaTipoCitaHorarioActions";
import { getDaysOfWeekFromDate, getFechaStringToDate } from "../../../../utils/moment";


function DiaSemanaList (props){

    const getPeriodoPorDia=()=>{
        let periodoPorDia=[];
        //01Ene21 esquemaTipoCitaHorario obtiene la coleccion de los dias de semana y horarios disponobles para el tipo de cita  para el tipo de cita seleccionado por el usuario.
        console.log("esquemaTipoCitaHorario:::"+JSON.stringify(props.esquemaTipoCitaHorario));

        props.esquemaTipoCitaHorario.map(item=>
            props.horario.filter(f=>f.id===item.HORARIO_id ).map(h=>
              props.periodo.filter(f=>f.id===h.PERIODO_id).map(p=>{
                console.log("hDIA_SEMANA_id:"+JSON.stringify(h));
                periodoPorDia.push({"DIA_SEMANA_id":h.DIA_SEMANA_id,"horaInicial":p.horaInicial,"horaFinal":p.horaFinal});
               }
              )
            )
          );
          
        return periodoPorDia;
    }

    const getPeriodoPorDiaOrdenado=()=>{
        let periodoPorDia=getPeriodoPorDia();                  
        console.log("periodoPorDia1:::"+JSON.stringify(periodoPorDia));
        console.log("periodoPorDia2:::"+periodoPorDia.length);


        let agrupacionPeriodosDisponibles = periodoPorDia.reduce((recursivo, item) => {
        recursivo[item.DIA_SEMANA_id] = [...(recursivo[item.DIA_SEMANA_id] || []), item];
          return recursivo;
         }, {});
     
          return agrupacionPeriodosDisponibles;
  
    }

    let periodoPorDiaOrdenado=getPeriodoPorDiaOrdenado();
   // let newDate =getFechaDeBusqueda(); 
   console.log("periodoPorDiaOrdenado 0>>"+JSON.stringify(periodoPorDiaOrdenado));
   // console.log("newDate 0>>"+newDate);
   // let diaDeBusqueda = newDate.getDay();  
   // let weekday =getWeekSorte(diaDeBusqueda);


        return <Page 
                    periodoPorDiaOrdenado={periodoPorDiaOrdenado}
                    diaBusquedaSelecionado={getFechaStringToDate(props.fechaBusqueda)}
                    weekday={getDaysOfWeekFromDate(getFechaStringToDate(props.fechaBusqueda))}
                    
                    duracionTipoCita={props.duracionTipoCita}

                    fecha={props.fecha} 
                    fechaBusqueda={props.fechaBusqueda} 
                    setFechaBusqueda={props.setFechaBusqueda}
                    setFieldValue={props.setFieldValue} 
                    horaInicio={props.horaInicio} 
                    horaFin={props.horaFin}
                    
        />
    

}

const mapStateToProps =(state,ownProps)=>({
    periodo: state.PeriodoReducer[PERIODO],
    horario:state.HorarioReducer[HORARIO],
    esquemaTipoCitaHorario:state.EsquemaTipoCitaHorarioReducer[ESQUEMA_TIPO_CITA_HORARIO].filter(e => e.ESQUEMA_TIPO_CITA_id === ownProps.idEsquemaTipoCita)
})



export default connect(mapStateToProps)(DiaSemanaList);