import React,{ useEffect, useState} from "react";
import HorarioListItem from "../HorarioListItem";

import Grid from '@mui/material/Grid';
import { getFechaDateToStrin } from "../../../../utils/moment";
import { Box } from "@mui/material";

function Page (props){

  const [isDaySelected,setIsDaySeleted]=useState(false);



    useEffect(()=>{
      let fechaString=getFechaDateToStrin(props.dayofCard);

      let fechaLocal=fechaString;    
      console.log("useEffect (Page) [fechaLocal]"+fechaLocal);
      console.log("useEffect (Page) [props.fecha]"+props.fecha);
      console.log("useEffect (Page) [(props.fecha===fechaLocal)]"+(props.fecha===fechaLocal));

      //07Ene21 activa la paloma de seleccion para este Card e identificar que dia es selecionado.      
      setIsDaySeleted((props.fecha===fechaLocal));
    },[props.fecha]);

  
      return <Box sx={{
                       display:"flex",
                       flexDirection:"row", 
                       columnGap:"3px",
                       rowGap:"3px", 
                       justifyContent:"flex-start",
                       flexWrap:"wrap"}}>
                      
                      {props.horarios.map((item,index) => 
                            <HorarioListItem 
                              key={index}
                              desde={item.desde} 
                              hasta={item.hasta}     
                              ajustado={item.ajustado} 
                              contenido={item.contenido}   
                              idTipoCita={item.idTipoCita}                                                                                                                           
                              
                              isDaySelected={isDaySelected}

                              setFecha={props.setFecha} 
                              setHoraInicio={props.setHoraInicio} 
                              setHoraFin={props.setHoraFin}
                              
                              horaInicio={props.horaInicio} 
                              horaFin={props.horaFin}
                              
                              dayofCard={props.dayofCard}

                              duracionTipoCita={props.duracionTipoCita}
                            />

                      )}
                    </Box>
                 
                             
}

export default Page;