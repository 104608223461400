import React,{Component, useEffect, useState} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {USUARIO,ESTADO,INSTITUCION} from "../../../redux/constants/action-type";
import {connect, useDispatch, useSelector} from "react-redux";
import {ID_ROL_MEDICO,ROLES_ERLAUB_ARRAY} from "../../../utils/constantes"
import { actualizarUsuarioWSAction, eliminarUsuarioWSAction } from "../../../redux/actions/UsuarioActions";

const passwordRegex = /^[a-zA-Z0-9][a-zA-Z0-9 .,]*$/;
const alfanumericoRegex = /^[a-zA-Z0-9][a-zA-Z0-9]*$/;

const validationSchema = yup.object().shape({
    usuario:yup.object().shape({
        usuario: yup.string().matches(
            alfanumericoRegex,
            "Solo es permitido valores alphanumericos (letras y numeros)."
          ).min(8, 'debe ser mayor a 8 caracteres').max(25, 'debe ser menor a 25 caracteres').required("Campo requerido"),
		password: yup.string().matches(
            passwordRegex,
            "Solo es permitido valores alphanumericos (letras y numeros) puntos y comas."
          ).min(8, 'debe ser mayor a 8 caracteres').max(45, 'debe ser menor a 45 caracteres').required("Campo requerido"),
        passwordConfirmacion:yup.string().oneOf([yup.ref("password"),null],"Las contraseñas no coinciden. Vuelve a intentarlo."), 
		correo: yup.string().min(2, 'debe ser mayor a 2 caracteres').max(45, 'debe ser menor a 45 caracteres').email('Correo no valido (revisa no tenga espacios)').required("Campo requerido"),
        }),
    rol:yup.object().shape({
        CAT_ROL_id: yup.string().required("Campo requerido"),
        MEDICO_id: yup.string().when("CAT_ROL_id",{ is:ID_ROL_MEDICO,then:yup.string().required("Campo requerido")})
        }),
})

function UsuarioFormEdit (props){
    const dispath=useDispatch();
    const instituciones= useSelector(state=>state.InstitucionReducer[INSTITUCION]);
    const estados= useSelector(state=>state.EstadoReducer[ESTADO]);

    const [modalConfirmacion,setModalConfirmacion]=useState(false)
    const [modalEditar,setModalEditar]=useState(false);


    
    const abrirConfirmacion=()=> {
        setModalConfirmacion(true);

    }
    const cerrarConfirmacion=()=> {
        setModalConfirmacion(false);

    }

    const eliminar=(dato)=> {
        cerrarConfirmacion();
        props.setUsuario(null);
        dispath(eliminarUsuarioWSAction(USUARIO,dato))
    }

    const cerrarModalEditar=()=> {
        props.setUsuario(null);

    }

    useEffect(()=>{
        const showDialog = Boolean(props.payload);
        console.log("showDialog:"+showDialog);
  
        setModalEditar(showDialog);

        console.log("+estados:"+estados);
        console.log("+estados:"+JSON.stringify(estados));
  
    },[props.payload]);
    
    
    
    let valores=props.payload;

    if(valores===null){
        return <></>
    }

        return <Formik
                    enableReinitialize
                    initialValues={valores}
                    validationSchema={validationSchema}
                    onSubmit={(values,{setSubmitting, resetForm,stopPropagation}) => {
                          console.log("(setSubmitting)valores "+JSON.stringify(values));                              
                          setSubmitting(dispath(actualizarUsuarioWSAction(USUARIO,values)));
                          resetForm({values:""});
                          setModalEditar(false);
                      }}
                >
                {propers => < Page {...propers}  
                                 instituciones={instituciones}  
                                 estados={estados}   
                                 modalEditar={modalEditar} 
                                 cerrarModalEditar={cerrarModalEditar} 
                                 eliminar={eliminar} 
                                 payload={valores}
                                 modalConfirmacion={modalConfirmacion}
                                 cerrarConfirmacion={cerrarConfirmacion}
                                 abrirConfirmacion={abrirConfirmacion}
                                                                  
                                 />}
                </Formik> 
   



}
/*
const mapStateToProps=(state)=>({
  instituciones:state.InstitucionReducer[INSTITUCION],
  estados:state.EstadoReducer[ESTADO],
})
*/
//export default connect(mapStateToProps)(UsuarioFormEdit);
export default UsuarioFormEdit;

