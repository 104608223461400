import React,{Component, useEffect, useState} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {CAT_TIPO_CITA,COLOR} from "../../../redux/constants/action-type";
import {connect, useDispatch, useSelector} from "react-redux";
import { obtenerColorListWSAction } from "../../../redux/actions/ColorActions";
import { actualizarCatTipoCitaWSAction, eliminarCatTipoCitaWSAction } from "../../../redux/actions/CatTipoCitaActions";


const validationSchema = yup.object({
		nombre: yup.string().required("Campo requerido").matches(/^'?\p{L}+(?:[' ]\p{L}+)*'?$/u,
        'No se permiten caracteres especiales').max(45, 'debe ser menor a 45 caracteres'),
		descripcion: yup.string().required("Campo requerido").max(45, 'debe ser menor a 45 caracteres'),
		duracion: yup.string().required("Campo requerido"),
		COLOR_id: yup.string().required("Campo requerido")
})

function CatTipoCitaFormEdit (props){ 
    
    const [modalConfirmacion,setModalConfirmacion]=useState(false)
    const [modalEditar,setModalEditar]=useState(false);

    const dispatch=useDispatch();
    const color=useSelector(state=>state.ColorReducer[COLOR]);

//class CatTipoCitaFormEdit extends Component{

    /*constructor(props) {
        super(props);
        this.state={
                modalConfirmacion:false,
        };
    }*/
   const abrirConfirmacion=()=> {
       /* this.setState({
            modalConfirmacion:true
        });*/
        setModalConfirmacion(true);

    }
    const cerrarConfirmacion=()=> {
       /* this.setState({
            modalConfirmacion:false
        });*/
        setModalConfirmacion(false);

    }

    const eliminar=(dato)=> {
        cerrarConfirmacion();
        props.setTipoCita(null);
        //dispatch(eliminarCatTipoCitaWSAction(CAT_TIPO_CITA,dato));
        dato["status"]=0;
        dispatch(eliminarCatTipoCitaWSAction(CAT_TIPO_CITA,dato))
    }

    const cerrarModalEditar=()=> {
        props.setTipoCita(null);

    }
    
    useEffect(()=>{
        const showDialog = Boolean(props.payload);
        console.log("showDialog:"+showDialog);
  
        setModalEditar(showDialog);
  
    },[props.payload]);

 //   render(){    
    
        let valores=props.payload;

        if(valores===null){
            return <></>
        }
    
        return <Formik
                    enableReinitialize
                    initialValues={valores}
                    validationSchema={validationSchema}
                    onSubmit={(values,{setSubmitting, resetForm,stopPropagation}) => {
                          console.log("(setSubmitting)valores "+JSON.stringify(values));                              
                          setSubmitting(dispatch(actualizarCatTipoCitaWSAction(CAT_TIPO_CITA,values)));
                          resetForm({values:""});
                          setModalEditar(false);
                      }}
                >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    touched,
                    setFieldValue
                    }) => <Page setFieldValue={setFieldValue} 
                                 handleSubmit={handleSubmit} 
                                 handleChange={handleChange} 
                                 handleBlur={handleBlur} 
                                 values={values} 
                                 errors={errors} 
                                 touched={touched}
                                 color={color}   
                                 modalEditar={modalEditar} 
                                cerrarModalEditar={cerrarModalEditar} 
                                eliminar={eliminar} 
                                payload={valores}
                                modalConfirmacion={modalConfirmacion}
                                cerrarConfirmacion={cerrarConfirmacion}
                                abrirConfirmacion={abrirConfirmacion} />}
                </Formik> 
  //  }



}

/*
const mapStateToProps=(state)=>({
  color:state.ColorReducer[COLOR],
})*/

export default CatTipoCitaFormEdit;