import { useDispatch, useSelector } from "react-redux";
import { CAT_ESTADO_CITA, CAT_SEXO, CAT_TIPO_CITA, CITA, COLOR, MEDICO, PACIENTE } from "../../../redux/constants/action-type";
import { useEffect } from "react";
import {obtenerCitaListWSAction,agregarCitaWSAction,actualizarCitaWSAction,eliminarCitaWSAction} from "../../../redux/actions/CitaActions";
import { Avatar, Box, Chip, Container, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography, Item, useMediaQuery, Badge, Divider, ListSubheader } from "@mui/material";
import { obtenerPacienteListWSAction } from "../../../redux/actions/PacienteActions";
import { obtenerMedicoListWSAction } from "../../../redux/actions/MedicoActions";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import pacienteHPNG from "../../../icons/pacienteH.png";
import pacienteMPNG from "../../../icons/pacienteM.png";
import estetoscopioMedPNG from "../../../icons/estetoscopioMed.png";
import { FEMENINO, MASCULINO } from "../../../utils/constantes";
import { getFechatoLocaleDateString2 } from "../../../utils/date";
import { getDiaStringByDateDayjs, getMesAnioByFechaStringViewDayjs, getMesSemanaByFechaStringViewDayjs, getSemanaByFechaStringViewDayjs } from "../../../utils/dayJS";
import ListCitasItem from "./ListCitasItem";
import { getColorSemana } from "../../../utils/utils";



const DEV_MODE=false;

function ListCitasRoot(props) {
    const matches = useMediaQuery('(min-width:800px)');
    const { fecha,citas,setCita } = props;

   console.log("index:"+fecha);
   console.log("citas JSON:"+JSON.stringify(citas));
   
    return (
          <>                     
          <Box sx={{display:"flex", flexDirection:"row",backgroundColor:matches?"#F3F6F8":"#FFFFFF"}}>
            <Box >
            <ListSubheader stick="true" sx={{paddingTop:"2rem", top: "2.3rem",display:"flex",flexDirection:matches?"row":"column",backgroundColor:"transparent"}}>
                
                <Box> 
                  <Badge badgeContent={props.citas?.length} 
                         color="primary"
                  >
                    <Chip
                        sx={{ '& .MuiChip-avatar': {
                                marginLeft:matches?"5px":"0px",
                                marginRight:matches?"-6px":"0px",
                                width: matches?"25px":"30px",
                                height:matches? "25px":"30px",
                                fontSize: matches? "0.8571428571428571rem":"1.1rem",
                                color: "#616161",                            
                                },
                        
                            display:"flex",flexDirection:matches?"row":"column",
                            flexDirection:matches?"row":"column-reverse",
                            height:matches?"32px":"100%" ,
                            color:"primary",
                            backgroundColor:getColorSemana(getFechatoLocaleDateString2(props.fecha)),
                            borderWidth:matches?"0px":"0.05rem",                            
                        }}
                        variant="outlined"
                        avatar={                                              
                                    <Avatar sx={{
                                              display:"flex",flexDirection:matches?"row":"column",                                                
                                                marginleft:"0px",
                                                marginRight:"0px",
                                                backgroundColor:"#ffffff",
                                                color:"#616161"}}>{getDiaStringByDateDayjs(props.fecha)}</Avatar>
                                }
                        label={matches?getMesSemanaByFechaStringViewDayjs(props.fecha):getSemanaByFechaStringViewDayjs(props.fecha)}
                        >
                        </Chip>
                    </Badge>
                    </Box> 
                    <Box sx={{display:matches?"none":"block"}} >
                        <Chip  label={getMesAnioByFechaStringViewDayjs(props.fecha)} />          
                    </Box>  
                    </ListSubheader>
            </Box>
            <Box>
            {
                citas.map((item,index)=>{
                    return<ListCitasItem  
                                key={item.id} 
                                item={item}
                                index={index}
                                length={citas.length}
                                fecha={fecha}
                                setCita={setCita}
                            />
                                
                            
                } )
            }
            </Box>
           </Box>      
           <Divider variant="middle"></Divider>               
           </> 
            );
}

export default ListCitasRoot;