import React,{Component} from "react";
import Page from "./page";

export default class PacientesListItem extends Component{
    render(){    
        return< Page usuario={this.props.usuario} setUsuario={this.props.setUsuario}/>
    }

}

