import {CONSULTAR_COLORES, AGREGAR_COLOR, ACTUALIZAR_COLOR,ELIMINAR_COLOR} from "../constants/Color-action-type";
import {estadoInicial} from "../store/initialState";

export default function(state =estadoInicial.datos, action){

    console.log("Dentro de REDUCERS (COLORES):"+action.type);

    switch(action.type){
        
        case CONSULTAR_COLORES:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat.apply([],[action.payload]),
                estatus:action.estatus

            }

        case AGREGAR_COLOR:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat(action.payload)

            }

        case ACTUALIZAR_COLOR:
            return{
                ...state,
                [action.dataType]: state[action.dataType].map(p =>
                p.id === action.payload.id ? action.payload : p)        

            }
        case ELIMINAR_COLOR:
            return{
                ...state,
                [action.dataType]: state[action.dataType]
                .filter(p => p.id !== action.payload.id)                    

            }

                default:
            console.log("ENTRA EN DEFAULT SWICTH  EN REDUCER COLORES");
            return state||estadoInicial.datos;
        

    }
}
