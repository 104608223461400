import { PDFDownloadLink } from "@react-pdf/renderer";
import Button from '@mui/material/Button';
import { QRCodeCanvas } from "qrcode.react";
import React, { useEffect, useState ,memo} from "react";
import { Box } from "@mui/material";
import QRPDFDocumento from "./QRPDFDocumento";
import GetAppIcon from '@mui/icons-material/GetApp';

function QRGeneracion(props){

    const[dataURL,setDataURL]=useState("");
    const[generarQRPDF,setgenerarQRPDF]=useState(false);

    let pdfDescarga=""

    useEffect(() => {
        var canvas = document.getElementById('canvas');
        let dataURL = canvas.toDataURL();
        setDataURL(dataURL);
     //   console.log("dataURL:"+dataURL);
        setgenerarQRPDF(true);
        console.log(">>dataURL<<:"+dataURL);

    });
    console.log(">>GENERANDO PDF<<"+generarQRPDF);

    if(generarQRPDF){
        pdfDescarga=<PDFDownloadLink 
                        document={
                            <QRPDFDocumento 
                                dataURL={dataURL} 
                                paciente={props.paciente}  
                                medico={props.medico} 
                                procedimiento={props.procedimiento}
                                fecha={props.fecha} 
							    horaCita={props.horaCita}
                            />
                        }
                        fileName={props.paciente+".pdf"}
                        style={{  textDecoration:"none"}}
                    >

                        {({loading})=>(loading?"cargando documento":<Button         
                                                                        startIcon={<GetAppIcon />}
                                                                        variant="contained" 
                                                                        color="primary" 
                                                                        size="medium" 
                                                                        >
                                                                        PDF Cita-QR
                                                                    </Button>)}
                        </PDFDownloadLink>
    
    }


    return (<Box style={{display:"flex",justifyContent:"space-around",alignItems:"center"}} >
                <Box>
                    <QRCodeCanvas id="canvas" value={props.id} size={128} bgColor="#fff" fgColor="#282c34" level={"H"} includeMargin={true}/>
                </Box>
                <Box>
                    {pdfDescarga}
                </Box>
            </Box>
    )
}
export default memo(QRGeneracion);