import { Avatar } from "@mui/material";
import pacienteHPNG from "../icons/pacienteH.png";
import pacienteMPNG from "../icons/pacienteM.png";
import doctorHPNG from "../icons/doctor.png";
import doctorMPNG from "../icons/doctora.png";
import { FEMENINO, MASCULINO } from "./constantes";

//recibe fecha 
export const getColorSemana=(fecha)=>{

    let color="#f5f5f5";
    let dia=fecha.substring(0,3);
    console.log("diaSemana:"+dia);
    switch (dia) {
      case "mar":
        color = "#e1f5fe";
        break;
      case 'sáb':
        color = "#e8f5e9";
        break;
      case "jue":
        color = "#fffde7";
        break;
      case "mié":
        color = "#ffebee";
        break;
      case "lun":
        color = "#fbe9e7";
        break;
      case "vie":
        color = "#fff3e0";
        break;
      case "dom":
        color = "#eceff1";
        break;
      }

    return color;
}

export const getAvatarBySex=(sexIdValue)=>{
  let colorFace="";
  let pacientePNG="";
  if(sexIdValue===MASCULINO){
    colorFace="#e4f8ff";
    pacientePNG=pacienteHPNG;                       

  }else if(sexIdValue===FEMENINO){
    colorFace="#ffdee3";
    pacientePNG=pacienteMPNG;
  }
  
  let avatar= <Avatar   
                 sx={{padding:"3px"}}
                 alt="Paciente"  
                 src={pacientePNG}  
                 style={{ 
                        width: "2.3rem",
                        height: "2.3rem" ,
                        color:colorFace,
                        backgroundColor:colorFace
                    }} 
          />
  return avatar;        

}

export const getAvatarMedBySex=(sexIdValue)=>{
  let colorFace="";
  let pacientePNG=doctorHPNG;
  if(sexIdValue==="Dr."){
    colorFace="#e4f8ff";
    pacientePNG=doctorHPNG;                       

  }else if(sexIdValue==="Dra."){
    colorFace="#ffdee3";
    pacientePNG=doctorMPNG;
  }
  
  let avatar= <Avatar   
                 sx={{padding:"3px"}}
                 alt="Paciente"  
                 src={pacientePNG}  
                 style={{ 
                        width: "2.3rem",
                        height: "2.3rem" ,
                        color:colorFace,
                        backgroundColor:colorFace
                    }} 
          />
  return avatar;        

}