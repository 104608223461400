import React,{Component} from "react";
import Page from "./page";
import { PACIENTE } from "../../../redux/constants/action-type";

class PacientesListItem extends Component{
    render(){    
        return <Page paciente={this.props.paciente}  
                     setPaciente={this.props.setPaciente}/>
    }

}

export default PacientesListItem;