import React,{Component} from "react";
import MedicoListItem from "../MedicoListItem";
import List from '@mui/material/List';

export default class Page extends Component{

  constructor(props) {
    super(props);
  }

    render(){      
      return <List > {
            this.props.medicosFilter.map(medico=> 
                      <MedicoListItem 
                        key={medico.id} 
                        medico={medico} 
                        setMedico={this.props.setMedico}/> )
            }</List>
    }                          
}
