import React,{Component, useEffect} from "react";
import DiaSemanaListItem from "../DiaSemanaListItem";
import "./estilo.css"
import CardContent from '@mui/material/CardContent';
import { dayOffset } from "../../../../utils/moment";
import { Alert, Stack } from "@mui/material";


export default function Page (props){
    
    console.log("this.props.periodoPorDiaOrdenado:"+JSON.stringify(props.periodoPorDiaOrdenado));
    console.log("weekday:"+JSON.stringify(props.weekday));

  useEffect(()=>{
    console.log(" useEffect TERMINA SEMANAS ")
    return (console.log(" useEffect TERMINA SEMANAS return"))
  },[]);   
  
    return (JSON.stringify(props.periodoPorDiaOrdenado)!== "{}")
            ?<CardContent className="card_main" >
               
             {props.weekday.map((item,index)=>{             // Se obtinene los dias de la semana y los periodos de horario desde el esquema de trabajo {this.props.setPeriodoPorDia({p.horaInicial+","+p.horaInicial+","+p.horaInicial})}

                                    if(props.periodoPorDiaOrdenado[item]!==undefined){
                                      console.log("props.diaBusquedaSelecionado (map):"+props.diaBusquedaSelecionado);
                                      console.log("props.diaBusquedaSelecionado (item):"+JSON.stringify(item));
                                      let dayofCard=dayOffset(props.diaBusquedaSelecionado,index);
                                      return  <DiaSemanaListItem  
                                                key={index}
                                                dayofCard={dayofCard}
                                                idDia={item}           
                                                periodoList={props.periodoPorDiaOrdenado[item]}
                                                diaBusquedaSelecionado={props.diaBusquedaSelecionado}
                                                duracionTipoCita={props.duracionTipoCita}                            
                                                fecha={props.fecha} 
                                                setFieldValue={props.setFieldValue} 
                                                horaInicio={props.horaInicio} 

                                                />   
                                    }                  
                                  }                  
                            )
              }    
              
              </CardContent>
              :<Stack sx={{ width: '100%' }} spacing={2} margin={2}>
                  <Alert severity="info">No se encontrarón resultados.</Alert>
               </Stack>       
                            
}