import { Grid } from "@mui/material";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "./auth";

export const ProtectedRoute = () => {
    const auth=useAuth();

    if (!auth.isLoggedIn) {
        return <Navigate to="/login" />
    }
    return (
      <Grid container direction="column" width="100%" flexWrap="nowrap">
          <Outlet />        
      </Grid>
    );
  };