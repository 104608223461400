import React,{Component} from "react";
import { styled } from '@mui/material/styles';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import {Link} from "react-router-dom";
import { useSelector } from "react-redux";
import { PROFIL_ADMINISTRADOR } from "../../../utils/constantes";


const PREFIX = 'page';

const classes = {
  text: `${PREFIX}-text`,
  paper: `${PREFIX}-paper`,
  list: `${PREFIX}-list`,
  subheader: `${PREFIX}-subheader`,
  appBar: `${PREFIX}-appBar`,
  grow: `${PREFIX}-grow`,
  fabButton: `${PREFIX}-fabButton`
};

const StyledFab = styled(Fab)((
  {
    theme
  }
) => ({
  [`& .${classes.text}`]: {
    padding: theme.spacing(2, 2, 0),
  },

  [`& .${classes.paper}`]: {
    paddingBottom: 50,
  },

  [`& .${classes.list}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.subheader}`]: {
    backgroundColor: theme.palette.background.paper,
  },

  [`& .${classes.appBar}`]: {
    top: 'auto',
    bottom: 0,
  },

  [`& .${classes.grow}`]: {
    flexGrow: 1,
  },

  [`& .${classes.fabButton}`]: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  }
}));

function Page (props){

  const profil =useSelector(state =>state.autenticacionReducer.profil);


  const abrirModal=()=> {
    props.abrirModal(true);
  }

  //(02feb24) Se agraga para validar el perfil de anestesia o algun otro
    return (
      <StyledFab
      position="fixed"
      variant="extended"
      size="large"
      color="default"
      aria-label="agregar"
      style={{position: 'fixed', bottom: "4.5rem", right: "1rem",boxShadow:"1", backgroundColor:"#FFFFFF",borderColor:"#004C99",borderWidth:"0.3rem"}}
      onClick={abrirModal}
    >
       <AddIcon sx={{ mr: 1 }}  style={{ color:"#004C99", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}} />
       <Typography type="Roboto" style={{flexGrow:"1",color: '#004C99', fontSize:'1.2rem',fontWeight:'normal',textTransform: "capitalize" }}>Agregar</Typography>
    </StyledFab>

    );       
                                                 
}
export default Page;
