import React,{Component,Papper, useState} from "react";
import TextField from "@mui/material/TextField";
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';

import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Delete from '@mui/icons-material/Delete';
import { useEffect } from "react";
import { Box, IconButton, Paper } from "@mui/material";
import { useRef } from "react";
import ClearIcon from '@mui/icons-material/Clear';

//export default class Page extends Component{
  function Page (props){
    const MINUTE_MS = 500;

    const [value, setValue] = useState('')

    
    const scrollToBottom = (input) => {  

      const interval = setTimeout(() => {
        if (input) {
          input.focus();
        }          
      }, MINUTE_MS);
  
      return () => clearInterval(interval);

    }

    
    const busquedaFuncion=(event)=>{
      let valor = event.target.value;
      setValue(valor);
    }

    useEffect(()=>{      
      props.setBusqueda(value);

    },[value]);

  
      return <Box m={2}>      
                <Paper  elevation={0}>
    
                    <TextField                            
                      id="busqueda"
                      label={props.label}
                      type="text"
                      fullWidth
                      size="small"
                      variant="outlined" 
                      value={value}
                      InputProps={{
                        style: { color: "#000",backgroundColor:"whitesmoke" },
                         startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon color="primary"/>
                          </InputAdornment>
                        ),
                        endAdornment:(
                                      <IconButton  position="end" onClick={() => setValue('')}>
                                        <ClearIcon color="secondary"/>
                                      </IconButton>
                                    )
                      }}  
                      onChange={(e)=>busquedaFuncion(e)}    
                      style={{backgroundColor:"#fff"}}
//                      inputRef={scrollToBottom}
                       
                      /> 
              </Paper>   
             </Box>   
                             
}
export default Page;