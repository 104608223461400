import React,{Component} from "react";
import Page from "./page";
import { useMediaQuery } from "@mui/material";

function BottomNavigationComponent(props) {
    const matches = useMediaQuery('(min-width:800px)');

        return  (
                <div>
                    {matches?"":<Page opcion={props.opcion}/>}
                </div>
                )    
}


export default BottomNavigationComponent;