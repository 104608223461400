import { useDispatch, useSelector } from "react-redux";
import { CAT_ESTADO_CITA, CAT_TIPO_CITA, CITA, COLOR, ESQUEMA_TIPO_CITA, ESQUEMA_TRABAJO, MEDICO, PACIENTE, TIPO_DE_DATO } from "../../../redux/constants/action-type";
import { useEffect, useMemo, useState } from "react";
import {obtenerCitaListWSAction} from "../../../redux/actions/CitaActions";
import { obtenerPacienteListWSAction } from "../../../redux/actions/PacienteActions";
import { obtenerMedicoListWSAction } from "../../../redux/actions/MedicoActions";
import { obtenerCatEstadoCitaListWSAction } from "../../../redux/actions/CatEstadoCitaActions";
import { obtenerCatTipoCitaListWSAction } from "../../../redux/actions/CatTipoCitaActions";
import { obtenerColorListWSAction } from "../../../redux/actions/ColorActions";
import ListCitasRoot from "./ListCitasRoot";
import CitaFormEdit from "../CitaFormEdit";
import { obtenerEsquemaTrabajoListWSAction } from "../../../redux/actions/EsquemaTrabajoActions";
import { obtenerEsquemaTipoCitaListWSAction } from "../../../redux/actions/EsquemaTipoCitaActions";
import { combinaFechaTimeDays } from "../../../utils/dayJS";
import CitaFloatButton from "../CitaFloatButton";
import CitaForm from "../CitaForm";
import { PROFIL_ADMINISTRADOR } from "../../../utils/constantes";
import { Collapse } from "@mui/material";
import Busqueda from "../../BusquedaComponent";

function Citas() {
    const dispath =useDispatch();

    const citasAgendadasStore = useSelector(state =>state.CitaReducer[CITA]);
    const pacientesStore =useSelector(state =>state.PacienteReducer[PACIENTE]);
    const profil =useSelector(state =>state.autenticacionReducer.profil);

    const [cita, setCita]=useState(null);
    const [isModalNuevaCita,setIsModalNuevaCita]=useState(false);
    const isBusqueda =useSelector(state =>state.estadosReducer.mostrarBusqueda);    
    const [busqueda,setBusqueda]=useState("");
    const [citaesFilter,setCitaesFilter]=useState([]);

    console.log(":citasAgendadasStore:"+JSON.stringify(citasAgendadasStore));
    console.log(":citaesFilter:"+JSON.stringify(citaesFilter));

 

    useEffect(()=>{
        console.log("useEffect:Cita");     
            dispath(obtenerPacienteListWSAction(PACIENTE,false));     
            dispath(obtenerMedicoListWSAction(MEDICO,false));
            dispath(obtenerCatEstadoCitaListWSAction(CAT_ESTADO_CITA,false));
            dispath(obtenerCatTipoCitaListWSAction(CAT_TIPO_CITA,false));
            dispath(obtenerColorListWSAction(COLOR,false));
            dispath(obtenerEsquemaTrabajoListWSAction(ESQUEMA_TRABAJO,false));       
            dispath(obtenerEsquemaTipoCitaListWSAction(ESQUEMA_TIPO_CITA,false));           
         //   dispath(obtenerCitaListWSAction(CITA,false));

    },[]);
    
    
    useEffect(()=>{      
        console.log("cambia busqueda (useEffect):"+busqueda);
        
		let citaesPacienteCombinacionFilter = citasAgendadasStore.filter((data)=>{

            if(busqueda === ""){
                return data;
            }else if(pacientesStore!==undefined ){
                  let paciente = pacientesStore.find(item=>data.PACIENTE_id===item.id); 
                  if(paciente!==undefined){
                    if(paciente.nombres?.toLowerCase().includes(busqueda?.toLowerCase()) ||  paciente.apellidoPaterno?.toLowerCase().includes(busqueda?.toLowerCase()) || paciente.apellidoMaterno?.toLowerCase().includes(busqueda?.toLowerCase())){ 
                        return data;
                    }
                  }						

            }else{
                return data;

            }
        });   

        setCitaesFilter(citaesPacienteCombinacionFilter);
        console.log(" citaesPacienteCombinacionFilter:"+JSON.stringify(citaesPacienteCombinacionFilter));	 

    },[busqueda,citasAgendadasStore,pacientesStore]);

    useEffect(()=>{      
        if(pacientesStore.length !== 0){ //espera a que se llene la lista de pacientes para poder renderizar solo una vez
            dispath(obtenerCitaListWSAction(CITA,false));
        }
    },[pacientesStore]);


    useEffect(()=>{      
        console.log("cambia citas (useEffect)"+citaesFilter.length);
        dispath({type:TIPO_DE_DATO,
                 dataType:CITA,
                 registros:citaesFilter.length});
    },[citaesFilter]);



  //(04marz24) Las ordena por fecha  
    const sortCitasAgendadasStore =()=>{
        citaesFilter.sort(function(a, b) {
            let date1=combinaFechaTimeDays(a.fecha,a.horaInicio);
            let date2=combinaFechaTimeDays(b.fecha,b.horaInicio);
            // Subtract the dates to get a value that is either negative, positive, or zero
            return date1 - date2;
          });
          console.log(":sortcitaesFilter:"+JSON.stringify(citaesFilter));
    }

//(04marz24)MEMO agrupa las citas por dias en la posicion correcta. al eliminar o agregar
    const agrupacionCitasByFechaResult = useMemo(() => {
                                            console.log("agrupacionCitasByFechaResult [useMemo]");
                                            sortCitasAgendadasStore();
                                            return citaesFilter.reduce((recursivo, citaItem) => {
                                                    recursivo[citaItem.fecha] = [...(recursivo[citaItem.fecha] || []), citaItem];
                                                    return recursivo;
                                                }, {})
                                        },[citaesFilter]);

    if(citasAgendadasStore.length === 0){
        return(
            <div>
            <div style={{marginBottom:"4rem" , marginTop:"4rem" }}>
            <p>Cargando...</p>
            </div>
      </div>   
    
        ) 

    }

    const keys = Object.keys(agrupacionCitasByFechaResult);

    return (
      <div>
      
        
        <div style={{position:"relative",marginBottom:"4rem" , marginTop:"4rem" }}>
            <Collapse in={isBusqueda}>
                <Busqueda label="Buscar Paciente" setBusqueda={setBusqueda}  />
            </Collapse>    
            {
                keys.map((item)=>{ 
                     return <ListCitasRoot 
                                key={item} 
                                citas={agrupacionCitasByFechaResult[item]} 
                                fecha={item}
                                setCita={setCita}
                            />
                    })
            }
            
        </div>
        

        {(profil===PROFIL_ADMINISTRADOR)?<CitaFloatButton abrirModal={setIsModalNuevaCita} />:""}
        {(profil===PROFIL_ADMINISTRADOR)?<CitaForm  modal={isModalNuevaCita} cerrarModal={setIsModalNuevaCita} />  :""}
        {(profil===PROFIL_ADMINISTRADOR)?<CitaFormEdit payload={cita} setCita={setCita}/> :""}
                
      </div>
    );
}

export default Citas;