import React,{Component} from "react";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default class Page extends Component{

    render(){
        

        return  <FormControl
                    fullWidth
                    margin="normal"	
                    variant="filled" 

                    >
                        <InputLabel htmlFor="age-native-simple">PACIENTE</InputLabel>
                        <Select
                            error={this.props.touched.rol&&this.props.touched.rol.PACIENTE_id&&this.props.errors.rol&&this.props.errors.rol.PACIENTE_id? true:false}
                            size="medium"
                            id={this.props.PACIENTE_id}
                            label="PACIENTE_id:"
                            type="text"
                            fullWidth
                            variant="outlined"
                            onChange={this.props.handleChange} 
                            value={this.props.pacienteValue}
                            onBlur={this.props.handleBlur}
                            native
                        >
                                <option aria-label="None" value="" disabled/>

                                {

                                
                                    this.props.pacientes.map(paciente=> (
                                        <option key={paciente.id}  value={paciente.id}>{paciente.nombres} {paciente.apellidoPaterno} </option>)
                                    )
                                    
                                }
                            </Select>
                    </FormControl>
                
    }
}
