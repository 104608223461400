import {RestDataSource} from "./RestDataSource";
import { MOSTRAR_MENSAJE, PETICION_WS_SOLICITANDO, PETICION_WS_EXITOSA, PETICION_WS_ERROR,
         TIPO_DE_DATO, MEDICO,INSTITUCION_MEDICO} from "../constants/action-type";
import { CONSULTAR_MEDICOS, AGREGAR_MEDICO, ACTUALIZAR_MEDICO, ELIMINAR_MEDICO } from "../constants/Medico-action-type";
import { AGREGAR_INSTITUCION_MEDICO } from "../constants/InstitucionMedico-action-type";
import { PROFIL_ADMINISTRADOR } from "../../utils/constantes";


/* Funcion para loggin llamada logMiddleware */
const createMedicoRestMiddleware =(medicosURL,institucionMedicoURL)=>{
    /* Obtiene datos de webservice  */
    const dataSources={
        [MEDICO]: new RestDataSource(medicosURL),
        [INSTITUCION_MEDICO]: new RestDataSource(institucionMedicoURL),
    }

return ({getState, dispatch}) => next => action => {
    console.log("Dentro de MedicoRestMiddleware:"+action.type);

    let profile=getState().autenticacionReducer.profil;


    /* Antes de enviar al Reducer */
    switch(action.type){

                /** MEDICO INICIO */    
                //(26junio21) Se actualiza para obtener la consulta de medico por INSTITUCION_id                                
                case CONSULTAR_MEDICOS:
                  if(getState().MedicoReducer.estatus==="idle"){                
 
                    let profil=getState().autenticacionReducer.profil;
                    console.log("PERFIL DEL USUARIO:"+profil);
                    if(profil===PROFIL_ADMINISTRADOR){ //(26junio21)Se obtienen los tipos de cita por Institucion //ya no por medico
                        console.log("DESDE PROFIL_ADMINISTRADOR:"+JSON.stringify(getState().InstitucionReducer.instituciones[0].id));
                        var datosMedicosByInstitucion ={};
                        datosMedicosByInstitucion['INSTITUCION_id']=getState().InstitucionReducer.instituciones[0].id;
                        if(action.loading){
                            next({type:PETICION_WS_SOLICITANDO});
                        }
                        dataSources[action.dataType].GetDataParams((dataMedico,mensaje)=>{ 
                            if(dataMedico===null){
                                if(action.loading){
                                    next({type:PETICION_WS_ERROR});
                                }
                            }else{
                                next({     //por cada registro devuelto del Webservices se envia la funcion next
                                    type:CONSULTAR_MEDICOS,
                                    dataType:action.dataType,
                                    payload:dataMedico,
                                    estatus:"succeed"
                                }); 
                                if(action.loading){
                                    next({type:PETICION_WS_EXITOSA});
                                    next({type:TIPO_DE_DATO,dataType:action.dataType,registros:dataMedico.length});
                                }
                            }
                        },datosMedicosByInstitucion);      
                    }
                  }
                break;   

                case AGREGAR_MEDICO:
                    console.log("AGREGAR_MEDICO(Middleware):"+JSON.stringify(action.payload));
                    let profil2=getState().autenticacionReducer.profil;
                    console.log("PERFIL DEL USUARIO:"+profil2);
                    if(profil2===PROFIL_ADMINISTRADOR){ //(26junio21)Se obtienen los tipos de cita por Institucion //ya no por medico
                        console.log("DESDE PROFIL_ADMINISTRADOR:"+JSON.stringify(getState().InstitucionReducer.instituciones[0].id));
                        next({
                            type:PETICION_WS_SOLICITANDO
                            });                                   
                        dataSources[action.dataType].SetData((dataMedico,mensaje)=>{ 
                            if(dataMedico===null){
                                console.log("FALLA PETICION A MEDICO:"+mensaje);
                                next({
                                    type:PETICION_WS_ERROR
                                });
                            }else{
                                console.log("AGREGAR_MEDICO (dataMedico):"+dataMedico); 
                                console.log("AGREGAR_MEDICO (json dataMedico):"+JSON.stringify(dataMedico)); 
                                console.log("AGREGAR_MEDICO (action.payload):"+JSON.stringify(action.payload));     
                                         
                                console.log("AGREGAR_MEDICO (action.dataType):"+action.dataType);            
                               
                                    
                                //(27junio21) Si la operacion es exitosa se agrega el la tabla relacional medico-institucion    
                                var datosInstitucionMedico ={};
                                datosInstitucionMedico["INSTITUCION_id"]=getState().InstitucionReducer.instituciones[0].id;
                                datosInstitucionMedico["MEDICO_id"]=dataMedico.id;
                                console.log("datosInstitucionMedico"+JSON.stringify(datosInstitucionMedico));

                                dataSources[INSTITUCION_MEDICO].SetData((dataInstitucionMedico,mensaje)=>{ 
                                    if(dataInstitucionMedico===null){
                                        console.log("FAllo peticion a Servicio:"+mensaje);
                                        next({
                                            type:PETICION_WS_ERROR
                                        });
                                    }else{
                                        console.log("Se actuliza en Axios (dataInstitucionMedico):"+JSON.stringify(dataInstitucionMedico));                
                                        next({     //por cada registro devuelto del Webservices se envia la funcion next
                                            type:AGREGAR_MEDICO,
                                            dataType:action.dataType,
                                            payload:action.payload
                                            });
                                        next({     
                                            type:AGREGAR_INSTITUCION_MEDICO,
                                            dataType:INSTITUCION_MEDICO,
                                            payload:dataInstitucionMedico
                                        });
                                        let count=parseInt( getState().estadosReducer.registros) + 1;
                                        console.log("coun:"+count); 
                                        next({type:PETICION_WS_EXITOSA});
                                        next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                                        next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se agrego el registro exitosamente!."}); 

                                    }
                                },datosInstitucionMedico);

                            }
                        },action.payload);
    
                    }

                            
                 break; 

                 case ACTUALIZAR_MEDICO:
                    if(profile===PROFIL_ADMINISTRADOR){ //(26junio21)Se obtienen los tipos de cita por Institucion //ya no por medico
                        console.log("PERFIL DEL USUARIO:"+profile);
                        next({type:PETICION_WS_SOLICITANDO});
   
                              dataSources[action.dataType].UpdateData((dataMedico,mensaje)=>{ 
                               if(dataMedico===null){
                                   console.log("FAllo peticion a Servicio:"+mensaje);
                                   next({type:PETICION_WS_ERROR});
                               }else{
                                   console.log("Se actuliza en Axios (dataMedico):"+dataMedico);
               
                                   next({     //por cada registro devuelto del Webservices se envia la funcion next
                                       type:ACTUALIZAR_MEDICO,
                                       dataType:action.dataType,
                                       payload:dataMedico});
                                   let count=parseInt( getState().estadosReducer.registros) + 0;
                                   next({type:PETICION_WS_EXITOSA});
                                   next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                                   next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se actualizo el registro exitosamente!."}); 
                                   }
                               },action.payload);
   
                    }
                              
                 break;    

                 case ELIMINAR_MEDICO:
                    if(profile===PROFIL_ADMINISTRADOR){ //(26junio21)Se obtienen los tipos de cita por Institucion //ya no por medico
                        next({type:PETICION_WS_SOLICITANDO});
                        dataSources[action.dataType].DeleteData((dataMedico,mensaje)=>{ 
                            console.log("Se Elimina en Axios (dataMedico):"+dataMedico);
                            console.log("Se Elimina en Axios (json dataMedico):"+JSON.stringify(dataMedico));


                            if(dataMedico===null){
                                console.log("FAllo peticion a Servicio:"+mensaje);
                                next({type:PETICION_WS_ERROR});
                            }else{
                                console.log("Se Elimina en Axios (dataMedico):"+JSON.stringify(dataMedico));
            
                                next({     //por cada registro devuelto del Webservices se envia la funcion next
                                    type:ELIMINAR_MEDICO,
                                    dataType:action.dataType,
                                    payload:dataMedico});
    
                                let count=parseInt( getState().estadosReducer.registros) - 1;
                                console.log("coun(eliminar):"+count); 
                                next({type:PETICION_WS_EXITOSA});
                                next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                                next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se elimino el registro exitosamente!."}); 
                                }
                            },action.payload.id);
                        
                    }
        
                    break;  



 

        default:
                next(action);

    }
 }

}

export default createMedicoRestMiddleware;