import React,{Component, useState} from "react";
import "./estilo.css"
import { Box, Button, CircularProgress, IconButton, Paper, Typography } from "@mui/material";
//import OtroHorario from "../HorariosBusqueda/OtroHorario";
//import BuscarHorario from "../HorariosBusqueda/BuscarHorario";
import { PROFIL_ADMINISTRADOR } from "../../../../utils/constantes";
import OtroHorario from "../../HorariosBusqueda/OtroHorario";
import BuscarHorario from "../../HorariosBusqueda/BuscarHorario";
const Page=(props)=>{
    //  const [isLoading,setIsLoading]=useState(true);
      let OtroHorarioBoton=<div/>; 

      if(props.profil===PROFIL_ADMINISTRADOR){
            OtroHorarioBoton=<OtroHorario
                               fechaBusqueda={props.fechaBusqueda} 
                               setFechaBusqueda={props.setFechaBusqueda}   
                               setFieldValue={props.setFieldValue}                              
                              />
      }

    return (<div>
                <Box flexDirection="row"  style={{marginTop:"0.5em"}}>
                      {OtroHorarioBoton}
                      <BuscarHorario 
                            duracionTipoCita={props.duracionTipoCita}            
                            fecha={props.fecha} 
                            fechaBusqueda={props.fechaBusqueda} 
                            setFechaBusqueda={props.setFechaBusqueda} 
                            setFieldValue={props.setFieldValue} 
                            horaInicio={props.horaInicio} 
                            horaFin={props.horaFin}
                            idEsquemaTipoCita={props.idEsquemaTipoCita}    
                            MEDICO_id={props.MEDICO_id}
                            CAT_TIPO_CITA_id={props.CAT_TIPO_CITA_id}
                  
                      />
                 
                </Box>
              </div>)                   
}
export default Page;