import React,{Component, useEffect, useState} from "react";
import { Box, Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, TextField } from "@mui/material";
import DiaSemanaList from "../DiaSemanaList";
import { SearchOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

const Page=(props)=>{
 
  const MINUTE_MS = 300;

  const [loading, setLoading] = React.useState(false);

  const divRef = React.useRef();

  const[open,setOpen]=useState(false);

  let buscarHorariosDisponibles="";
  
  const scrollToBottom = () => {  
		if (divRef.current) {
			console.log(">>scrollToBottom()<<:");
			divRef.current.scrollIntoView({behavior: "smooth"});
		}
	}

  const handleClickOpen = () => {
    setOpen(true);
    setLoading(true);    
    const interval = setTimeout(() => {
      console.log('Logs every minute');
      scrollToBottom();
    }, MINUTE_MS);

    return () => clearInterval(interval);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeDesde=(event)=>{

  }

  const handleChangeHasta=(event)=>{
  }

  const handleClick=()=>{   
  }

  if(open){
    
    buscarHorariosDisponibles=<DiaSemanaList
                                    duracionTipoCita={props.duracionTipoCita}

                                    fecha={props.fecha} 
                                    fechaBusqueda={props.fechaBusqueda} 
                                    setFechaBusqueda={props.setFechaBusqueda}
                                    setFieldValue={props.setFieldValue} 
                                    horaInicio={props.horaInicio} 
                                    horaFin={props.horaFin}
                                    idEsquemaTipoCita={props.idEsquemaTipoCita}
                                />                                                            
  }

  useEffect(()=>{
    return (setLoading(false))
  });
      return (<Card  
                variant="outlined"
                direction="row"
                justify="center"
                spacing={1} 
                style={{flexGrow:"1",marginTop:"1rem"}} 
              >
              <div id="divRef" ref={divRef} />
                <LoadingButton
                  fullWidth
                  onClick={handleClickOpen}
                  startIcon={<SearchOutlined />}
                  loading={loading}
                  loadingPosition="start"
                  variant="contained"
                >
                  <span>BUSCAR HORARIO</span>
                </LoadingButton>
                {buscarHorariosDisponibles}


            </Card>)
                             
}

export default Page;
  