import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import React, { useEffect } from "react";
import RestoreIcon from '@mui/icons-material/Restore';
import citaPNG from "../../icons/cita.png";
import pacientesPNG from "../../icons/pacientes.png";
import medicosPNG from "../../icons/medicos.png";
import {Link} from "react-router-dom";


const Page = (props) => {
  const [value, setValue] = React.useState(0);
  const dataPositionButtons = [
    { id: 0, name: "citas" },
    { id: 1, name: "pacientes" },
    { id: 2, name: "medicos" },
  ];
  useEffect(()=>{
    let position =dataPositionButtons.findIndex((item) => item.name === props.opcion);
    console.log("opcion=[BottomNavigation]:"+props.opcion+" - "+position);
    setValue(position);
  },[props.opcion]);

  return (
          <div>
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0,zIndex:1000 }} elevation={6}>
                      <BottomNavigation
                        
                        showLabels      
                        value={value}
                        onChange={(event, newValue) => {
                          console.log("newValue=[BottomNavigation]:"+newValue);
                          setValue(newValue);
                        }}  
                        sx={{height: "3.7rem"}}            
                      >

                        <BottomNavigationAction component={Link} to={"/citas"} label="Agendados" icon={<img src={citaPNG} style={{ width: "1.4rem",height: "1.4rem", color:"#1e88e5"}}/>} />
                        <BottomNavigationAction component={Link} to={"/pacientes"} label="Pacientes" icon={<img src={pacientesPNG} style={{ width: "1.4rem",height: "1.4rem", color:"#607d8b"}}/>} />
                        <BottomNavigationAction component={Link} to={"/medicos"} label="Medicos" icon={<img src={medicosPNG} style={{ width: "1.4rem",height: "1.4rem", color:"#00bfa5"}}/>} />
                      </BottomNavigation>
                </Paper>      
          </div>              
          )
}

export default Page;