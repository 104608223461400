import React,{ useEffect, useState} from "react";
import Page from "./page";
import { connect } from "react-redux";
import { PACIENTE,CAT_TIPO_CITA,COLOR, CITA } from "../../../../redux/constants/action-type";
import { getFechaDateToStringDays, isBetweenDays } from "../../../../utils/dayJS";

function HorarioListItem (props){

    const [codColorItem,setCodColorItem]=useState("")
    const [disabled,setDisabled] = useState(false);

    const containCita=()=>{

       props.citaes.map(item => {
            //if(item.fecha === getFechaDateToStringDays(props.dayofCard)){
                let citaDateIni=item.fecha+" "+item.horaInicio;
                let citaDateFin=item.fecha+" "+item.horaFin;
              //  console.log("obj  citaDate1(citaes):"+citaDate1);
                let contenido=isBetweenDays(citaDateIni,props.desde,props.hasta,0);
                let contenido2=isBetweenDays(citaDateFin,props.desde,props.hasta,1);
               //let contenido=isBetweenDays(props.desde,citaDate1,citaDate2);
               //let contenido2=isBetweenDays(props.hasta,citaDate1,citaDate2);

                console.log("obj  contenido(citaes):"+contenido);
                if(contenido||contenido2){
                    setDisabled(true);       
                    return;             
                }  
          //  }
        });

    }
    useEffect(() => {
        containCita();

        if(props.catTipoCita!==undefined){        
            setCodColorItem(props.colores.find((item)=> item.id === props.catTipoCita.COLOR_id ).color);
        }else{
            setCodColorItem("");
        }
        
    },[]);

        return (<Page 
                     desde={props.desde} 
                     hasta={props.hasta} 
                     isDaySelected={props.isDaySelected}  
                     ajustado={props.ajustado}  
                     contenido={disabled} 
                     codColorItem={codColorItem}      
                                          
                     setFecha={props.setFecha} 
                     setHoraInicio={props.setHoraInicio} 
                     setHoraFin={props.setHoraFin} 
                     
                     horaInicio={props.horaInicio} 
                     horaFin={props.horaFin}
                     
                     dayofCard={props.dayofCard}

                     />);
    
}

const mapStateToProps =(state,ownProps)=>({
    colores:state.ColorReducer[COLOR],
    catTipoCita:state.CatTipoCitaReducer[CAT_TIPO_CITA].filter(p => p.id === ownProps.idTipoCita)[0],
    citaes:state.CitaReducer[CITA],    

})

const mapDispatchToProps = (dispatch, ownProps) => ({
})

export default connect(mapStateToProps,mapDispatchToProps)(HorarioListItem)

