import React,{Component, useEffect} from "react";
import Page from "./page";
import {connect} from "react-redux";
import {PACIENTE,CAT_TIPO_CITA, MEDICO_USUARIO} from "../../../redux/constants/action-type";
import {autenticacionLogin} from "../../../redux/actions/stateActions";
import {obtenerMedicoUsuarioListWSAction} from "../../../redux/actions/MedicoUsuarioActions";
import { useAuth } from "../../auth/auth";
import { Navigate, useNavigate } from "react-router-dom";

function Login(props) {
    const auth = useAuth();
    
    if (props.autenticado) {
        auth.login();
        return <Navigate to="/citas" replace={true}/>
    }


    return<Page autenticacionLogin={props.autenticacionLogin} 
                     errorsForm={props.errorsForm} 
                     usuario={props.usuario}    
                     loading={props.loading}  />
    }





/*
funcion que se utilza para regresar el estado del sotre y hacerlo dispobible
*/
const mapStateToProps=(state)=>({
    usuario:state.loginReducer.usuario,
    loading:state.loginReducer.loading,
    errorsForm:state.loginReducer.errors,
    autenticado:state.autenticacionReducer.autenticado, 
    profil:state.autenticacionReducer.profil,     
    pacientes:state.datosReducer[PACIENTE],       
    openModalPaciente:state.estadosReducer.openModalPaciente,
    catTipoCitaes:state.CatTipoCitaReducer[CAT_TIPO_CITA]    

})
/*
permite que una funcion o llamada a funcion esten disponibles como un props
regresa una funcion creadas en actions
*/
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        autenticacionLogin: (datos) => dispatch(autenticacionLogin(datos))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Login);
