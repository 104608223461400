import React,{Component} from "react";
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemComponent from "./ListItemComponent"
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Typography from '@mui/material/Typography';
import OpacityIcon from '@mui/icons-material/Opacity';
import EventIcon from '@mui/icons-material/Event';
import GroupIcon from '@mui/icons-material/Group';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import Collapse from '@mui/material/Collapse';
import {Link} from "react-router-dom";
import {CALENDARIO,CITA,ESQUEMA_TRABAJO,CAT_TIPO_CITA,USUARIO, PACIENTE, MEDICO} from "../../../redux/constants/action-type";

import RecentActorsIcon from '@mui/icons-material/RecentActors';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import SettingsIcon from '@mui/icons-material/Settings';

import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Chip } from "@mui/material";
import logoInstitucion from '../../../logo/gastroUsePlus.jpg'
import TodayIcon from '@mui/icons-material/Today';
import planEstrategicoPNG from "../../../icons/plan-estrategico.png";
import citaPNG from "../../../icons/cita.png";
import pacientesPNG from "../../../icons/pacientes.png";
import medicosPNG from "../../../icons/medicos.png";
import libroMedPNG from "../../../icons/libro-medico.png";
import equipoPNG from "../../../icons/equipo.png";


const PREFIX = 'Page';
const drawerWidth = 240;

const classes = {
  root: `${PREFIX}-root`,
  drawer: `${PREFIX}-drawer`,
  appBar: `${PREFIX}-appBar`,
  menuButton: `${PREFIX}-menuButton`,
  toolbar: `${PREFIX}-toolbar`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  content: `${PREFIX}-content`,
  nested: `${PREFIX}-nested`,
  sectionDesktop: `${PREFIX}-sectionDesktop`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
    backgroundColor:"red"
  },

  [`& .${classes.drawer}`]: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,

    },
  },

  [`& .${classes.appBar}`]: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    boxShadow: "none",
    backgroundColor:"#FFFFFF",
    borderWidth:"1",
    marginTop: "0rem"
  },

  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  // necessary for content to be below app bar
  [`& .${classes.toolbar}`]: theme.mixins.toolbar,

  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor:"red"

  },

  [`& .${classes.nested}`]: {
    paddingLeft: theme.spacing(4),
  },

  [`& .${classes.sectionDesktop}`]: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  }
}));


const Page = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
      setOpen(!open);
    };




  return (
    <Root>                                                  
                <List>

                    <ListItemComponent icon={<FavoriteIcon style={{ width: "2.2rem",height: "2.2rem", color:"#2196f3"}}/>}
                                    opcion={props.opcion} menu={"comida"} ruta={"/comida"} nombreMenu={"Plan de Alimentación"} handleDrawerToggleClose={props.handleDrawerToggleClose}/>


                    <ListItemComponent icon={<FavoriteIcon style={{ width: "2.2rem",height: "2.2rem", color:"#2196f3"}}/>}
                                    opcion={props.opcion} menu={"producto"} ruta={"/producto"} nombreMenu={"Productos"} handleDrawerToggleClose={props.handleDrawerToggleClose}/>

                    <ListItemComponent icon={<FavoriteIcon style={{ width: "2.2rem",height: "2.2rem", color:"#2196f3"}}/>}
                                    opcion={props.opcion} menu={"procedimiento"} ruta={"/procedimiento"} nombreMenu={"Procedimientos"} handleDrawerToggleClose={props.handleDrawerToggleClose}/>

                    <ListItemComponent icon={<FavoriteIcon style={{ width: "2.2rem",height: "2.2rem", color:"#2196f3"}}/>}
                                    opcion={props.opcion} menu={"movimiento"} ruta={"/movimiento"} nombreMenu={"Movimientos"} handleDrawerToggleClose={props.handleDrawerToggleClose}/>

                    <ListItemComponent icon={<FavoriteIcon style={{ width: "2.2rem",height: "2.2rem", color:"#2196f3"}}/>}
                                    opcion={props.opcion} menu={"tecnico"} ruta={"/tecnico"} nombreMenu={"Técnicos"} handleDrawerToggleClose={props.handleDrawerToggleClose}/>


                    <ListItemComponent icon={<FavoriteIcon style={{ width: "2.2rem",height: "2.2rem", color:"#2196f3"}}/>}
                                        opcion={props.opcion} menu={"presionArterial"} ruta={"/presionArterial"} nombreMenu={"Presión Arterial"} handleDrawerToggleClose={props.handleDrawerToggleClose}/>

                    <ListItemComponent icon={<OpacityIcon style={{ width: "2.2rem",height: "2.2rem", color:"#f44336"}}/>}
                                        opcion={props.opcion} menu={"glucosa"} ruta={"/glucosa"} nombreMenu={"Glucosa"} handleDrawerToggleClose={props.handleDrawerToggleClose}/>


                    <ListItemComponent icon={<img src={citaPNG} style={{ width: "2.2rem",height: "2.2rem", color:"#1e88e5"}}/>}
                                        opcion={props.opcion} menu={CITA} ruta={"/citas"} nombreMenu={"Agendados"} handleDrawerToggleClose={props.handleDrawerToggleClose}/>


                    <ListItemComponent icon={<img src={pacientesPNG} style={{ width: "2.2rem",height: "2.2rem", color:"#607d8b"}}/>}
                                        opcion={props.opcion} menu={PACIENTE} ruta={"/pacientes"} nombreMenu={"Pacientes"} handleDrawerToggleClose={props.handleDrawerToggleClose}/>


                    <ListItemComponent icon={<img src={medicosPNG}  style={{ width: "2.2rem",height: "2.2rem", color:"#00bfa5"}}/>}
                                        opcion={props.opcion} menu={MEDICO} ruta={"/medicos"} nombreMenu={"Medicos"} handleDrawerToggleClose={props.handleDrawerToggleClose}/>

                    <ListItemComponent icon={<img src={libroMedPNG} style={{ width: "2.2rem",height: "2.2rem", color:"#f50057"}}/>}
                                        opcion={props.opcion} menu={CAT_TIPO_CITA} ruta={"/tipoCita"} nombreMenu={"Tipos Citas"} handleDrawerToggleClose={props.handleDrawerToggleClose}/>

                    <ListItemComponent icon={<img src={equipoPNG} style={{ width: "2.2rem",height: "2.2rem", color:"#8eacbb"}}/>}
                                        opcion={props.opcion} menu={USUARIO} ruta={"/usuarios"} nombreMenu={"Usuarios"} handleDrawerToggleClose={props.handleDrawerToggleClose}/>

                    <ListItem button  href="/login" onClick={props.logout} >
                        <ListItemIcon><ExitToAppIcon style={{ width: "2.2rem",height: "2.2rem", color:"#babdbe"}}/></ListItemIcon>
                        <ListItemText  primary={<Typography type="Roboto" variant="subtitle1" style={{ color: "#000000",fontSize:"1.2rem",fontWeight:"normal" }}>Cerrar sesión</Typography>} />
                    </ListItem>

                </List>

                <Box display="flex" justifyContent="flex-start" m={1} p={2} style={{ backgroundColor:"transparent",alignItems:"center"}}>                
                  <Chip
                    avatar={<Avatar alt="Bildsoft"  src={logoInstitucion}  style={{width: "7rem",height: "7rem"}}/>}
                    label=""
                    style={{backgroundColor:"transparent",marginTop:"0.6rem",marginBottom:"0.6rem"}}
                    align="center" />                                                                      
                                                  
                </Box>
           </Root>
  );
};
export default Page;
